import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// components
import {
  createProductReview,
  listProductDetails
} from '../../actions/productActions'

// utils
import { isEmptyObj } from '../../utils'

const AddReviewForm = ({ productId }) => {
  const initialReviewValues = {
    title: '',
    rating: '',
    comment: ''
  }

  const [reviewFormValues, setReviewFormValues] = useState(initialReviewValues)
  const [reviewFormErrors, setReviewFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [isReviewed, setIsReviewed] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productDetails = useSelector((state) => state.productDetails)
  const {
    loading: loadingProductDetails,
    error: errorProductDetails,
    product
  } = productDetails

  const productCreateReview = useSelector((state) => state.productCreateReview)
  const { loading, error, success } = productCreateReview

  // refs and callbacks
  const _initialReviewValuesRef = useRef(initialReviewValues).current

  // handle form fields changes
  const handleChange = (e) => {
    let { name, value } = e.target

    setReviewFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value
    }))
  }

  // validate form
  const validate = (values) => {
    const errors = {}

    if (!values.title.trim()) errors.title = 'Title is required'

    if (!values.rating) errors.rating = 'Rating is required'

    if (!values.comment.trim()) errors.comment = 'Comment is required'

    return errors
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    setReviewFormErrors(validate(reviewFormValues))
    setIsSubmit(true)
  }

  // ref and callbacks
  const _createProductReview = useCallback(async () => {
    if (isEmptyObj(reviewFormErrors) && isSubmit && !isReviewed) {
      const review = {
        name: userInfo?.username,
        picture: userInfo?.picture,
        user: userInfo?._id,
        ...reviewFormValues
      }
      dispatch(createProductReview(productId, review))
      setIsReviewed(true)
    }
  }, [
    dispatch,
    productId,
    reviewFormErrors,
    reviewFormValues,
    userInfo?._id,
    userInfo?.picture,
    userInfo?.username,
    isSubmit,
    isReviewed
  ])

  useEffect(() => {
    // TODO: dispatch add review
    _createProductReview()

    if (success) {
      // close the modal
      document.querySelector('.closeReviewModal').click()

      // list users
      dispatch(listProductDetails(productId))

      // reset form
      setReviewFormValues(_initialReviewValuesRef)

      // navigate
      navigate(`/product/${productId}`)
    }
  }, [
    _initialReviewValuesRef,
    dispatch,
    navigate,
    success,
    productId,
    _createProductReview
  ])

  return (
    <>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '20px 20px 5px 20px',
          margin: '0 0 20px 0',
          borderRadius: '10px'
        }}
        className="d-none"
      >
        <h4 className="mb-4">DEGUB</h4>
        <pre>formValues: {JSON.stringify(reviewFormValues, undefined, 2)}</pre>
        <pre>formErrors: {JSON.stringify(reviewFormErrors, undefined, 2)}</pre>
        <pre>isSubmit: {JSON.stringify(isSubmit, undefined, 2)}</pre>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit} noValidate>
        <div className="form-group font-weight-normal">
          <label htmlFor="title">
            Title{' '}
            <small className="x-small text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <input
            type="text"
            autoComplete="true"
            className={`form-control form-control-lg ${
              reviewFormErrors.title && 'is-invalid'
            }`}
            name="title"
            id="title"
            value={reviewFormValues.title}
            onChange={handleChange}
          />
          {reviewFormErrors.title && (
            <div className="invalid-feedback fw-light">
              {reviewFormErrors.title}
            </div>
          )}
        </div>
        <div className="form-group font-weight-normal">
          <label htmlFor="rating">
            Rating{' '}
            <small className="x-small text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <select
            className={`custom-select custom-select-lg ${
              reviewFormErrors.rating && 'is-invalid'
            }`}
            id="rating"
            name="rating"
            value={reviewFormValues.rating}
            onChange={handleChange}
          >
            <option value="">-- Select --</option>
            <option value="5">Excellent (5 stars)</option>
            <option value="4">Very Good (4 stars)</option>
            <option value="3">Good (3 stars)</option>
            <option value="2">Fair (2 stars)</option>
            <option value="1">Poor (1 star)</option>
          </select>
          {reviewFormErrors.rating && (
            <div className="invalid-feedback fw-light">
              {reviewFormErrors.rating}
            </div>
          )}
        </div>
        <div className="form-group font-weight-normal">
          <label htmlFor="comment">
            Comment{' '}
            <small className="x-small text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <textarea
            className={`form-control form-control-lg ${
              reviewFormErrors.comment && 'is-invalid'
            }`}
            name="comment"
            id="comment"
            rows={4}
            value={reviewFormValues.comment}
            onChange={handleChange}
          ></textarea>
          {reviewFormErrors.comment && (
            <div className="invalid-feedback fw-light">
              {reviewFormErrors.comment}
            </div>
          )}
        </div>
        <div className="modal-footer modal-footer-align">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Add
          </button>
        </div>
      </form>
    </>
  )
}

export default AddReviewForm
