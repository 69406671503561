import React, { useEffect, useRef, useState } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// components
import Message from '../shared/Message'
import SkeletonWishlist from '../shared/skeletons/SkeletonWishlist'

// actions
import { likeProduct, wishlistProducts } from '../../actions/productActions'

// config
import { myWishlistPerPage, productImagePlaceholder } from '../../config'

// utils
import { currencyFormatter } from '../../utils'
import Paginator from '../pagination/wishlist/Paginator'

const UserWishlist = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [isUnliked, setIsUnliked] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const productWishlist = useSelector((state) => state.productWishlist)
  const { items, loading, error } = productWishlist

  const page = Number(searchParams.get('page')) || 1

  // const _meta = useRef(items.meta).current

  const likeProductHandler = (e, productId) => {
    e.preventDefault()
    dispatch(likeProduct(productId))
    dispatch(wishlistProducts(myWishlistPerPage, 1))
    setIsUnliked(true)
  }

  useEffect(() => {
    if (firstLoad || isUnliked) {
      dispatch(wishlistProducts(myWishlistPerPage, page))
      setFirstLoad(false)
      setIsUnliked(false)
    }
  }, [dispatch, page, navigate, firstLoad, isUnliked])

  return (
    <>
      <div className="card pt-0 shadow">
        <div className="card-body px-0 py-4">
          <div className="d-flex justify-content-between align-items-center border-bottom mb-0 pb-3">
            <h6 className="card-header-title ml-4 mb-1">{'My Wishlist'}</h6>
            <div className="fw-light top-min-2 position-relative mr-4 mb-1">
              {items && items.data && items.data.length > 0 && (
                <div className="small-- fw-light">
                  <span className="d-none d-md-inline-block">Showing</span>{' '}
                  {items.meta.from_page} to {items.meta.to_page} of{' '}
                  {items.meta.total_products}{' '}
                  {items.meta.total_products === 1 ? 'product' : 'products'}
                </div>
              )}
            </div>
          </div>
          <div>
            {loading && <SkeletonWishlist />}
            {!loading && error && <Message message={error} />}
            {!loading && items && items.data && items.data.length === 0 && (
              <Message message={'No saved products'} />
            )}
            {!loading && items && items.data && items.data.length > 0 && (
              <>
                <div className="table-responsive">
                  <table className="table-striped- table-user mb-0 table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="small font-weight-bold pl-4"
                          colSpan="2"
                          style={{ minWidth: '260px' }}
                        >
                          ITEM
                        </th>
                        <th scope="col" className="small font-weight-bold">
                          PRICE
                        </th>
                        <th scope="col" className="small font-weight-bold">
                          CATEGORY
                        </th>
                        <th scope="col" className="small font-weight-bold">
                          BRAND
                        </th>
                        <th scope="col" className="small font-weight-bold pr-4">
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.data.map((product, index) => (
                        <tr
                          key={product._id}
                          className={`${
                            (items.data.length !== index + 1 || true) &&
                            'tr-border-bottom'
                          }`}
                        >
                          <td
                            className="position-relative top-min-2 pt-2 pl-3 pb-0"
                            width="50"
                          >
                            <Link to={`/product/${product._id}`}>
                              <img
                                alt={product.name}
                                src={
                                  (product.images &&
                                    product.images[0]?.imageurl) ||
                                  productImagePlaceholder
                                }
                                className="rounded-circle"
                                width="50"
                              />
                            </Link>
                          </td>
                          <td className="pt-3 pl-0 pb-3">
                            <Link
                              to={`/product/${product._id}`}
                              className="text-black"
                            >
                              {product.name}
                            </Link>
                          </td>
                          <td className="pt-3 pb-3">
                            {currencyFormatter(product.price)}
                          </td>
                          <td className="pt-3 pb-3">{product.category}</td>
                          <td className="pt-3 pb-3">{product.brand}</td>
                          <td className="pt-3 pb-3 pr-4">
                            <Link
                              className="fw-light link-underline"
                              title="unlike"
                              to="."
                              onClick={(e) =>
                                likeProductHandler(e, product._id)
                              }
                            >
                              Unlike
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="float-lg-right">
                  <Paginator
                    itemsPerPage={items.meta.products_per_page}
                    totalPages={items.meta.total_pages}
                    page={items.meta.page}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default UserWishlist
