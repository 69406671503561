import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// components
import ProductInfo from '../components/product/ProductInfo'
import ProductImg from '../components/product/ProductImg'
import RatingBreakdown from '../components/rating/RatingBreakdown'
import ReviewList from '../components/review/ReviewList'
import ReviewForm from '../components/review/ReviewForm'
import ReviewFilterBar from '../components/review/ReviewFilterBar'
import Loader from '../components/shared/Loader'
import Message from '../components/shared/Message'
import AlertCard from '../components/shared/AlertCard'

// actions
import { listProductDetails, removeReview } from '../actions/productActions'

const ProductScreen = () => {
  const [isShown, setIsShown] = useState(false)
  const [readMore, setReadMore] = useState('Read More')

  const dispatch = useDispatch()

  const { id } = useParams()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const reviewRemove = useSelector((state) => state.reviewRemove)
  const { success: successsRemove } = reviewRemove

  const handleRemoveReview = (e) => {
    e.preventDefault()
    document.querySelector('.close').click()

    // TODO: remove review
    dispatch(removeReview(id))
  }

  useEffect(() => {
    dispatch(listProductDetails(id))
  }, [dispatch, id, successsRemove])

  if (loading)
    return (
      <div className="py-5">
        <Loader />
      </div>
    )

  if (error)
    return (
      <>
        <div className="band band-fat">
          <div className="mb-5 pb-3"></div>
        </div>
        <AlertCard
          message={error}
          icon="exclamation-circle"
          cardBorder={false}
          button={{ label: 'Go Back Home', action: '/' }}
        />
      </>
    )

  if (!product) return <Message message="Item does not exist" />

  return (
    <>
      <div className="py-md-5 py-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mb-lg-0 mb-4">
              <ProductImg product={product} />
            </div>
            <div className="col-lg-5">
              <ProductInfo product={product} />
            </div>
          </div>
        </div>
        <div
          className="container"
          id="reviews"
          style={{ marginTop: '50px', paddingTop: '50px' }}
        >
          <div className="row">
            <div className="col-lg-4 mb-lg-0 mb-7">
              <RatingBreakdown product={product} />
            </div>
            <div className="col-lg-8">
              <div className="pl-lg-4">
                <ReviewFilterBar />
                <ReviewList
                  reviews={product.reviews}
                  isShown={isShown}
                  productId={id}
                  handleRemoveReview={handleRemoveReview}
                />
                <ReviewForm
                  numReviews={product.numReviews}
                  readMore={readMore}
                  setReadMore={setReadMore}
                  isShown={isShown}
                  setIsShown={setIsShown}
                  productId={id}
                  reviews={product.reviews}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductScreen
