import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
  productListReducer,
  productDetailsReducer,
  productRemoveReducer,
  productAddReducer,
  productEditReducer,
  productUpdateReducer,
  productCreateReviewReducer,
  featuredProductListReducer,
  productLikeReducer,
  productWishlistReducer,
  reviewRemoveReducer,
  reviewEditReducer,
  reviewUpdateReducer,
  productMyReviewsReducer
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userUpdatePasswordReducer,
  userForgotPasswordReducer,
  userResetPasswordReducer,
  userConfirmRegistrationReducer,
  userResetPasswordValidReducer,
  userConfirmRegistrationValidReducer,
  userListReducer,
  userRemoveReducer,
  userAddReducer,
  userEditReducer,
  userUpdateReducer
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyOrdersReducer,
  orderListReducer
} from './reducers/orderReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productRemove: productRemoveReducer,
  productAdd: productAddReducer,
  productEdit: productEditReducer,
  productUpdate: productUpdateReducer,
  productCreateReview: productCreateReviewReducer,
  featuredProductList: featuredProductListReducer,
  productLike: productLikeReducer,
  productWishlist: productWishlistReducer,
  productMyReviews: productMyReviewsReducer,
  reviewRemove: reviewRemoveReducer,
  reviewEdit: reviewEditReducer,
  reviewUpdate: reviewUpdateReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdatePassword: userUpdatePasswordReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetPassword: userResetPasswordReducer,
  userConfirmRegistration: userConfirmRegistrationReducer,
  userResetPasswordValid: userResetPasswordValidReducer,
  userConfirmRegistrationValid: userConfirmRegistrationValidReducer,
  userList: userListReducer,
  userRemove: userRemoveReducer,
  userAdd: userAddReducer,
  userEdit: userEditReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMyOrders: orderListMyOrdersReducer,
  orderList: orderListReducer
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

const paymentMethodFromStorage = localStorage.getItem('paymentMethod')
  ? JSON.parse(localStorage.getItem('paymentMethod'))
  : ''

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: paymentMethodFromStorage
  },
  userLogin: { userInfo: userInfoFromStorage }
}
const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
