import React, { useState, useEffect } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { togglePassword } from '../../utils/index'

// actions
import { login } from '../../actions/userActions'

const SignIn = () => {
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const redirect = searchParams.get('redirect')
    ? searchParams.get('redirect')
    : 'shipping'

  const onSubmit = (e) => {
    e.preventDefault()

    dispatch(login(identifier, password))
  }

  // handle hide/show password
  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  useEffect(() => {
    if (userInfo) navigate(`/${redirect}`)
  }, [navigate, redirect, userInfo])

  return (
    <>
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="identifier">Email</label>
          <input
            type="email"
            className="form-control form-control-lg"
            name="identifier"
            id="identifier"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="login-password">Password</label>
          <div className="position-relative">
            <input
              type="password"
              className="form-control form-control-lg"
              autoComplete="new-password"
              name="password"
              id="password"
              style={{ paddingRight: '60px' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="show-password-icon">
              <i
                className="bi bi-eye"
                style={{ cursor: 'pointer' }}
                onClick={handleShowHidePassword}
              ></i>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="remember"
            />
            <label htmlFor="remember" className="custom-control-label">
              Remember me
            </label>
          </div>
        </div>
        <button type="submit" className="btn btn-lg btn-outline-primary px-4">
          Login
        </button>
        <Link
          to="/forgot-password"
          className="btn btn-no-transaction text-primary"
        >
          Forgot password?
        </Link>
      </form>
      <hr className="mt-4" />
      <>
        Need an account?{' '}
        <Link to="/register" className="text-primary">
          Register now
        </Link>
      </>
    </>
  )
}

export default SignIn
