import React, { useEffect, useMemo, useState } from 'react'
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// actions
import { listOrders } from '../actions/orderActions'
import { listUsers } from '../actions/userActions'

// utils
import {
  currencyFormatter,
  isEmptyObj,
  updateQueryString
} from '../utils/index'

// config
import { orderListPerPage } from '../config'

// components
import Message from '../components/shared/Message'
import SkeletonOrderList from '../components/shared/skeletons/SkeletonOrderList'
import Paginator from '../components/pagination/orders/Paginator'

const OrderListScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const [currentUser, setCurrentUser] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderList = useSelector((state) => state.orderList)
  const { orders, loading, error } = orderList

  const userList = useSelector((state) => state.userList)
  const { users, loading: loadingUserList, error: errorUserList } = userList

  const page = Number(searchParams.get('page')) || 1
  const userId = searchParams.get('userId') || ''

  // diaplay name
  const displayName = (user) => {
    let displayedName = 'Anonymous'

    if (user) {
      const fname = user.firstname === undefined ? '' : user.firstname
      const lname = user.lastname === undefined ? '' : user.lastname
      const fullname = `${fname} ${lname}`.trim()
      displayedName = fullname ? fullname : user.username
    }

    return displayedName
  }

  const updateParams = (userId) => {
    const updatedParams = updateQueryString({}, { userId }, pathname)
    window.history.pushState(null, '', `${updatedParams}`)
  }

  // filter by user
  const filterByUserHandler = (e) => {
    const userId = e.target.value ? e.target.value : ''
    setCurrentUser(userId)
    if (userId) {
      dispatch(listOrders(orderListPerPage, 1, userId))
      updateParams(userId)
    } else {
      dispatch(listOrders(orderListPerPage, 1, ''))
      updateParams('')
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders(orderListPerPage, page, userId))
      dispatch(listUsers(0, 1, ''))
    } else {
      navigate('/')
    }
  }, [userInfo, navigate, dispatch, page, userId])

  // CONSOLE.LOG
  // console.log('orders:', orders)

  // CONSOLE.LOG
  // console.log('users:', users)

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-5 pb-3">
            <h1 className="h3 mb-1">
              <i className="bi bi-receipt-cutoff before-top-min-1 mr-2"></i>
              Orders
            </h1>
          </div>
        </div>
      </div>
      <div className="container-overlap container">
        <div className="row">
          <div className="col">
            <div className="card card-no-border- pt-0 shadow">
              <div className="card-body px-0 py-4">
                <div className="d-md-flex justify-content-md-between align-items-md-center border-bottom-- mb-0 pb-3">
                  <h6 className="card-header-title mb-md-2 ml-4 mb-3">
                    Orders List
                  </h6>
                  <div className="fw-light top-min-2 position-relative mr-4 ml-4 mb-1">
                    {users && users?.data && (
                      <select
                        className="custom-select pr-5"
                        id="country"
                        name="country"
                        defaultValue={userId}
                        onChange={filterByUserHandler}
                      >
                        <option value="" key={1}>
                          Filter by user
                        </option>
                        {users.data.map((user) => (
                          <option value={user._id} key={user._id}>
                            {displayName(user)}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center border-bottom mb-0 pl-4 pb-3">
                  <div className="fw-light top-min-2 position-relative mr-4 mb-1">
                    {orders && orders.data && orders.data.length > 0 && (
                      <div className="small-- fw-light">
                        <span className="d-none-- d-md-inline-block">
                          Showing
                        </span>{' '}
                        {orders.meta.from_page} to {orders.meta.to_page} of{' '}
                        {orders.meta.total_orders} orders{' '}
                      </div>
                    )}
                  </div>
                </div>
                {loading && <SkeletonOrderList />}
                {!loading && error && <Message message={error} />}
                {!loading &&
                  orders &&
                  (!orders.data || orders.data.length === 0) && (
                    <Message message="No orders found" />
                  )}
                {!loading && orders && orders.data && orders.data.length > 0 && (
                  <>
                    <div className="table-responsive">
                      <table className="table-striped- table-user mb-0 table">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="small font-weight-bold pl-4"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="small font-weight-bold"
                              style={{ minWidth: '200px' }}
                            >
                              USER
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              DATE
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              TOTAL
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              PAID
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              DELIVERED
                            </th>
                            <th
                              scope="col"
                              className="small font-weight-bold pr-4"
                            >
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.data.map((order, index) => (
                            <tr
                              key={order._id}
                              className={`${
                                (orders.data.length !== index + 1 || true) &&
                                'tr-border-bottom'
                              }`}
                            >
                              <td className="position-relative pt-3 pb-3 pl-4">
                                #{`${order._id.substring(0, 9).toUpperCase()}`}
                              </td>
                              <td className="pt-3 pb-3 pl-3">
                                {displayName(order.user)}
                              </td>
                              <td className="pt-3 pb-3">
                                {moment(order.createdAt).format('L')}
                              </td>
                              <td className="pt-3 pb-3">
                                {currencyFormatter(order.totalPrice)}
                              </td>
                              <td className="pt-3 pb-3">
                                {order.paidAt ? (
                                  moment(order.paidAt).format('L')
                                ) : (
                                  <i className="bi bi-x-circle bi-status-align text-muted before-top-2 opacity-25"></i>
                                )}
                              </td>
                              <td className="pt-3 pb-3">
                                {order.deliveredAt ? (
                                  moment(order.deliveredAt).format('L')
                                ) : (
                                  <i className="bi bi-x-circle bi-status-align text-muted before-top-2 opacity-25"></i>
                                )}
                              </td>
                              <td className="fw-light pt-3 pb-3 pr-4">
                                <Link
                                  to={`/orders/${order._id}`}
                                  className="link-underline"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="float-lg-right">
                      <Paginator
                        ordersPerPage={orders.meta.orders_per_page}
                        totalPages={orders.meta.total_pages}
                        page={orders.meta.page}
                        userId={currentUser}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderListScreen
