import React from 'react'

const ModalMessage = ({ type, context }) => {
  if (type === 'removeUser')
    return (
      <>
        Are you sure you wish to remove“
        <span className="font-weight-bold">{context}</span>
        ”user?
      </>
    )

  if (type === 'removeProduct')
    return (
      <>
        Are you sure you wish to remove“
        <span className="font-weight-bold">{context}</span>
        ”product?
      </>
    )

  if (type === 'unlikeProduct')
    return (
      <>
        Are you sure you wish to unlike“
        <span className="font-weight-bold">{context}</span>
        ”product?
      </>
    )

  if (type === 'removeReview')
    return (
      <>
        Are you sure you wish to remove“
        <span className="font-weight-bold">{context}</span>
        ”review?
      </>
    )
}

export default ModalMessage
