import React from 'react'

import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonWishlist = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="table-responsive">
        <table className="table-striped- table-user mb-0 table">
          <thead>
            <tr>
              <th
                scope="col"
                className="small font-weight-bold pl-4"
                colSpan="2"
                style={{ minWidth: '260px' }}
              >
                ITEM
              </th>
              <th scope="col" className="small font-weight-bold">
                PRICE
              </th>
              <th scope="col" className="small font-weight-bold">
                CATEGORY
              </th>
              <th scope="col" className="small font-weight-bold">
                BRAND
              </th>
              <th scope="col" className="small font-weight-bold pr-4">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            {[...Array(5).keys()].map((elem, index) => (
              <tr
                key={index}
                className={`${index + 1 !== 5 && 'tr-border-bottom'}`}
              >
                <td
                  className="position-relative top-min-1 pt-0 pl-4 pb-2"
                  width={80}
                >
                  <SkeletonElement type="item-circle" />
                </td>
                <td className="pt-2 pl-0">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3 pr-4">
                  <SkeletonElement type="item" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Shimmer />
      </div>
    </div>
  )
}

export default SkeletonWishlist
