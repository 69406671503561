import React, { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import ProductFilterBar from '../components/product/ProductFilterBar'
import ProductList from '../components/product/ProductList'

import { productsPerPage } from '../config'
import { listProducts } from '../actions/productActions'
import Paginator from '../components/pagination/products/Paginator'

const SearchScreen = () => {
  const dispatch = useDispatch()
  const { term } = useParams()
  const [searchParams] = useSearchParams()
  const page = searchParams.get('page') || 1
  const sort = searchParams.get('sort') || '*'

  useEffect(() => {
    dispatch(listProducts(productsPerPage, page, sort, term, { page, sort }))
  }, [dispatch, term, page, sort])

  return (
    <>
      <div className="band band-fat">
        <ProductFilterBar pb={4} searchFor={true} />
      </div>
      <div className="container-overlap">
        <ProductList />
      </div>
      <Paginator />
    </>
  )
}

export default SearchScreen
