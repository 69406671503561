import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PlaceOrder = ({ action }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const cart = useSelector((state) => state.cart)
  const { shippingAddress, paymentMethod } = cart

  const navigate = useNavigate()

  useEffect(() => {
    if (!paymentMethod || !userInfo) {
      navigate('/payment')
    }
  }, [paymentMethod, navigate, userInfo])

  return (
    <>
      <div className="border-bottom mb-4 pb-4">
        {shippingAddress && (
          <>
            <h2 className="h5 mb-4">Shipping Address</h2>
            {shippingAddress.address}
            {shippingAddress.apt && `, #${shippingAddress.apt}`}
            <br />
            {shippingAddress.city}, {shippingAddress.state}{' '}
            {shippingAddress.zipCode}
            <br />
            {shippingAddress.country}
            <Link to="/shipping" className="small fw-light-- ml-2">
              Edit
            </Link>
          </>
        )}
      </div>
      <div className="border-bottom mb-4 pb-4">
        <h2 className="h5 mb-3">Payment Method</h2>
        {paymentMethod}
        <Link to="/payment" className="small fw-light-- ml-2">
          Edit
        </Link>
      </div>
      <button className="btn btn-primary text-uppercase px-4" onClick={action}>
        Place Order
      </button>
    </>
  )
}

export default PlaceOrder
