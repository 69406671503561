import axios from 'axios'
import { toast } from 'react-toastify'

import {
  CART_ADD_ITEM,
  CART_REMOVE_ALL_ITEMS,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_MEHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_RESET
} from '../constants/cartConstants'

// config
import { productImagePlaceholder, apiBaseUrl } from '../config'

export const addToCart = (id, quantity) => async (dispatch, getState) => {
  const {
    data: { data }
  } = await axios.get(`${apiBaseUrl}/api/products/${id}`)

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      image:
        (data.images && data.images[0]?.imageurl) || productImagePlaceholder,
      price: data.price,
      countInStock: data.countInStock,
      rating: data.rating,
      quantity
    }
  })

  toast.dismiss()
  toast.success('Product added to the cart.')

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => async (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id
  })

  toast.dismiss()
  toast.success(`Product removed from the cart.`)

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const emptyCart = () => async (dispatch) => {
  dispatch({ type: CART_REMOVE_ALL_ITEMS })
  localStorage.removeItem('cartItems')
}

export const resetCart = () => async (dispatch) => {
  dispatch({ type: CART_RESET })
  localStorage.removeItem('cartItems')
  localStorage.removeItem('shippingAddress')
  localStorage.removeItem('paymentMethod')
}

export const removeAllItemsFromCart = () => async (dispatch) => {
  dispatch({ type: CART_REMOVE_ALL_ITEMS })

  toast.dismiss()
  toast.success('Removed all products from the cart.')

  localStorage.removeItem('cartItems')
}

export const saveShippingAddress = (data, navigate) => async (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data
  })

  toast.dismiss()
  toast.success('Shipping address saved.')
  navigate('/payment')

  localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data, navigate) => async (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_MEHOD,
    payload: data
  })

  toast.dismiss()
  toast.success('Payment method selected.')
  navigate('/place-order')

  localStorage.setItem('paymentMethod', JSON.stringify(data))
}
