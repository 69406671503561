import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Masonry from 'react-masonry-component'

// components
import FeaturedProducts from '../components/product/FeaturedProducts'
import Loader from '../components/shared/Loader'
import Message from '../components/shared/Message'

// actions
import { listFeaturedProducts } from '../actions/productActions'

const SettingsScreen = () => {
  const [isSaveSubmit, setIsSaveSubmit] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const featuredProductList = useSelector((state) => state.featuredProductList)
  const { products, loading, error } = featuredProductList

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listFeaturedProducts())
    } else {
      navigate('/')
    }
  }, [userInfo, navigate, dispatch])

  // console.log(products)

  const updateFeaturedProductsHandler = () => {
    setIsSaveSubmit(true)
  }

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-5 pb-3">
            <h1 className="h3 mb-1">
              <i className="bi bi-gear before-top-min-1 mr-2"></i>Settings
            </h1>
          </div>
        </div>
      </div>
      <div className="container-overlap container">
        <Masonry className="row">
          <div className="col-item col-lg-12">
            <div className="card card-no-border- pt-0 shadow">
              {loading && (
                <div className="mb-5">
                  <Message message="Loading..." />
                </div>
              )}
              {!loading && error && (
                <div className="mb-5">
                  <Message message={error} />
                </div>
              )}
              {!loading && products && (
                <>
                  <div className="card-body px-0 pt-3 pb-2">
                    <div className="d-flex justify-content-between align-items-center border-bottom mb-0 pb-3">
                      <h6 className="card-header-title ml-4 mb-1">
                        <i className="bi bi-pin-fill h5 position-relative top-3 mr-2"></i>
                        Featured Products
                      </h6>
                    </div>
                    <div className="p-4">
                      <FeaturedProducts
                        featuredProducts={products}
                        isSaveSubmit={isSaveSubmit}
                        setIsSaveSubmit={setIsSaveSubmit}
                      />
                    </div>
                  </div>
                  <div className="card-footer px-4 py-3">
                    <div className="float-right">
                      <button className="btn btn-sm btn-secondary mr-2 px-4">
                        Cancel
                      </button>
                      {false ? (
                        <button className="btn btn-sm btn-primary disabled px-4">
                          <span className="spinner-border spinner-form-button spinner-form-button-sm"></span>
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-primary px-4"
                          onClick={updateFeaturedProductsHandler}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Masonry>
      </div>
    </>
  )
}

export default SettingsScreen
