import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { updateUserProfile, getUserDetails } from '../../actions/userActions'

const UserProfile = ({
  user,
  userProfile,
  setUserProfile,
  fullname,
  title
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedPicture, setSelectedPicture] = useState(null)

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { loading, error, userInfo } = userUpdateProfile

  const handleUploadPicture = async (e) => {
    const pictureObj = e.target.files[0]
    // console.log('pictureObj', pictureObj)
    setSelectedPicture(pictureObj)

    e.target.parentElement.getElementsByTagName('label')[0].textContent =
      pictureObj.name
    setUserProfile({ ...userProfile, picture: URL.createObjectURL(pictureObj) })
  }

  const cancelHandler = (e) => {
    e.preventDefault()

    dispatch(getUserDetails())

    navigate('/account')
  }

  const onSubmit = (e) => {
    e.preventDefault()

    let userFormData = new FormData()
    userFormData.append('username', userProfile.username)
    userFormData.append('email', userProfile.email)
    userFormData.append('firstname', userProfile.firstname)
    userFormData.append('lastname', userProfile.lastname)
    userFormData.append('picture', selectedPicture)

    // DIPATCH UPDATE PROFILE
    dispatch(updateUserProfile(userFormData))
  }

  return (
    <form
      encType="multipart/form-data"
      autoComplete="false"
      onSubmit={onSubmit}
      noValidate
    >
      <div className="card card-no-border- pt-0 shadow">
        <div className="card-body px-4 py-4">
          <div className="d-flex justify-content-between align-items-end border-bottom mb-4 pb-3">
            <h6 className="card-header-title">{title}</h6>
          </div>
          <div className="media position-relative mb-4">
            <img
              src={userProfile.picture}
              width="100"
              className="rounded-circle pic-border profile-img mr-3 shadow-sm"
              alt="..."
            />
            <div className="media-body mt-3">
              <h4 className="card-title">
                {fullname ? fullname : userProfile.username}
              </h4>
              <p className="card-text text-muted text-small">
                {userProfile.email}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="username">
              Username{' '}
              <small className="x-small text-muted opacity-75 font-weight-light">
                (required)
              </small>
            </label>
            <input
              type="text"
              className="form-control form-control-lg "
              name="username"
              id="username"
              value={userProfile.username}
              onChange={(e) =>
                setUserProfile({ ...userProfile, username: e.target.value })
              }
            />
            <small className="form-text text-muted font-weight-light x-small">
              A valid username should start with an alphabet, then other
              characters can be alphabets, numbers or underscores. When its
              length consists of 6 to 28 characters and it's case insensitive
              (e.g. <i>hyusuf</i>, <i>yusuf22</i>, <i>Yusuf_22</i>,{' '}
              <i>h_yusuf_22</i>).
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="email">
              Email{' '}
              <small className="x-small text-muted opacity-75 font-weight-light">
                (required)
              </small>
            </label>
            <input
              type="email"
              className="form-control form-control-lg "
              name="email"
              id="email"
              value={userProfile.email}
              onChange={(e) =>
                setUserProfile({ ...userProfile, email: e.target.value })
              }
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="firstname">Firstname</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="firstname"
                  name="firstname"
                  value={userProfile.firstname}
                  onChange={(e) =>
                    setUserProfile({
                      ...userProfile,
                      firstname: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="lastname">Lastname</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="lastname"
                  name="lastname"
                  value={userProfile.lastname}
                  onChange={(e) =>
                    setUserProfile({ ...userProfile, lastname: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="picture">Update profile picture</label>
            <div className="custom-file form-control-lg">
              <input
                type="file"
                className="custom-file-input form-control-lg"
                name="picture"
                id="picture"
                accept="image/*"
                onChange={handleUploadPicture}
              />
              <label
                className="custom-file-label form-control-lg text-muted"
                htmlFor="picture"
              >
                Choose file
              </label>
            </div>
          </div>
        </div>
        <div className="card-footer px-4 py-4">
          <div className="float-right">
            <button
              className="btn btn-secondary mr-2 px-4"
              onClick={cancelHandler}
            >
              Cancel
            </button>
            {loading ? (
              <button className="btn btn-primary disabled px-4">
                <span className="spinner-border spinner-form-button"></span>
                Save Changes
              </button>
            ) : (
              <button className="btn btn-primary px-4">Save Changes</button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default UserProfile
