import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import {
  togglePassword,
  validateUsername,
  validateEmail,
  validatePassword,
  generateRandomPassword
} from '../../utils/index'

// actions
import { addUser, listUsers } from '../../actions/userActions'

// config
import { userListPerPage } from '../../config'

const AddUserForm = () => {
  const initialUserValues = {
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    confirmPassword: '',
    role: 'user',
    status: 'active'
  }
  const [userFormValues, setUserFormValues] = useState(initialUserValues)
  const [userFormErrors, setUserFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const dispatch = useDispatch()

  const userAdd = useSelector((state) => state.userAdd)
  const { loading, error, success } = userAdd

  // refs and callbacks
  const _initialUserValuesRef = useRef(initialUserValues).current

  // handle form fields changes
  const handleChange = (e) => {
    let { name, value } = e.target
    setUserFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value
    }))
  }

  // validate form
  const validate = (values) => {
    const errors = {}

    if (!values.username.trim()) errors.username = 'Username is required'
    else if (!validateUsername(values.username.trim()))
      errors.username = 'Username is not valid'

    if (!values.email.trim()) errors.email = 'Email is required'
    else if (!validateEmail(values.email.trim()))
      errors.email = 'Email is not valid'

    if (!values.password) errors.password = `Password is required`
    else if (!validatePassword(values.password))
      errors.password = 'Password is not valid'

    if (!values.confirmPassword)
      errors.confirmPassword = `Confirm password is required`
    else if (values.password !== values.confirmPassword)
      errors.confirmPassword = 'Passwords do not match'

    return errors
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()

    setUserFormErrors(validate(userFormValues))
    setIsSubmit(true)

    dispatch(addUser(userFormValues))
  }

  // handle show/hide password
  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  const showPasswords = () => {
    // show passwords
    document.getElementById('password').type = 'text'
    document.getElementById('confirmPassword').type = 'text'
    document.getElementById('pw-eye-icon').classList.remove('bi-eye')
    document.getElementById('pw-eye-icon').classList.add('bi-eye-slash')
    document.getElementById('cpw-eye-icon').classList.remove('bi-eye')
    document.getElementById('cpw-eye-icon').classList.add('bi-eye-slash')
  }

  const hidePasswords = () => {
    // hide passwords
    document.getElementById('password').type = 'password'
    document.getElementById('confirmPassword').type = 'password'
    document.getElementById('pw-eye-icon').classList.remove('bi-eye-slash')
    document.getElementById('pw-eye-icon').classList.add('bi-eye')
    document.getElementById('cpw-eye-icon').classList.remove('bi-eye-slash')
    document.getElementById('cpw-eye-icon').classList.add('bi-eye')
  }

  const generatePasswordHandler = (e) => {
    e.preventDefault()
    const randomPassword = generateRandomPassword()
    setUserFormValues((prevFormValues) => ({
      ...prevFormValues,
      password: randomPassword,
      confirmPassword: randomPassword
    }))

    // show passwords
    showPasswords()
  }

  useEffect(() => {
    if (success) {
      // close the modal
      document.querySelector('.closeAddModal').click()

      // list users
      dispatch(listUsers(userListPerPage, 1))

      // reset form
      setUserFormValues(_initialUserValuesRef)

      // hide passwords
      hidePasswords()
    }
  }, [success, dispatch, _initialUserValuesRef])

  return (
    <>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '20px 20px 5px 20px',
          margin: '0 0 20px 0',
          borderRadius: '10px'
        }}
        className="d-none"
      >
        <h4 className="mb-4">DEGUB</h4>
        <pre>formValues: {JSON.stringify(userFormValues, undefined, 2)}</pre>
        <pre>formErrors: {JSON.stringify(userFormErrors, undefined, 2)}</pre>
        <pre>isSubmit: {JSON.stringify(isSubmit, undefined, 2)}</pre>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit} noValidate>
        <div className="form-group">
          <label htmlFor="username">
            Username{' '}
            <small className="x-small text-muted opacity-75 font-weight-light">
              (required)
            </small>
          </label>
          <input
            type="text"
            autoComplete="true"
            className={`form-control form-control-lg ${
              userFormErrors.username && 'is-invalid'
            }`}
            name="username"
            id="username"
            value={userFormValues.username}
            onChange={handleChange}
          />
          {userFormErrors.username ? (
            <div className="invalid-feedback fw-light">
              {userFormErrors.username}
            </div>
          ) : (
            <small className="form-text text-muted font-weight-light x-small">
              A valid username should start with an alphabet, then other
              characters can be alphabets, numbers or underscores. When its
              length consists of 6 to 28 characters and it's case insensitive
              (e.g. <i>hyusuf</i>, <i>yusuf22</i>, <i>Yusuf_22</i>,{' '}
              <i>h_yusuf_22</i>).
            </small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email">
            Email{' '}
            <small className="x-small text-muted opacity-75 font-weight-light">
              (required)
            </small>
          </label>
          <input
            type="email"
            className={`form-control form-control-lg ${
              userFormErrors.email && 'is-invalid'
            }`}
            name="email"
            id="email"
            value={userFormValues.email}
            onChange={handleChange}
          />
          {userFormErrors.email && (
            <div className="invalid-feedback fw-light">
              {userFormErrors.email}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="firstname">Firstname</label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="firstname"
                name="firstname"
                value={userFormValues.firstname}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="lastname">Lastname</label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="lastname"
                name="lastname"
                value={userFormValues.lastname}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="password">
                Password{' '}
                <small className="x-small text-muted opacity-75 font-weight-light">
                  (required)
                </small>
              </label>
              <Link
                to="/users"
                className="float-right text-muted link-underline mt-0 small fw-light"
                onClick={generatePasswordHandler}
              >
                Generate password
              </Link>
              <div className="position-relative">
                <input
                  type="password"
                  autoComplete="new-password"
                  className={`form-control form-control-lg ${
                    userFormErrors.password && 'is-invalid'
                  }`}
                  name="password"
                  id="password"
                  style={{ paddingRight: '60px' }}
                  value={userFormValues.password}
                  onChange={handleChange}
                />
                <div className="show-password-icon">
                  <i
                    className="bi bi-eye"
                    id="pw-eye-icon"
                    style={{ cursor: 'pointer' }}
                    onClick={handleShowHidePassword}
                  ></i>
                </div>
                {userFormErrors.password ? (
                  <div className="invalid-feedback fw-light">
                    {userFormErrors.password}
                  </div>
                ) : (
                  <small className="form-text text-muted font-weight-light x-small">
                    Password must be at least 8 characters. And must contain
                    letters, numbers, symbols (e.g. <i>@yusuf123</i>,{' '}
                    <i>Yusuf2022$</i>).
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="confirmPassword">
                Confirm Password{' '}
                <small className="x-small text-muted opacity-75 font-weight-light">
                  (required)
                </small>
              </label>
              <div className="position-relative">
                <input
                  type="password"
                  autoComplete="new-password"
                  className={`form-control form-control-lg ${
                    userFormErrors.confirmPassword && 'is-invalid'
                  }`}
                  name="confirmPassword"
                  id="confirmPassword"
                  style={{ paddingRight: '60px' }}
                  value={userFormValues.confirmPassword}
                  onChange={handleChange}
                />
                <div className="show-password-icon">
                  <i
                    className="bi bi-eye"
                    id="cpw-eye-icon"
                    style={{ cursor: 'pointer' }}
                    onClick={handleShowHidePassword}
                  ></i>
                </div>
                {userFormErrors.confirmPassword && (
                  <div className="invalid-feedback fw-light">
                    {userFormErrors.confirmPassword}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="role">Role</label>
              <select
                className="custom-select custom-select-lg"
                id="role"
                name="role"
                value={userFormValues.role}
                onChange={handleChange}
              >
                <option value="admin">Admin</option>
                <option value="user">User</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <select
                className="custom-select custom-select-lg"
                id="status"
                name="status"
                value={userFormValues.status}
                onChange={handleChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div className="modal-footer modal-footer-align">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Add
          </button>
        </div>
      </form>
    </>
  )
}

export default AddUserForm
