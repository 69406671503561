import React from 'react'
import { useSelector } from 'react-redux'

import Pagination from './Pagination'

const Paginator = ({ mode }) => {
  const productList = useSelector((state) => state.productList)
  const {
    meta: { total_pages: totalPages }
  } = productList

  return (
    totalPages > 1 && (
      <div
        className={`d-lg-flex justify-content-lg-center align-items-lg-center container ${
          mode === 'frontend' ? 'mb-4' : 'mb-0'
        }`}
      >
        <div
          className={`d-none d-lg-block ${
            mode === 'frontend' ? 'mt-5' : 'mt-4'
          }`}
        >
          <Pagination mode={mode} />
        </div>
        <div className={`d-none d-md-block d-lg-none mt-4`}>
          <Pagination mode={mode} theme={'dropdown'} />
        </div>
        <div
          className={`d-block d-md-none ${
            mode === 'frontend' ? 'mt-2' : 'mt-4'
          }`}
        >
          <Pagination mode={mode} theme={'prevnext'} />
        </div>
      </div>
    )
  )
}

Paginator.defaultProps = {
  mode: 'frontend'
}

export default Paginator
