import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'

// compoents
import Rating from '../rating/Rating'
import RemoveModal from '../shared/modal/RemoveModal'
import ModalMessage from '../shared/ModalMessage'
import EditReviewForm from '../form/EditReviewForm'

// actions
import EditModal from '../shared/modal/EditModal'

const Review = ({ review, shown, productId, handleRemoveReview }) => {
  const [isInitialRender, setIsInitialRender] = useState(false)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  return (
    <>
      <div className={`border-bottom mb-4 pb-4 ${!shown && 'd-none'}`}>
        <div className="d-flex justify-content-between align-items-center text-muted small mb-3">
          <div className="text-warning">
            <Rating rating={review.rating} />
          </div>
          <span className="font-weight-light">
            {moment(review.createdAt).format('LL')}
          </span>
        </div>
        <h5 className="text-uppercase">{review.title}</h5>
        <p className="text-muted font-weight-light">{review.comment}</p>

        <div className="d-sm-flex justify-content-sm-between align-items-sm-top">
          <div className="text-muted small mb-sm-0 mb-2">
            <span className="text-dark font-weight-bold mr-1">
              <img
                className="rounded-circle position-relative top-min-1 mr-2"
                src={review.picture}
                width="30"
                height="30"
                alt={review.name}
              />
              {review.name}
            </span>
            <span className="font-weight-light">&ndash; Verified Purchase</span>
          </div>
          {userInfo && userInfo._id === review.user && (
            <div className="small ml-sm-4 mt-sm-0 mt-3 ml-0">
              <Link
                to="."
                className="link-underline-primary font-weight-light mr-2"
                data-toggle="modal"
                data-target="#editItem"
                title="edit review"
                onClick={() => setIsInitialRender(true)}
              >
                Edit
              </Link>
              <Link
                to="."
                className="link-underline-primary font-weight-light"
                data-toggle="modal"
                data-target="#removeItem"
                title="remove review"
              >
                Remove
              </Link>
            </div>
          )}
        </div>
      </div>
      <RemoveModal
        title="Remove Review"
        message={<ModalMessage type="removeReview" context={review.title} />}
        action={handleRemoveReview}
      />
      <EditModal
        title="Edit Review"
        size="large"
        content={
          <EditReviewForm
            productId={productId}
            isInitialRender={isInitialRender}
            setIsInitialRender={setIsInitialRender}
          />
        }
        action={() => {}}
      />
    </>
  )
}

export default Review
