import React from 'react'
import PropTypes from 'prop-types'

const Rating = ({ rating, color, size }) => {
  return (
    <>
      <i
        className={`bi ${
          rating >= 1
            ? 'bi-star-fill'
            : rating >= 0.5
            ? 'bi-star-half'
            : 'bi-star'
        } ${color} ${size}`}
      ></i>
      <i
        className={`bi ${
          rating >= 2
            ? 'bi-star-fill'
            : rating >= 1.5
            ? 'bi-star-half'
            : 'bi-star'
        } ${color} ${size}`}
      ></i>
      <i
        className={`bi ${
          rating >= 3
            ? 'bi-star-fill'
            : rating >= 2.5
            ? 'bi-star-half'
            : 'bi-star'
        } ${color} ${size}`}
      ></i>
      <i
        className={`bi ${
          rating >= 4
            ? 'bi-star-fill'
            : rating >= 3.5
            ? 'bi-star-half'
            : 'bi-star'
        } ${color} ${size}`}
      ></i>
      <i
        className={`bi ${
          rating >= 5
            ? 'bi-star-fill'
            : rating >= 4.5
            ? 'bi-star-half'
            : 'bi-star'
        } ${color} ${size}`}
      ></i>
    </>
  )
}

Rating.defaultProps = {
  color: 'text-warning',
  size: ''
}

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  color: PropTypes.string,
  size: PropTypes.string
}

export default Rating
