import React, { useState } from 'react'

// components
import Message from '../shared/Message'
import Review from './Review'

// config
import { shownReviews } from '../../config'

const ReviewList = ({ reviews, isShown, productId, handleRemoveReview }) => {
  // console.log('reviews:', reviews)

  if (reviews.length === 0)
    return (
      <div className="border-bottom mb-4 pb-5">
        <Message message="No reviews" />
      </div>
    )

  return (
    <>
      {reviews.length > 0 &&
        reviews
          .slice(0)
          .reverse()
          .map((review, index) => (
            <Review
              review={review}
              shown={shownReviews > index ? true : isShown}
              key={review._id}
              productId={productId}
              handleRemoveReview={handleRemoveReview}
            />
          ))}
    </>
  )
}

export default ReviewList
