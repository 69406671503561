import React from 'react'

const ReviewFilterBar = () => {
  return (
    <div className="border-bottom mb-4 pb-4">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="text-muted-- mb-0">Sort on</h5>
        <select
          className="custom-select w-50 float-right"
          defaultValue={'newest'}
          disabled={true}
        >
          <option value="mostRecent" data-select2-id="3">
            Most recent
          </option>
          <option value="relevant" data-select2-id="4">
            Relevant
          </option>
          <option value="helpful" data-select2-id="5">
            Helpful
          </option>
          <option value="newest" data-select2-id="6">
            Newest
          </option>
        </select>
      </div>
    </div>
  )
}

export default ReviewFilterBar
