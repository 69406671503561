import React, { useState, useMemo, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { logout, getUserDetails } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'

import UserPassword from '../components/user/UserPassword'
import UserProfile from '../components/user/UserProfile'
import UserOrders from '../components/user/UserOrders'
import UserWishlist from '../components/user/UserWishlist'
import UserNav from '../components/user/UserNav'
import Loader from '../components/shared/Loader'
import Message from '../components/shared/Message'

// utils
import { isEmptyObj } from '../utils/index'
import UserReviews from '../components/user/UserReviews'

const AccountScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { slug } = useParams()

  const [userProfile, setUserProfile] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    picture: ''
  })

  const fullname = useMemo(() => {
    return `${userProfile.firstname} ${userProfile.lastname}`.trim()
  }, [userProfile])

  const accountTitles = {
    profile: 'Profile',
    password: 'Password',
    orders: 'My Orders',
    wishlist: 'My Wishlist',
    reviews: 'My Reviews'
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const logoutHandler = () => {
    dispatch(logout())
  }

  const renderComponent = (slug) => {
    switch (slug) {
      case 'profile':
        return (
          <UserProfile
            user={user}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            fullname={fullname}
            title={accountTitles.profile}
          />
        )
      case 'password':
        return <UserPassword title={accountTitles.password} />
      case 'orders':
        return <UserOrders title={accountTitles.orders} />
      case 'wishlist':
        return <UserWishlist title={accountTitles.wishlist} />
      case 'reviews':
        return <UserReviews title={accountTitles.reviews} />
      default:
        return (
          <UserProfile
            user={user}
            userProfile={userProfile}
            setUserProfile={setUserProfile}
            fullname={fullname}
            title={accountTitles.profile}
          />
        )
    }
  }

  const renderTitle = (slug) => {
    switch (slug) {
      case 'profile':
        return accountTitles.profile
      case 'password':
        return accountTitles.password
      case 'orders':
        return accountTitles.orders
      case 'wishlist':
        return accountTitles.wishlist
      case 'reviews':
        return accountTitles.reviews
      default:
        return accountTitles.profile
    }
  }

  useEffect(() => {
    if (!userInfo) {
      navigate('/login')
    } else {
      if (isEmptyObj(user) || success) {
        // dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails())
      } else {
        setUserProfile((prevState) => ({
          ...prevState,
          username: user.username
        }))
        setUserProfile((prevState) => ({ ...prevState, email: user.email }))
        setUserProfile((prevState) => ({
          ...prevState,
          firstname: user.firstname
        }))
        setUserProfile((prevState) => ({
          ...prevState,
          lastname: user.lastname
        }))
        setUserProfile((prevState) => ({
          ...prevState,
          picture: user.picture
        }))
      }
    }
  }, [userInfo, navigate, dispatch, user, success])

  if (loading)
    return (
      <div className="py-5">
        <Loader />
      </div>
    )
  if (error)
    return (
      <div className="py-5">
        <Message message={error} />
      </div>
    )

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-start mb-5 pb-3">
            <div>
              <h1 className="h3 mb-1">{renderTitle(slug)}</h1>
              <span className="text-muted font-weight-light">
                <Link to="/account" className="text-dark">
                  Account
                </Link>{' '}
                / {renderTitle(slug)}
              </span>
            </div>
            <Link
              to="/"
              className="btn btn-primary d-none d-lg-block"
              onClick={logoutHandler}
            >
              Log Out
            </Link>
            <button
              type="button"
              className="btn btn-primary d-lg-none collapsed"
              aria-label="Toggle navigation"
              aria-expanded="false"
              aria-controls="sidebarNav"
              data-toggle="collapse"
              data-target="#sidebarNav"
            >
              Menu
            </button>
          </div>
        </div>
      </div>

      <div className="container-overlap container">
        <div className="row">
          <div className="col-lg-3">
            <UserNav
              userProfile={userProfile}
              fullname={fullname}
              slug={slug}
            />
          </div>
          {/* navbar card */}

          <div className="col-lg-9">{renderComponent(slug)}</div>
        </div>
      </div>
    </>
  )
}

export default AccountScreen
