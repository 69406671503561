import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Loader from '../components/shared/Loader'

import {
  confirmRegistationValid,
  confirmRegistration
} from '../actions/userActions'
import AlertCard from '../components/shared/AlertCard'

const ConfirmRegistrationScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { token } = useParams()

  const userConfirmRegistrationValid = useSelector(
    (state) => state.userConfirmRegistrationValid
  )
  const { loading, error, userInfo } = userConfirmRegistrationValid

  const confirmRegistrationHandler = (e) => {
    e.preventDefault()

    dispatch(confirmRegistration(token, navigate))
  }

  useEffect(() => {
    dispatch(confirmRegistationValid(token))
  }, [dispatch, token])

  if (loading)
    return (
      <div className="py-5">
        <Loader />
      </div>
    )
  if (error)
    return (
      <>
        <div className="band band-fat">
          <div className="pb-3 mb-5"></div>
        </div>
        <AlertCard
          message={error}
          icon="exclamation-circle"
          cardBorder={false}
          button={{ label: 'Go Back Home', action: '/' }}
        />
      </>
    )

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-end pb-3 mb-5">
            <div>
              <h1 className="h3 mb-1">Confirm Registration</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container container-overlap container-overlap-lg">
        {/* <div className="row">
          <div className="col-lg-7 col-xl-6 mx-auto">
            <div className="card shadow pt-0">
              <div className="card-body px-4 py-4">
                <p className="lead text-center mb-4 mt-3">
                  Please click below button to activate your account.
                </p>
                <button
                  className="btn btn-lg px-4 mb-3 btn-primary transition-3d-hover text-uppercase d-block mx-auto"
                  onClick={confirmRegistrationHandler}
                >
                  activate
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <AlertCard
          message="Please click below button to activate your account."
          icon="lock"
          cardBorder={false}
          button={{ label: 'ACTIVE', action: '/' }}
          handler={confirmRegistrationHandler}
        />
      </div>
    </>
  )
}

export default ConfirmRegistrationScreen
