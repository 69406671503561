import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// actions
import { savePaymentMethod } from '../../actions/cartActions'

// utils
import { isEmptyObj } from '../../utils/index'

const Payment = () => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const cart = useSelector((state) => state.cart)
  const { shippingAddress, paymentMethod: paymentMethodFromStore } = cart

  const [paymentMethod, setPaymentMethod] = useState(paymentMethodFromStore)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEmptyObj(shippingAddress) || !userInfo) {
      navigate('/shipping')
    }
  }, [shippingAddress, navigate, userInfo])

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(savePaymentMethod(paymentMethod, navigate))
    navigate('/place-order')
  }

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <h2 className="h5 mb-3">Select Method</h2>
        <div className="form-group">
          <div className="custom-control custom-radio custom-control-inline mt-2">
            <input
              type="radio"
              id="paypal"
              name="paymentMethod"
              className="custom-control-input"
              value="PayPal"
              onChange={(e) => {
                setPaymentMethod(e.target.value)
              }}
              checked={paymentMethod === 'PayPal'}
            />
            <label
              className="custom-control-label custom-control-label-radio"
              htmlFor="paypal"
            >
              PayPal or Credit Card
            </label>
          </div>
          {/* <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="stripe"
              name="paymentMethod"
              className="custom-control-input"
              value="Stripe"
              onChange={(e) => {
                setPaymentMethod(e.target.value)
              }}
              checked={paymentMethod === 'Stripe'}
            />
            <label className="custom-control-label" htmlFor="stripe">
              Stripe
            </label>
          </div> */}
        </div>
        <input type="hidden" name="id" value="" />
        <button
          type="submit"
          className={`btn btn-primary text-uppercase  mt-3 px-4 ${
            !paymentMethod && 'disabled'
          }`}
        >
          Continue
        </button>
      </form>
    </>
  )
}

export default Payment
