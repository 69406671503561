import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import $ from 'jquery'

import { logout } from '../../actions/userActions'

const UserNav = ({ userProfile, fullname, slug }) => {
  const dispatch = useDispatch()

  const logoutHandler = () => {
    dispatch(logout())
  }

  const closeSidebarNav = () => {
    $('#sidebarNav').removeClass('show')
    $('div[data-target="#sidebarNav"]')
      .attr('aria-expanded', false)
      .addClass('collapsed')
  }

  useEffect(() => {
    $(document).on('click', '#sidebarNav .list-group-item', () =>
      closeSidebarNav()
    )
  }, [])

  return (
    <div className="navbar-expand-lg navbar-expand-lg-collapse-block navbar-light">
      <div id="sidebarNav" className="navbar-collapse navbar-vertical collapse">
        <div className="card card-no-border- w-100 shadow">
          <div className="card-body p-4">
            <div>
              <div className="mt-2 mb-5 text-center">
                <img
                  className="rounded-circle pic-border profile-img mb-4 shadow-sm"
                  width="100"
                  src={userProfile.picture}
                  alt="..."
                />
                <h4 className="card-title">
                  {fullname ? fullname : userProfile.username}
                </h4>
                <p className="card-text small">{userProfile.email}</p>
              </div>
              <h6 className="text-uppercase small font-weight-bold">Account</h6>
            </div>
            {/* Account */}

            <div className="list-group">
              <NavLink
                to="/account/profile"
                className={`list-group-item list-group-item-action d-flex justify-content-between ${
                  typeof slug === 'undefined' && ' active'
                }`}
              >
                <span className="bi bi-person-fill list-group-item-icon">
                  <span>Personal info</span>
                </span>
              </NavLink>
              <NavLink
                to="/account/password"
                className="list-group-item list-group-item-action d-flex justify-content-between"
              >
                <span className="bi bi-shield-shaded list-group-item-icon">
                  <span>Login & security</span>
                </span>
              </NavLink>
              <NavLink
                to="/account/notifications"
                style={{ pointerEvents: 'none', opacity: '.1' }}
                className="list-group-item list-group-item-action d-flex justify-content-between"
              >
                <span className="bi bi-bell-fill list-group-item-icon">
                  <span>Notification</span>
                </span>
                <span className="badge badge-secondary list-group-item-badge">
                  1
                </span>
              </NavLink>
              <NavLink
                to="/account/preferences"
                style={{ pointerEvents: 'none', opacity: '.1' }}
                className="list-group-item list-group-item-action d-flex justify-content-between"
              >
                <span className="bi bi-gear-fill list-group-item-icon">
                  <span>Preferences</span>
                </span>
              </NavLink>
            </div>
            <div style={{ opacity: '1', display: 'none--' }}>
              {' '}
              <h6 className="text-uppercase small font-weight-bold">
                Shopping
              </h6>
              <div className="list-group">
                <NavLink
                  to="/account/orders"
                  className="list-group-item list-group-item-action d-flex justify-content-between"
                >
                  <span className="bi bi-receipt-cutoff list-group-item-icon">
                    {/* <span className="bi bi-basket2-fill list-group-item-icon"> */}
                    <span>Orders</span>
                  </span>
                </NavLink>
                <NavLink
                  to="/account/wishlist"
                  // style={{ pointerEvents: 'none', opacity: '.1' }}
                  className="list-group-item list-group-item-action d-flex justify-content-between"
                >
                  <span className="bi bi-suit-heart-fill list-group-item-icon">
                    <span>Wishlist</span>
                  </span>
                  <span className="badge badge-secondary list-group-item-badge">
                    {}
                  </span>
                </NavLink>
                <NavLink
                  to="/account/reviews"
                  // style={{ pointerEvents: 'none', opacity: '.1' }}
                  className="list-group-item list-group-item-action d-flex justify-content-between"
                >
                  <span className="bi bi-star-half list-group-item-icon">
                    <span>Reviews</span>
                  </span>
                  <span className="badge badge-secondary list-group-item-badge">
                    {}
                  </span>
                </NavLink>
              </div>
            </div>
            {/* Shopping */}
            <div style={{ opacity: '.1', display: 'none' }}>
              <h6 className="text-uppercase small font-weight-bold">Billing</h6>
              <div className="list-group list-group-last">
                <NavLink
                  to="/account/billing"
                  style={{ pointerEvents: 'none' }}
                  className="list-group-item list-group-item-action d-flex justify-content-between"
                >
                  <span className="bi bi-credit-card-2-back-fill list-group-item-icon">
                    <span>Plans & payment</span>
                  </span>
                </NavLink>
                <NavLink
                  to="/account/address"
                  style={{ pointerEvents: 'none' }}
                  className="list-group-item list-group-item-action d-flex justify-content-between"
                >
                  <span className="bi bi-geo-fill list-group-item-icon">
                    <span>Address</span>
                  </span>
                </NavLink>
                <NavLink
                  to="/account/teams"
                  style={{ pointerEvents: 'none' }}
                  className="list-group-item list-group-item-action d-flex justify-content-between"
                >
                  <span className="bi bi-people-fill list-group-item-icon">
                    <span>Teams</span>
                  </span>
                  <span className="badge badge-secondary list-group-item-badge list-group-item-badge-wide">
                    +2 new users
                  </span>
                </NavLink>
              </div>
            </div>
            {/* Billing */}
            <div className="d-lg-none">
              <hr />
              <div className="list-group mb-0">
                <Link
                  to="/"
                  className="list-group-item list-group-item-action d-flex justify-content-between text-primary"
                  onClick={logoutHandler}
                >
                  <span className="bi bi-box-arrow-right list-group-item-icon">
                    <span>Log Out</span>
                  </span>
                </Link>
              </div>
            </div>
            {/* Logout (appears on small screens only) */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserNav
