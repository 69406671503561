import React from 'react'
import { Link } from 'react-router-dom'

// components
import CartItem from './CartItem'

const CartItemList = ({ cartItems }) => {
  return (
    <div className="card shadow">
      <div className="card-body py-md-4 px-4 py-3">
        {cartItems.map((item, i) => (
          <div
            className={`${
              cartItems.length === i + 1
                ? 'mb-4'
                : 'border-bottom pb-md-3 mb-md-5 mb-2 pb-5'
            }`}
            key={item.product}
          >
            <CartItem item={item} />
          </div>
        ))}
      </div>
      <div className="card-footer px-4 py-2">
        <Link
          className="btn btn-no-transaction btn-block btn-sm btn-link font-weight-bold"
          to="/"
        >
          Continue shopping
          <i className="bi bi-chevron-right position-relative top-2 ml-1"></i>
        </Link>
      </div>
    </div>
  )
}

export default CartItemList
