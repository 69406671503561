import React from 'react'

import { spinner } from '../../data-ui'

const Loader = () => {
  return (
    <img src={spinner} width={80} className="mt-5 d-block mx-auto" alt="..." />
  )
}

export default Loader
