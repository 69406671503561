import React, { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

// actions
import { listMyOrders } from '../../actions/orderActions'

// components
import Message from '../shared/Message'
import SkeletonMyOrderList from '../shared/skeletons/SkeletonMyOrderList'

// utils
import { currencyFormatter } from '../../utils/index'

// config
import { myOrdersPerPage } from '../../config'

// components
import Paginator from '../pagination/myorders/Paginator'

const UserOrders = ({ title }) => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const orderListMyOrders = useSelector((state) => state.orderListMyOrders)
  const { orders, loading, error } = orderListMyOrders

  const page = Number(searchParams.get('page')) || 1

  useEffect(() => {
    dispatch(listMyOrders(myOrdersPerPage, page))
  }, [dispatch, page])

  return (
    <div className="card pt-0 shadow">
      <div className="card-body px-0 py-4">
        <div className="d-flex justify-content-between align-items-center border-bottom mb-0 pb-3">
          <h6 className="card-header-title ml-4 mb-1">{title}</h6>
          <div className="fw-light top-min-2 position-relative mr-4 mb-1">
            {orders && orders.data && (
              <div className="small-- fw-light">
                <span className="d-none d-md-inline-block">Showing</span>{' '}
                {orders.meta.from_page} to {orders.meta.to_page} of{' '}
                {orders.meta.total_orders} orders
              </div>
            )}
          </div>
        </div>
        <div>
          {loading && <SkeletonMyOrderList />}
          {!loading && error && <Message message={error} />}
          {!loading && orders && !orders.data && (
            <Message message="No orders found" />
          )}
          {!loading && orders && orders.data && (
            <>
              <div className="table-responsive">
                <table className="table-striped- table-user mb-0 table">
                  <thead>
                    <tr>
                      <th scope="col" className="small font-weight-bold pl-4">
                        ID
                      </th>
                      <th scope="col" className="small font-weight-bold">
                        DATE
                      </th>
                      <th scope="col" className="small font-weight-bold">
                        TOTAL
                      </th>
                      <th scope="col" className="small font-weight-bold">
                        PAID
                      </th>
                      <th scope="col" className="small font-weight-bold">
                        DELIVERED
                      </th>
                      <th scope="col" className="small font-weight-bold pr-4">
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.data.map((order, index) => (
                      <tr
                        key={order._id}
                        className={`${
                          (orders.data.length !== index + 1 || true) &&
                          'tr-border-bottom'
                        }`}
                      >
                        <td className="fw-light-- pt-3 pb-3 pl-4">
                          #{`${order._id.substring(0, 9).toUpperCase()}`}
                        </td>
                        <td className="fw-light-- pt-3 pb-3 ">
                          {moment(order.createdAt).format('L')}
                        </td>
                        <td className="fw-light-- pt-3 pb-3">
                          {currencyFormatter(order.totalPrice)}
                        </td>
                        <td className="fw-light-- pt-3 pb-3">
                          {order.paidAt ? (
                            moment(order.paidAt).format('L')
                          ) : (
                            <i className="bi bi-x-circle bi-status-align text-muted before-top-2 opacity-25"></i>
                          )}
                        </td>
                        {/* <td>
                          <div className="form-group">
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input custom-control-input-lg"
                                id="deliveredSwitch2"
                                checked
                                onChange={() => {}}
                              />
                              <label
                                className="custom-control-label custom-control-label-lg"
                                htmlFor="deliveredSwitch2"
                              ></label>
                            </div>
                          </div>
                        </td> */}
                        <td className="fw-light-- pt-3 pb-3">
                          {order.deliveredAt ? (
                            moment(order.deliveredAt).format('L')
                          ) : (
                            <i className="bi bi-x-circle bi-status-align text-muted before-top-2 opacity-25"></i>
                          )}
                        </td>
                        <td className="fw-light pt-3 pb-3 pr-4">
                          <Link
                            to={`/orders/${order._id}`}
                            className="link-underline"
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="float-lg-right">
                <Paginator
                  ordersPerPage={orders.meta.orders_per_page}
                  totalPages={orders.meta.total_pages}
                  page={orders.meta.page}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserOrders
