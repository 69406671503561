import React from 'react'

// components
import AddProductForm from '../components/form/AddProductForm'

const ProductDetailsScreen = () => {
  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-5 pb-3">
            <h1 className="h3 mb-1">Product Details</h1>
          </div>
        </div>
      </div>
      <div className="container-overlap container">
        <div className="row">
          <div className="col">
            <div className="card card-no-border- pt-0 shadow">
              <div className="card-body px-4 py-4">
                <AddProductForm />
              </div>
              <div className="card-footer px-4 py-4">
                <div className="float-right">
                  <button
                    className="btn btn-secondary mr-2 px-4"
                    onClick={() => {}}
                  >
                    Cancel
                  </button>
                  {false ? (
                    <button className="btn btn-primary disabled px-4">
                      <span className="spinner-border spinner-form-button"></span>
                      Save Changes
                    </button>
                  ) : (
                    <button className="btn btn-primary px-4">
                      Save Changes
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetailsScreen
