import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'

import { SlickArrowLeft, SlickArrowRight } from '../slick/SlickArrows'

const ProductCarousel = ({ images }) => {
  const [slideFor, setSlideFor] = useState(null)
  const [slideNav, setSlideNav] = useState(null)
  const [sliderOne, setSliderOne] = useState(null)
  const [sliderTwo, setSliderTwo] = useState(null)

  const settingsFor = {
    arrows: false,
    fade: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          arrows: false,
          fade: false
        }
      }
    ]
  }
  const settingsNav = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: '110px',
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '70px'
        }
      },
      {
        breakpoint: 992,
        settings: {
          centerPadding: '180px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '90px'
        }
      },
      {
        breakpoint: 576,
        settings: {
          dot: true,
          arrows: false,
          centerPadding: '80px'
        }
      }
    ]
  }

  useEffect(() => {
    setSlideFor(sliderOne)
    setSlideNav(sliderTwo)
  }, [sliderOne, sliderTwo])

  return (
    <div className="product-detail-carousel">
      <Slider
        asNavFor={slideNav}
        ref={(slider) => setSliderOne(slider)}
        className="slider-for text-center"
        style={{ padding: '0 0 15px 0' }}
        {...settingsFor}
      >
        {images &&
          images.length > 0 &&
          images.map((image) => (
            <div className="carousel-image" key={image._id}>
              <img
                src={image.imageurl}
                className="img-fluid img-thumbnail- p-4"
                alt="..."
              />
            </div>
          ))}
      </Slider>
      <Slider
        asNavFor={slideFor}
        ref={(slider) => setSliderTwo(slider)}
        className="slider-nav d-none d-sm-block"
        style={{ padding: '0', marginBottom: '1rem' }}
        {...settingsNav}
      >
        {images &&
          images.length > 0 &&
          images.map((image) => (
            <div className="pr-3" key={image._id}>
              <img
                src={image.imageurl}
                className="img-fluid img-thumbnail px-lg-4 py-lg-1 p-2"
                alt="..."
              />
            </div>
          ))}
      </Slider>
    </div>
  )
}

export default ProductCarousel
