import React from 'react'
import { Link } from 'react-router-dom'

const AlertCard = ({ message, icon, cardBorder, button, handler }) => {
  return (
    <div className="container container-overlap space-1 space-md-2">
      <div className="row">
        <div className="col-lg-7 col-xl-6 mx-auto">
          <div
            className={`card ${cardBorder ? 'shadow' : 'card-no-border'} pt-0`}
          >
            <div className="card-body px-4 py-4">
              <div className="text-center mb-3">
                {icon && <i className={`bi bi-${icon} h1 text-primary`}></i>}
                <p className="card-text font-weight-normal mt-4">{message}</p>
                {button && (
                  <Link
                    className="btn btn-primary"
                    to={button.action}
                    onClick={handler && handler}
                  >
                    {button.label}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

AlertCard.defaultProps = {
  icon: '',
  cardBorder: true,
  button: null,
  handler: null
}
export default AlertCard
