import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// utils
import { currencyFormatter } from '../../utils/index'

const CartOrderSummary = ({
  title,
  itemSubtotal,
  itemTotalPrice,
  deliveryFee,
  taxLabel,
  tax,
  orderTotalLabel,
  orderTotalPrice,
  cartItems,
  actionLabel,
  action,
  actionDisable = true,
  isItemsShown = false,
  isDeliveryNote = false
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)

  const toggleItemsDrawerHandler = (e) => {
    e.preventDefault()
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <div className="card p-4 shadow">
      <div className="border-bottom mb-4 pb-4">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h5 mb-0">{title}</h2>
          {isItemsShown && (
            <Link
              className="text-dark"
              to="/"
              onClick={toggleItemsDrawerHandler}
            >
              <i
                className={`bi bi-chevron-${
                  isDrawerOpen ? 'up' : 'down'
                } before-top-min-3`}
              ></i>
            </Link>
          )}
        </div>
      </div>

      {isItemsShown && (
        <>
          <div className={`items-drawer ${!isDrawerOpen && 'd-none'}`}>
            {cartItems.map((item) => (
              <div className="border-bottom mb-4 pb-4" key={item.product}>
                <div className="media">
                  <Link
                    to={`/product/${item.product}`}
                    className="avatar avatar-lg position-relative mr-3"
                  >
                    <img
                      className="img-fluid"
                      src={item.image}
                      width="112"
                      alt="..."
                    />
                    <sup className="avatar-status badge-primary">
                      {item.quantity}
                    </sup>
                  </Link>
                  <div className="media-body">
                    <h2 className="h6">
                      <Link
                        to={`/product/${item.product}`}
                        className="h6 d-block text-dark mb-0"
                      >
                        {item.name}
                      </Link>
                    </h2>
                    <span className="d-block text-muted font-size-1 text-numeric">
                      {item.quantity} x {currencyFormatter(item.price)} ={' '}
                      {currencyFormatter(item.price * item.quantity)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={`items-drawer mb-4 ${isDrawerOpen && 'd-none'}`}>
            {cartItems.map((item, index) => {
              return (
                index < 3 && (
                  <Link
                    to={`/product/${item.product}`}
                    className="avatar avatar-lg position-relative mr-3 mb-3"
                    key={item.product}
                  >
                    <img
                      className="img-fluid"
                      src={item.image}
                      width="112"
                      alt="..."
                    />
                    <sup className="avatar-status badge-primary">
                      {item.quantity}
                    </sup>
                  </Link>
                )
              )
            })}
            {cartItems.length > 3 && (
              <Link
                to="/"
                className="ml-2 mb-3"
                style={{ fontSize: '17px' }}
                onClick={toggleItemsDrawerHandler}
              >
                +{cartItems.length - 3}
              </Link>
            )}
          </div>
        </>
      )}
      <div className="border-bottom mb-4 pb-4">
        <div className="media align-items-center mb-3">
          <span className="d-block font-size-1 mr-3">
            Item subtotal ({itemSubtotal})
          </span>
          <div className="media-body text-right">
            <span className="text-dark font-weight-bold text-numeric">
              {itemTotalPrice}
            </span>
          </div>
        </div>

        <div className="media align-items-center mb-3">
          <span className="d-block font-size-1 mr-3">
            Delivery {isDeliveryNote && '*'}
          </span>
          <div className="media-body text-right">
            <span className="text-dark font-weight-bold">{deliveryFee}</span>
          </div>
        </div>
      </div>

      <div className="media align-items-center mb-3">
        <span className="d-block font-size-1 mr-3">{taxLabel}</span>
        <div className="media-body text-right">
          <span className="text-dark font-weight-bold">{tax}</span>
        </div>
      </div>

      <div className="media align-items-center mb-2">
        <h6 className="text-primary mr-3">{orderTotalLabel}</h6>
        <div className="media-body text-right">
          <h5 className="text-primary font-weight-bold text-numeric">
            {orderTotalPrice}
          </h5>
        </div>
      </div>

      <div className="row">
        <div className="col my-1 px-3">
          <button
            className="btn btn-primary btn-block btn-pill transition-3d-hover text-uppercase"
            disabled={actionDisable}
            onClick={action}
          >
            {actionLabel}
          </button>
        </div>
      </div>

      {isDeliveryNote && (
        <div
          className="d-block small text-muted fw-light mt-3 pt-2"
          style={{ borderTop: '1px dotted #ddd' }}
        >
          * Free delivery when you spend over $100
        </div>
      )}
    </div>
  )
}

export default CartOrderSummary
