import React, { useEffect } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { currencyFormatter } from '../utils/index'
import {
  removeAllItemsHandler,
  checkoutHandler,
  cartCalculations
} from '../utils/cart'

import { addToCart, removeAllItemsFromCart } from '../actions/cartActions'
import AlertCard from '../components/shared/AlertCard'
import CartOrderSummary from '../components/cart/CartOrderSummary'
import CartItemList from '../components/cart/CartItemList'

const CartScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { id: productId } = useParams()
  const quantity =
    searchParams.get('quantity') && Number(searchParams.get('quantity')) > 0
      ? Number(searchParams.get('quantity'))
      : 1

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  // calculations
  cartCalculations(cart, cartItems)

  useEffect(() => {
    // document.getElementsByClassName('cart-footer')[0].style.display = 'none'
    if (productId) {
      dispatch(addToCart(productId, quantity))
    }
    navigate('/cart')
  }, [dispatch, navigate, productId, quantity])

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-top mb-5 pb-3">
            <div>
              <h1 className="h3 pr-lg-0 mb-1 pr-5">Shopping cart</h1>
              {cartItems.length > 0 && (
                <button
                  className="btn btn-sm btn-no-pad btn-flat btn-link"
                  onClick={(e) =>
                    removeAllItemsHandler(
                      e,
                      dispatch,
                      removeAllItemsFromCart,
                      navigate
                    )
                  }
                >
                  Remove all items
                </button>
              )}
            </div>
            {cartItems.length > 0 && (
              <span className="mt-1">
                {cart.itemSubtotal} {cart.itemSubtotal === 1 ? 'item' : 'items'}
              </span>
            )}
          </div>
        </div>
      </div>
      {cartItems.length === 0 ? (
        <AlertCard
          message="Your cart is empty"
          icon="basket3"
          cardBorder={false}
          button={{ label: 'Start Shopping', action: '/' }}
        />
      ) : (
        <div className="container-overlap space-1 space-md-2 container">
          <div className="row">
            <div className="col-lg-8">
              <CartItemList cartItems={cartItems} />
            </div>
            {/* CartList */}

            <div className="col-lg-4">
              <CartOrderSummary
                title={'Order summary'}
                itemSubtotal={cart.itemSubtotal}
                itemTotalPrice={currencyFormatter(cart.itemTotalPrice)}
                deliveryFee={
                  cart.shippingPrice === 0
                    ? 'Free'
                    : currencyFormatter(cart.shippingPrice)
                }
                taxLabel={'Estimated tax'}
                tax={currencyFormatter(cart.taxPrice)}
                orderTotalLabel={'Order Total'}
                orderTotalPrice={currencyFormatter(cart.orderTotalPrice)}
                cartItems={cartItems}
                actionLabel={'Proceed to Checkout'}
                action={() => checkoutHandler(navigate)}
                actionDisable={false}
                isItemsShown={false}
                isDeliveryNote={true}
              />
            </div>
            {/* Order Summary */}
          </div>
        </div>
      )}
    </>
  )
}

export default CartScreen
