import axios from 'axios'
import { toast } from 'react-toastify'

import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_LIST_MY_ORDERS_REQUEST,
  ORDER_LIST_MY_ORDERS_SUCCESS,
  ORDER_LIST_MY_ORDERS_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_SUCCESS
} from '../constants/orderConstants'

// config
import { apiBaseUrl } from '../config'

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.post(`${apiBaseUrl}/api/orders`, order, config)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data.data
    })

    toast.dismiss()
    toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: errorMsg
    })

    toast.dismiss()
    toast.error(errorMsg, { autoClose: 20000 })
  }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(`${apiBaseUrl}/api/orders/${id}`, config)

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data.data
    })

    toast.dismiss()
    toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: errorMsg
    })

    toast.dismiss()
    toast.error(errorMsg, { autoClose: 20000 })
  }
}

export const payOrder = (id, paymentResult) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_PAY_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(
      `${apiBaseUrl}/api/orders/${id}/pay`,
      paymentResult,
      config
    )

    // console.log('data:', data)

    dispatch({
      type: ORDER_PAY_SUCCESS,
      payload: data.data
    })

    toast.dismiss()
    toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: ORDER_PAY_FAIL,
      payload: errorMsg
    })

    toast.dismiss()
    toast.error(errorMsg, { autoClose: 20000 })
  }
}

export const deliverOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIVER_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.put(
      `${apiBaseUrl}/api/orders/${id}/deliver`,
      {},
      config
    )

    // console.log('data:', data)

    dispatch({
      type: ORDER_DELIVER_SUCCESS,
      payload: data.data
    })

    toast.dismiss()
    toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: errorMsg
    })

    toast.dismiss()
    toast.error(errorMsg, { autoClose: 20000 })
  }
}

export const listMyOrders = (perPage, page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_ORDERS_REQUEST
    })

    const {
      userLogin: { userInfo }
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`
      }
    }

    const { data } = await axios.get(
      `${apiBaseUrl}/api/orders/myorders/${perPage}/${page}`,
      config
    )

    dispatch({
      type: ORDER_LIST_MY_ORDERS_SUCCESS,
      payload: data
    })

    // console.log('data:', data)

    toast.dismiss()
    toast.success(data.message)
  } catch (error) {
    const errorMsg =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: ORDER_LIST_MY_ORDERS_FAIL,
      payload: errorMsg
    })

    // toast.dismiss()
    // toast.error(errorMsg)
  }
}

export const listOrders =
  (perPage, page, userId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_LIST_REQUEST
      })

      const {
        userLogin: { userInfo }
      } = getState()

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`
        }
      }

      const { data } = await axios.get(
        `${apiBaseUrl}/api/orders/${perPage}/${page}/${userId}`,
        config
      )

      dispatch({
        type: ORDER_LIST_SUCCESS,
        payload: data
      })

      // toast.dismiss()
      // toast.success(data.message)
    } catch (error) {
      const errorMsg =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      dispatch({
        type: ORDER_LIST_FAIL,
        payload: errorMsg
      })

      // toast.dismiss()
      // toast.error(errorMsg)
    }
  }
