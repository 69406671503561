import React, { useEffect, useState, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'

// utils
import { currencyFormatter } from '../utils/index'

// config
import { productsPerPage } from '../config'

// actions
import { logout } from '../actions/userActions'
import { listProducts } from '../actions/productActions'

// assets
import { logo, logoXl } from '../data-ui'

const Header = () => {
  const [isToggleMenuClicked, setIsToggleMenuClicked] = useState(true)

  const productList = useSelector((state) => state.productList)
  const { term: serachTerm } = productList

  const [term, setTerm] = useState(serachTerm)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // console.log('pathname:', pathname)

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  const logoHandler = () => {
    dispatch(listProducts(productsPerPage, 1, '*', ''))
    closeMobileMenu()
  }

  const getItemSubtotal = (cartItems) =>
    cartItems.reduce((acc, item) => acc + item.quantity, 0)

  const getItemTotalPrice = (cartItems) =>
    cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0)

  const closeMobileMenu = () => {
    $('#navbarContent').removeClass('show')
    $('.navbar-toggler').attr('aria-expanded', false)
    $('.hamburger-menu').removeClass('open')
  }

  const closeDesktopDropdownMenu = () => {
    $('.dropdown-menu, .nav-item.dropdown').removeClass('show')
    $('#navbarDropdown').attr('aria-expanded', false)
  }

  useEffect(() => {
    // setTerm(serachTerm)

    pathname.includes('/search/') ? setTerm(serachTerm) : setTerm('')

    $(document).on('click', '.nav-link-not-nested, .dropdown-item', () =>
      closeMobileMenu()
    )

    if (isToggleMenuClicked) {
      $('.hamburger-menu').on('click', function () {
        $(this).toggleClass('open')
      })
      setIsToggleMenuClicked(false)
    }

    $(window).on('resize', function () {
      if ($(window).width() >= 992) closeMobileMenu()
    })
    $(window).on('resize', function () {
      if ($(window).width() < 992) closeDesktopDropdownMenu()
    })
  }, [serachTerm, pathname, isToggleMenuClicked])

  return (
    <header className="">
      <nav className="navbar navbar-expand-lg navbar-light fixed-top nav-shadow bg-white">
        <div className="container">
          <Link
            to="/"
            className="navbar-brand font-weight-bold"
            onClick={logoHandler}
          >
            <img src={logoXl} width={190} height={47} alt="Lynashop" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="hamburger-menu">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div
            className="collapse navbar-collapse mt-lg-0 mt-2"
            id="navbarContent"
          >
            <div className="mt-lg-0 mb-lg-0 w-lg-50 mx-auto mt-3 mb-3">
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  const termDraft = term.trim()
                  if (termDraft) {
                    setTerm(termDraft)
                    navigate(`/search/${termDraft}`)
                  } else {
                    navigate('/')
                  }
                  closeMobileMenu()
                }}
              >
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="What are you looking for?"
                        name="term"
                        id="term"
                        value={term}
                        onChange={(e) => {
                          setTerm(e.target.value)
                        }}
                        style={{ paddingLeft: '20px', paddingRight: '60px' }}
                      />
                      <div className="search-input-icon">
                        <i
                          className="bi bi-search text-black-50"
                          style={{ fontSize: '1.3rem' }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <ul className="navbar-nav float-lg-right">
              <li className="nav-item mr-lg-3 mr-0">
                <Link
                  to="/cart"
                  className="nav-link nav-link-not-nested position-relative"
                >
                  <i
                    className="bi bi-cart3 mr-1"
                    style={{
                      position: 'relative',
                      top: '2px',
                      fontSize: '1.3rem'
                    }}
                  ></i>
                  <span className="position-relative top-2">Cart</span>
                  {cartItems.length > 0 && (
                    <span className="badge badge-primary position-relative font-weight-light rounded-circle cart-qty">
                      {getItemSubtotal(cartItems)}
                    </span>
                  )}
                  {cartItems.length > 0 && (
                    <span className="cart-price">
                      {currencyFormatter(getItemTotalPrice(cartItems))}
                    </span>
                  )}
                </Link>
              </li>
              {userInfo ? (
                <li className="nav-item dropdown active--">
                  <span
                    className="nav-link dropdown-toggle pr-lg-0"
                    id="navbarDropdown"
                    data-toggle="dropdown"
                    style={{ cursor: 'pointer' }}
                  >
                    <i
                      className="bi-- bi-gear-- mr-1--"
                      style={{
                        position: 'relative',
                        top: '2px',
                        fontSize: '1.3rem'
                      }}
                    ></i>
                    <img
                      src={userInfo.picture}
                      className="profile-login-icon pic-border shadow-sm"
                      alt="profile"
                    />
                    <span className="position-relative top-2">{`Hi, ${
                      userInfo.firstname
                        ? userInfo.firstname
                        : userInfo.lastname
                        ? userInfo.lastname
                        : userInfo.username
                    }`}</span>
                  </span>
                  <div className="dropdown-menu">
                    <Link to="/account" className="dropdown-item active-">
                      <i className="bi bi-person mr-2"></i>
                      Account
                    </Link>
                    {userInfo.isAdmin && (
                      <>
                        <div className="dropdown-divider"></div>
                        <Link to="/users" className="dropdown-item">
                          <i className="bi bi-people mr-2"></i>
                          Users
                        </Link>
                        <Link to="/products" className="dropdown-item">
                          <i className="bi bi-basket3 mr-2"></i>
                          Products
                        </Link>
                        <Link to="/orders" className="dropdown-item">
                          <i className="bi bi-receipt-cutoff mr-2"></i>
                          Orders
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link to="/settings" className="dropdown-item">
                          <i className="bi bi-gear mr-2" aria-hidden="true"></i>
                          Settings
                        </Link>
                      </>
                    )}
                    <div className="dropdown-divider"></div>
                    <Link
                      to="/"
                      className="dropdown-item text-primary"
                      onClick={logoutHandler}
                    >
                      <i className="bi bi-box-arrow-right mr-2"></i>
                      Logout
                    </Link>
                  </div>
                </li>
              ) : (
                <li className="nav-item">
                  <Link to="/login" className="nav-link nav-link-not-nested">
                    <i
                      className="bi bi-person mr-1"
                      style={{
                        position: 'relative',
                        top: '2px',
                        fontSize: '1.3rem'
                      }}
                    ></i>
                    <span className="position-relative top-2">Sign In</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
