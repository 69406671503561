import React from 'react'

// images
import {
  previewOnExpoDarkBorder5,
  previewOnExpoDarkBorder5Rounded
} from '../data-ui'

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
          <p className="font-weight-light">Youcef Developer &copy; 2022</p>
          <p className="font-weight-light">
            Technologies&nbsp;&nbsp;
            <span className="badge badge-soft my-1 ml-1 mr-1">MERN Stack</span>
            <span className="badge badge-soft my-1 mr-1">MongoDB</span>
            <span className="badge badge-soft my-1 mr-1">Mongoose</span>
            <span className="badge badge-soft my-1 mr-1">ExpressJS</span>
            <span className="badge badge-soft my-1 mr-1">React</span>
            <span className="badge badge-soft my-1 mr-1">Redux</span>
            <span className="badge badge-soft my-1 mr-1">NodeJS</span>
            <span className="badge badge-soft my-1 mr-0">Bootstrap</span>
          </p>
        </div>
        <div className="font-weight-light mb-lg-0 mt-4 mb-3 text-center">
          <div className="text-lowercase mb-2">Mobile App</div>
          <a
            href="https://expo.dev/@youcefdeveloper/lynashop-mobile-app?serviceType=classic&distribution=expo-go"
            target="_blank"
            rel="noreferrer"
            title="Preview it on Expo"
          >
            <img
              src={previewOnExpoDarkBorder5Rounded}
              width={168}
              height={56}
              alt="Preview it on Expo"
            />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
