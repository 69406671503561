import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_DETAILS_RESET,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  USER_CONFIRM_REGISTRATION_VALID_REQUEST,
  USER_CONFIRM_REGISTRATION_VALID_SUCCESS,
  USER_CONFIRM_REGISTRATION_VALID_FAIL,
  USER_CONFIRM_REGISTRATION_REQUEST,
  USER_CONFIRM_REGISTRATION_SUCCESS,
  USER_CONFIRM_REGISTRATION_FAIL,
  USER_RESET_PASSWORD_VALID_REQUEST,
  USER_RESET_PASSWORD_VALID_SUCCESS,
  USER_RESET_PASSWORD_VALID_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_REMOVE_REQUEST,
  USER_REMOVE_SUCCESS,
  USER_REMOVE_FAIL,
  USER_ADD_REQUEST,
  USER_ADD_SUCCESS,
  USER_ADD_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_RESET
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true }
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload }
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case USER_DETAILS_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { ...state, loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userUpdatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PASSWORD_REQUEST:
      return { ...state, loading: true }
    case USER_UPDATE_PASSWORD_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_UPDATE_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userForgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true }
    case USER_FORGOT_PASSWORD_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_FORGOT_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userResetPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_REQUEST:
      return { ...state, loading: true }
    case USER_RESET_PASSWORD_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_RESET_PASSWORD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userConfirmRegistrationReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONFIRM_REGISTRATION_REQUEST:
      return { ...state, loading: true }
    case USER_CONFIRM_REGISTRATION_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_CONFIRM_REGISTRATION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userResetPasswordValidReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESET_PASSWORD_VALID_REQUEST:
      return { ...state, loading: true }
    case USER_RESET_PASSWORD_VALID_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_RESET_PASSWORD_VALID_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

export const userConfirmRegistrationValidReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CONFIRM_REGISTRATION_VALID_REQUEST:
      return { ...state, loading: true }
    case USER_CONFIRM_REGISTRATION_VALID_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_CONFIRM_REGISTRATION_VALID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true }
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userRemoveReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case USER_REMOVE_REQUEST:
      return { loading: true, success: false }
    case USER_REMOVE_SUCCESS:
      return { loading: false, success: true }
    case USER_REMOVE_FAIL:
      return { loading: false, success: false, error: action.payload }
    default:
      return state
  }
}

export const userAddReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case USER_ADD_REQUEST:
      return { loading: true, success: false }
    case USER_ADD_SUCCESS:
      return { loading: false, success: true, order: action.payload }
    case USER_ADD_FAIL:
      return { loading: false, success: false, error: action.payload }
    default:
      return state
  }
}

export const userEditReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_EDIT_REQUEST:
      return { ...state, loading: true }
    case USER_EDIT_SUCCESS:
      return { loading: false, user: action.payload }
    case USER_EDIT_FAIL:
      return { loading: false, error: action.payload }
    case USER_EDIT_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { ...state, loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload }
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_RESET:
      return {}
    default:
      return state
  }
}
