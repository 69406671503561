import React from 'react'
import { Link } from 'react-router-dom'

const Steps = ({ step }) => {
  return (
    <ul className="nav nav-pills nav-segment nav-fill d-block d-md-flex mb-3">
      <li className="nav-item text-md-center text-left">
        <Link
          className={`nav-link ${
            step < 1
              ? 'step step1 disabled'
              : step === 1
              ? 'step step1 active'
              : 'checked disabled'
          }`}
          to="/signin"
        >
          Sign In
        </Link>
      </li>
      <li className="nav-item text-md-center text-left">
        <Link
          className={`nav-link ${
            step < 2
              ? 'step step2 disabled'
              : step === 2
              ? 'step step2 active'
              : 'checked disabled'
          }`}
          to="/shipping"
        >
          Shipping
        </Link>
      </li>
      <li className="nav-item text-md-center text-left">
        <Link
          className={`nav-link ${
            step < 3
              ? 'step step3 disabled'
              : step === 3
              ? 'step step3 active'
              : 'checked disabled'
          }`}
          to="/payment"
        >
          Payment
        </Link>
      </li>
      <li className="nav-item text-md-center text-left">
        <Link
          className={`nav-link ${
            step < 4
              ? 'step step4 disabled'
              : step === 4
              ? 'step step4 active'
              : 'checked disabled'
          }`}
          to="/place-order"
        >
          Place Order
        </Link>
      </li>
    </ul>
  )
}

export default Steps
