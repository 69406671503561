import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { paging, updateQueryString } from '../../../utils/index'

// actions
import { listProducts } from '../../../actions/productActions'

const Pagination = ({ prevNext, theme, mode, keyword }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const productList = useSelector((state) => state.productList)
  const {
    meta: { products_per_page: productsPerPage, total_pages: totalPages, page },
    term,
    sort,
    filter
  } = productList

  const updateParams = (page, term) => {
    const updatedParams = updateQueryString(
      filter,
      { ...filter, page, term },
      pathname
    )
    window.history.pushState(null, '', `${updatedParams}`)
  }

  const pageNavHandler = (e, page) => {
    e.preventDefault()
    dispatch(
      listProducts(productsPerPage, page, sort, keyword, {
        ...filter,
        page
      })
    )
    updateParams(page, keyword)
  }

  return (
    totalPages > 1 && (
      <>
        {theme === 'default' ? (
          <ul
            className={`pagination justify-content-center mb-0 ${
              mode !== 'frontend' ? 'pagination-square mb-0 mb-0' : ''
            }`}
          >
            {prevNext && (
              <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                {page === 1 ? (
                  <span className="page-link">
                    <i className="bi bi-chevron-left"></i>
                  </span>
                ) : (
                  <Link
                    className="page-link"
                    to={`#${page - 1}`}
                    onClick={(e) => pageNavHandler(e, page - 1)}
                    title="Previous Page"
                  >
                    <i className="bi bi-chevron-left"></i>
                  </Link>
                )}
              </li>
            )}
            {/* previous */}

            {paging(page, totalPages).map((p, i) =>
              p === '...' ? (
                <li className="page-item page-item-dots disabled" key={i}>
                  <span className="page-link">...</span>
                </li>
              ) : (
                <li
                  className={`page-item ${p === page ? 'active' : ''}`}
                  key={i}
                >
                  <Link
                    className="page-link"
                    to={`#${p}`}
                    onClick={(e) => pageNavHandler(e, p)}
                    title={`Page ${p}`}
                  >
                    {p}
                  </Link>
                </li>
              )
            )}

            {prevNext && (
              <li
                className={`page-item ${page === totalPages ? 'disabled' : ''}`}
              >
                {page === totalPages ? (
                  <span className="page-link">
                    <i className="bi bi-chevron-right"></i>
                  </span>
                ) : (
                  <Link
                    className="page-link me-0"
                    to={`#${page + 1}`}
                    onClick={(e) => pageNavHandler(e, page + 1)}
                    title="Next Page"
                  >
                    <i className="bi bi-chevron-right"></i>
                  </Link>
                )}
              </li>
            )}
            {/* next */}
          </ul>
        ) : theme === 'dropdown' ? (
          <div className="d-flex justify-content-between align-items-center">
            {prevNext && (
              <div>
                <button
                  className={`btn btn-default shadow-none ${
                    page === 1 ? 'btn-pointer disabled' : ''
                  }`}
                  title="Previous Page"
                  onClick={(e) => {
                    page !== 1 && pageNavHandler(e, page - 1)
                  }}
                >
                  <i className="bi bi-chevron-left"></i>
                  <span className="d-none d-sm-inline-block position-relative top-min-1 ml-1">
                    Prev
                  </span>
                </button>
              </div>
            )}
            {/* previous */}

            <div className="mx-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="btn btn-pointer pl-0">Page</div>
                <div>
                  <select
                    className="custom-select mr-2"
                    value={page}
                    onChange={(e) => {
                      pageNavHandler(e, Number(e.target.value))
                    }}
                  >
                    {[...Array(totalPages)].map((_, i) => (
                      <option value={i + 1} key={i}>
                        {i + 1}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="btn btn-pointer pr-0">of {totalPages}</div>
              </div>
            </div>

            {prevNext && (
              <div>
                <button
                  className={`btn btn-default shadow-none ${
                    page === totalPages ? 'btn-pointer disabled' : ''
                  }`}
                  title="Next Page"
                  onClick={(e) => {
                    page !== totalPages && pageNavHandler(e, page + 1)
                  }}
                >
                  <span className="d-none d-sm-inline-block position-relative top-min-1 mr-1">
                    Next
                  </span>
                  <i className="bi bi-chevron-right"></i>
                </button>
              </div>
            )}
            {/* next */}
          </div>
        ) : (
          <div className={`paging ${mode === 'frontend' ? '' : 'pb-0'}`}>
            <div className="paging-carrier">
              <div>
                {page === 1 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ccc"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="arcs"
                  >
                    <path d="M15 18l-6-6 6-6"></path>
                  </svg>
                ) : (
                  <Link
                    title="Previous Page"
                    to={`#${page}`}
                    onClick={(e) => pageNavHandler(e, page - 1)}
                    className="disabled"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="arcs"
                    >
                      <path d="M15 18l-6-6 6-6"></path>
                    </svg>
                  </Link>
                )}
              </div>
              <span>
                <span className="font-weight-light">{page}</span>
                <span className=""> / {totalPages}</span>
              </span>
              <div>
                {page === totalPages ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ccc"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="arcs"
                  >
                    <path d="M9 18l6-6-6-6"></path>
                  </svg>
                ) : (
                  <Link
                    title="Next Page"
                    to={`#${page}`}
                    onClick={(e) => pageNavHandler(e, page + 1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="arcs"
                    >
                      <path d="M9 18l6-6-6-6"></path>
                    </svg>
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )
  )
}

Pagination.defaultProps = {
  prevNext: true,
  theme: 'default'
}

export default Pagination
