import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { generateRandomPassword } from '../../utils/index'

// actions
import { editUser, updateUser, listUsers } from '../../actions/userActions'

// components
import Loader from '../../components/shared/Loader'
import Message from '../../components/shared/Message'

// utils
import {
  togglePassword,
  validateUsername,
  validateEmail,
  validatePassword,
  isEmptyObj
} from '../../utils/index'

// config
import { userListPerPage } from '../../config'

const EditUserForm = ({
  userId,
  isInitialRender,
  setIsInitialRender,
  isChangePassword,
  setIsChangePassword,
  keyword,
  setKeyword
}) => {
  const userEdit = useSelector((state) => state.userEdit)
  const { loading: loadingEdit, error: errorEdit, user } = userEdit

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate
  } = userUpdate

  const dispatch = useDispatch()

  // init states
  const initialUserValues = {
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    confirmPassword: '',
    picture: '',
    role: 'user',
    status: 'inactive'
  }
  const [userFormValues, setUserFormValues] = useState(initialUserValues)
  const [userFormErrors, setUserFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [selectedPicture, setSelectedPicture] = useState(null)

  // handle form fields changes
  const handleChange = (e) => {
    let { name, value } = e.target
    setUserFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value
    }))
  }

  // handle upload picture
  const handleUploadPicture = async (e) => {
    const pictureObj = e.target.files[0]
    setSelectedPicture(pictureObj)

    e.target.parentElement.getElementsByTagName('label')[0].textContent =
      pictureObj.name

    setUserFormValues((prevFormValues) => ({
      ...prevFormValues,
      picture: URL.createObjectURL(pictureObj)
    }))
  }

  // validate form
  const validate = (values) => {
    const errors = {}

    if (!values.username.trim()) errors.username = 'Username is required'
    else if (!validateUsername(values.username.trim()))
      errors.username = 'Username is not valid'

    if (!values.email.trim()) errors.email = 'Email is required'
    else if (!validateEmail(values.email.trim()))
      errors.email = 'Email is not valid'

    if (isChangePassword) {
      if (!values.password) errors.password = `Password is required`
      else if (!validatePassword(values.password))
        errors.password = 'Password is not valid'

      if (!values.confirmPassword)
        errors.confirmPassword = `Confirm password is required`
      else if (values.password !== values.confirmPassword)
        errors.confirmPassword = 'Passwords do not match'
    }

    return errors
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()

    setUserFormErrors(validate(userFormValues))
    setIsSubmit(true)

    // TODO: UPDATE USER
    let userFormData = new FormData()
    userFormData.append('username', userFormValues.username)
    userFormData.append('email', userFormValues.email)
    userFormData.append('firstname', userFormValues.firstname)
    userFormData.append('lastname', userFormValues.lastname)
    userFormData.append('picture', selectedPicture)
    userFormData.append('role', userFormValues.role)
    userFormData.append('status', userFormValues.status)
    userFormData.append('isChangePassword', isChangePassword)
    userFormData.append('password', userFormValues.password)
    userFormData.append('confirmPassword', userFormValues.confirmPassword)

    // DIPATCH UPDATE USER
    dispatch(updateUser(userId, userFormData))
  }

  // handle show/hide password
  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  const showPasswords = () => {
    // show passwords
    document.getElementById('passwordEdit').type = 'text'
    document.getElementById('confirmPasswordEdit').type = 'text'
    document.getElementById('pw-eye-icon-edit').classList.remove('bi-eye')
    document.getElementById('pw-eye-icon-edit').classList.add('bi-eye-slash')
    document.getElementById('cpw-eye-icon-edit').classList.remove('bi-eye')
    document.getElementById('cpw-eye-icon-edit').classList.add('bi-eye-slash')
  }

  const hidePasswords = () => {
    // hide passwords
    document.getElementById('passwordEdit').type = 'password'
    document.getElementById('confirmPasswordEdit').type = 'password'
    document.getElementById('pw-eye-icon-edit').classList.remove('bi-eye-slash')
    document.getElementById('pw-eye-icon-edit').classList.add('bi-eye')
    document
      .getElementById('cpw-eye-icon-edit')
      .classList.remove('bi-eye-slash')
    document.getElementById('cpw-eye-icon-edit').classList.add('bi-eye')
  }

  // change password toggle
  const changePasswordToggleHandler = () => {
    setIsChangePassword(!isChangePassword)
    if (isChangePassword) {
      // reset password and confirm password fields
      setUserFormValues((prevFormValues) => ({
        ...prevFormValues,
        password: '',
        confirmPassword: ''
      }))

      // reset password and confirm password errors
      setUserFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        password: '',
        confirmPassword: ''
      }))

      // hide passwords
      hidePasswords()
    }
  }

  const generatePasswordHandler = (e) => {
    e.preventDefault()
    const randomPassword = generateRandomPassword()
    setUserFormValues((prevFormValues) => ({
      ...prevFormValues,
      password: randomPassword,
      confirmPassword: randomPassword
    }))

    // show passwords
    showPasswords()
  }

  const fullname = useMemo(() => {
    return `${userFormValues.firstname} ${userFormValues.lastname}`.trim()
  }, [userFormValues])

  // refs and callbacks
  const _initialUserValuesRef = useRef(initialUserValues).current
  const _editFormCb = useCallback(async () => {
    if (!isEmptyObj(user)) {
      setUserFormValues((prevFormValues) => ({
        ...prevFormValues,
        username: user.username,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        picture: user.picture,
        role: user.isAdmin ? 'admin' : 'user',
        status: user.active ? 'active' : 'inactive',
        password: '',
        confirmPassword: ''
      }))
    }
  }, [user])

  useEffect(() => {
    if (userId && isInitialRender) {
      dispatch(editUser(userId))
      setIsInitialRender(false)
    }

    _editFormCb()

    if (Object.keys(userFormErrors).length === 0 && successUpdate && isSubmit) {
      // close the modal
      document.querySelector('.closeEditModal').click()

      // list users
      dispatch(listUsers(userListPerPage, 1, keyword))

      // update keyword
      setKeyword(keyword)

      // reset form
      // setUserFormValues(_initialUserValuesRef)
    }
  }, [
    dispatch,
    userId,
    isInitialRender,
    setIsInitialRender,
    _editFormCb,
    successUpdate,
    _initialUserValuesRef,
    isSubmit,
    userFormErrors,
    keyword,
    setKeyword
  ])

  if (loadingEdit)
    return (
      <div className="mb-5 pb-5">
        <Loader />
      </div>
    )
  if (errorEdit)
    return (
      <div className="mb-5 pb-5">
        <Message message={loadingEdit} />
      </div>
    )

  return (
    <>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '20px 20px 5px 20px',
          margin: '0 0 20px 0',
          borderRadius: '10px'
        }}
        className="d-none"
      >
        <h4 className="mb-4">DEGUB</h4>
        <pre>formValues: {JSON.stringify(userFormValues, undefined, 2)}</pre>
        <pre>formErrors: {JSON.stringify(userFormErrors, undefined, 2)}</pre>
        <pre>isSubmit: {JSON.stringify(isSubmit, undefined, 2)}</pre>
      </div>
      {user && (
        <form
          encType="multipart/form-data"
          autoComplete="false"
          onSubmit={handleSubmit}
          noValidate
        >
          <div className="media position-relative mb-4">
            <img
              src={userFormValues.picture}
              width="100"
              className="rounded-circle pic-border profile-img mr-3 shadow-sm"
              alt="..."
            />
            <div className="media-body mt-3">
              <h4 className="card-title">
                {fullname ? fullname : userFormValues.username}
              </h4>
              <p className="card-text text-muted text-small fw-light">
                {userFormValues.email}
              </p>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="usernameEdit">
              Username{' '}
              <small className="x-small text-muted font-weight-light opacity-75">
                (required)
              </small>
            </label>
            <input
              type="text"
              autoComplete="true"
              className={`form-control form-control-lg ${
                userFormErrors.username && 'is-invalid'
              }`}
              name="username"
              id="usernameEdit"
              value={userFormValues.username}
              onChange={handleChange}
            />
            {userFormErrors.username ? (
              <div className="invalid-feedback fw-light">
                {userFormErrors.username}
              </div>
            ) : (
              <small className="form-text text-muted font-weight-light x-small">
                A valid username should start with an alphabet, then other
                characters can be alphabets, numbers or underscores. When its
                length consists of 6 to 28 characters and it's case insensitive
                (e.g. <i>hyusuf</i>, <i>yusuf22</i>, <i>Yusuf_22</i>,{' '}
                <i>h_yusuf_22</i>).
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="emailEdit">
              Email{' '}
              <small className="x-small text-muted font-weight-light opacity-75">
                (required)
              </small>
            </label>
            <input
              type="email"
              className={`form-control form-control-lg ${
                userFormErrors.email && 'is-invalid'
              }`}
              name="email"
              id="emailEdit"
              value={userFormValues.email}
              onChange={handleChange}
            />
            {userFormErrors.email && (
              <div className="invalid-feedback fw-light">
                {userFormErrors.email}
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="firstnameEdit">Firstname</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="firstnameEdit"
                  name="firstname"
                  value={userFormValues.firstname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="lastnameEdit">Lastname</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="lastnameEdit"
                  name="lastname"
                  value={userFormValues.lastname}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="changePasswordToggle"
                onChange={changePasswordToggleHandler}
              />
              <label
                htmlFor="changePasswordToggle"
                className="custom-control-label custom-checkbox-label"
              >
                Change Password
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="passwordEdit">
                  Password{' '}
                  {isChangePassword && (
                    <small className="x-small text-muted font-weight-light opacity-75">
                      (required)
                    </small>
                  )}
                </label>
                {isChangePassword && (
                  <Link
                    to="/users"
                    className="text-muted link-underline small fw-light float-right mt-0"
                    onClick={generatePasswordHandler}
                  >
                    Generate password
                  </Link>
                )}
                <div className="position-relative">
                  <input
                    type="password"
                    autoComplete="new-password"
                    className={`form-control form-control-lg ${
                      userFormErrors.password &&
                      isChangePassword &&
                      'is-invalid'
                    }`}
                    name="password"
                    id="passwordEdit"
                    style={{ paddingRight: '60px' }}
                    value={userFormValues.password}
                    onChange={handleChange}
                    disabled={!isChangePassword}
                  />
                  <div className="show-password-icon">
                    <i
                      className="bi bi-eye"
                      id="pw-eye-icon-edit"
                      style={{ cursor: 'pointer' }}
                      onClick={handleShowHidePassword}
                    ></i>
                  </div>
                  {userFormErrors.password && isChangePassword ? (
                    <div className="invalid-feedback fw-light">
                      {userFormErrors.password}
                    </div>
                  ) : (
                    <small className="form-text text-muted font-weight-light x-small">
                      Password must be at least 8 characters. And must contain
                      letters, numbers, symbols (e.g. <i>@yusuf123</i>,{' '}
                      <i>Yusuf2022$</i>).
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="confirmPasswordEdit">
                  Confirm Password{' '}
                  {isChangePassword && (
                    <small className="x-small text-muted font-weight-light opacity-75">
                      (required)
                    </small>
                  )}
                </label>
                <div className="position-relative">
                  <input
                    type="password"
                    autoComplete="new-password"
                    className={`form-control form-control-lg ${
                      userFormErrors.confirmPassword &&
                      isChangePassword &&
                      'is-invalid'
                    }`}
                    name="confirmPassword"
                    id="confirmPasswordEdit"
                    style={{ paddingRight: '60px' }}
                    value={userFormValues.confirmPassword}
                    onChange={handleChange}
                    disabled={!isChangePassword}
                  />
                  <div className="show-password-icon">
                    <i
                      className="bi bi-eye"
                      id="cpw-eye-icon-edit"
                      style={{ cursor: 'pointer' }}
                      onClick={handleShowHidePassword}
                    ></i>
                  </div>
                  {userFormErrors.confirmPassword && (
                    <div className="invalid-feedback fw-light">
                      {userFormErrors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="pictureEdit">Update profile picture</label>
            <div className="custom-file form-control-lg">
              <input
                type="file"
                className="custom-file-input form-control-lg"
                name="picture"
                id="pictureEdit"
                accept="image/*"
                onChange={handleUploadPicture}
              />
              <label
                className="custom-file-label form-control-lg text-muted"
                htmlFor="pictureEdit"
              >
                Choose file
              </label>
            </div>
            <small className="form-text text-muted font-weight-light x-small">
              Recommended size is 200 x 200 pixels.
            </small>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="roleEdit">Role</label>
                <select
                  className="custom-select custom-select-lg"
                  id="roleEdit"
                  name="role"
                  value={userFormValues.role}
                  onChange={handleChange}
                >
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="statusEdit">Status</label>
                <select
                  className="custom-select custom-select-lg"
                  id="statusEdit"
                  name="status"
                  value={userFormValues.status}
                  onChange={handleChange}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div className="modal-footer modal-footer-align">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </div>
        </form>
      )}
    </>
  )
}

export default EditUserForm
