import React, { useState, useEffect, useRef } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'

// utils
import { togglePassword } from '../utils/index'

// config
import { reSiteKey } from '../config'

// actions
import { register } from '../actions/userActions'

const RegisterScreen = () => {
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const reRef = useRef()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo: userInfoLogin } = userLogin

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  const redirect = searchParams.get('redirect')
    ? searchParams.get('redirect')
    : '/'

  const onSubmit = async (e) => {
    e.preventDefault()

    const reToken = await reRef.current.executeAsync()
    reRef.current.reset()

    dispatch(
      register(username, email, password, confirmPassword, reToken, navigate)
    )
  }

  useEffect(() => {
    if (userInfo) {
      setUsername('')
      setEmail('')
      setPassword('')
      setConfirmPassword('')
    }
  }, [userInfo])

  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  useEffect(() => {
    if (userInfoLogin) navigate(`${redirect}`)
  }, [navigate, redirect, userInfoLogin])

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-end mb-5 pb-3">
            <div>
              <h1 className="h3 mb-1">Register</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-overlap container-overlap-lg container">
        <div className="row">
          <div className="col-lg-7 col-xl-6 mx-auto">
            <div className="card pt-0 shadow">
              <div className="card-body p-4">
                <form onSubmit={onSubmit} noValidate>
                  <ReCAPTCHA sitekey={reSiteKey} size="invisible" ref={reRef} />
                  <div className="form-group">
                    <label htmlFor="username">
                      Username{' '}
                      <small className="x-small text-muted font-weight-light opacity-75">
                        (required)
                      </small>
                    </label>
                    <input
                      type="text"
                      autoComplete="true"
                      className="form-control form-control-lg"
                      name="username"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <small className="form-text text-muted font-weight-light x-small">
                      A valid username should start with an alphabet, then other
                      characters can be alphabets, numbers or underscores. When
                      its length consists of 6 to 28 characters and it's case
                      insensitive (e.g. <i>hyusuf</i>, <i>yusuf22</i>,{' '}
                      <i>Yusuf_22</i>, <i>h_yusuf_22</i>).
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">
                      Email{' '}
                      <small className="x-small text-muted font-weight-light opacity-75">
                        (required)
                      </small>
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      name="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">
                      Password{' '}
                      <small className="x-small text-muted font-weight-light opacity-75">
                        (required)
                      </small>
                    </label>
                    <div className="position-relative">
                      <input
                        type="password"
                        autoComplete="new-password"
                        className="form-control form-control-lg"
                        name="password"
                        id="password"
                        style={{ paddingRight: '60px' }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="show-password-icon">
                        <i
                          className="bi bi-eye"
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowHidePassword}
                        ></i>
                      </div>
                    </div>
                    <small className="form-text text-muted font-weight-light x-small">
                      Password must be at least 8 characters. And must contain
                      letters, numbers, symbols (e.g. <i>@yusuf123</i>,{' '}
                      <i>Yusuf2022$</i>).
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmPassword">
                      Confirm Password{' '}
                      <small className="x-small text-muted font-weight-light opacity-75">
                        (required)
                      </small>
                    </label>
                    <div className="position-relative">
                      <input
                        type="password"
                        autoComplete="new-password"
                        className="form-control form-control-lg"
                        name="confirmPassword"
                        id="confirmPassword"
                        style={{ paddingRight: '60px' }}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div className="show-password-icon">
                        <i
                          className="bi bi-eye"
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowHidePassword}
                        ></i>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <button className="btn btn-lg btn-primary disabled px-4">
                      <span className="spinner-border spinner-form-button"></span>
                      Register
                    </button>
                  ) : (
                    <button className="btn btn-lg btn-outline-primary px-4">
                      Register
                    </button>
                  )}
                </form>
              </div>
            </div>
            <hr />
            <p>
              Already a customer?
              <Link to="/login" className="text-primary ml-1">
                Log In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterScreen
