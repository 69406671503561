const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
  <span
    {...props}
    className={
      'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
  ></span>
)

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
  <span
    {...props}
    className={
      'slick-next slick-arrow' +
      (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
  ></span>
)

export { SlickArrowLeft, SlickArrowRight }
