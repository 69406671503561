import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import HomeBanner from '../components/shared/HomeBanner'
import ProductFilterBar from '../components/product/ProductFilterBar'
import ProductList from '../components/product/ProductList'

import { productsPerPage } from '../config'
import { listProducts } from '../actions/productActions'
import Paginator from '../components/pagination/products/Paginator'

const HomeScreen = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const page = searchParams.get('page') || 1
  const sort = searchParams.get('sort') || '*'

  useEffect(() => {
    dispatch(listProducts(productsPerPage, page, sort, '', { page, sort }))
  }, [dispatch, page, sort])

  return (
    <>
      <HomeBanner />
      <ProductFilterBar pt={3} pb={3} />
      <ProductList />
      <Paginator />
    </>
  )
}

export default HomeScreen
