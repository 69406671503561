import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
// import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'

// utils
import { currencyFormatter } from '../utils/index'

// config
import { paypalClientId } from '../config'

// components
import Order from '../components/checkout/Order'
import Message from '../components/shared/Message'
import SkeletonOrder from '../components/shared/skeletons/SkeletonOrder'
import AlertCard from '../components/shared/AlertCard'

// actions
import {
  getOrderDetails,
  payOrder,
  deliverOrder
} from '../actions/orderActions'

// constants
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET
} from '../constants/orderConstants'

const OrderScreen = () => {
  const [isProcessPayment, setIsProcessPayment] = useState(true)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const toggleItemsDrawerHandler = (e) => {
    e.preventDefault()
    setIsDrawerOpen(!isDrawerOpen)
  }

  const successPaymentHandler = (paymentResult) => {
    setIsProcessPayment(false)
    dispatch(payOrder(id, paymentResult))
  }

  const markAsDeliveredHandler = () => {
    // console.log('mark as delivered')
    dispatch(deliverOrder(id))
  }

  useEffect(() => {
    if (!userInfo) {
      navigate('/login')
    } else if (!userInfo.isAdmin && order && userInfo._id !== order.user._id) {
      navigate('/')
    } else if (!order || order._id !== id || successPay || successDeliver) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      dispatch(getOrderDetails(id))
    }
  }, [dispatch, id, order, successPay, successDeliver, navigate, userInfo])

  if (!loading && error)
    return (
      <>
        <div className="band band-fat">
          <div className="mb-5 pb-3"></div>
        </div>
        <AlertCard
          message={error}
          icon="exclamation-circle"
          cardBorder={false}
          button={{ label: 'Go Back Home', action: '/' }}
        />
      </>
    )

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-5 pb-3">
            <div>
              <h1 className="h3 pr-lg-0 mb-1 pr-5">
                Order #{`${id.substring(0, 9).toUpperCase()}`}
                <span className="d-none opacity-25">...</span>
              </h1>
            </div>
            <a
              href="!#"
              className="btn btn-primary btn-no-transition disabled d-none px-3"
            >
              <i className="bi bi-printer-fill position-relative"></i>
              <span className="d-none d-md-inline-block ml-2">Receipt</span>
            </a>
          </div>
        </div>
      </div>
      {loading && <SkeletonOrder />}
      {!loading && order && (
        <div className="container-overlap space-1 space-md-2 container">
          <div className="row">
            <div className="col-lg-8">
              <div className="card p-4 shadow">
                <div>
                  <Order
                    shippingAddress={order.shippingAddress}
                    paymentMethod={order.paymentMethod}
                    user={order.user}
                    isDelivered={order.isDelivered}
                    isPaid={order.isPaid}
                    paidAt={order.paidAt}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card p-4 shadow">
                <div className="border-bottom mb-4 pb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2 className="h5 mb-0">Order summary</h2>
                    {order.orderItems && (
                      <Link
                        className="text-dark"
                        to="/"
                        onClick={toggleItemsDrawerHandler}
                      >
                        <i
                          className={`bi bi-chevron-${
                            isDrawerOpen ? 'up' : 'down'
                          } before-top-min-3`}
                        ></i>
                      </Link>
                    )}
                  </div>
                </div>

                {order.orderItems && (
                  <>
                    <div
                      className={`items-drawer ${!isDrawerOpen && 'd-none'}`}
                    >
                      {order.orderItems.map((item) => (
                        <div
                          className="border-bottom mb-4 pb-4"
                          key={item.product}
                        >
                          <div className="media">
                            <Link
                              to={`/product/${item.product}`}
                              className="avatar avatar-lg position-relative mr-3"
                            >
                              <img
                                className="img-fluid"
                                src={item.image}
                                width="112"
                                alt="..."
                              />
                              <sup className="avatar-status badge-primary">
                                {item.quantity}
                              </sup>
                            </Link>
                            <div className="media-body">
                              <h2 className="h6">
                                <Link
                                  to={`/product/${item.product}`}
                                  className="h6 d-block text-dark mb-0"
                                >
                                  {item.name}
                                </Link>
                              </h2>
                              <span className="d-block text-muted font-size-1 text-numeric">
                                {item.quantity} x{' '}
                                {currencyFormatter(item.price)} ={' '}
                                {currencyFormatter(item.price * item.quantity)}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className={`items-drawer mb-4 ${
                        isDrawerOpen && 'd-none'
                      }`}
                    >
                      {order.orderItems.map((item, index) => {
                        return (
                          index < 3 && (
                            <Link
                              to={`/product/${item.product}`}
                              className="avatar avatar-lg position-relative mr-3 mb-3"
                              key={item.product}
                            >
                              <img
                                className="img-fluid"
                                src={item.image}
                                width="112"
                                alt="..."
                              />
                              <sup className="avatar-status badge-primary">
                                {item.quantity}
                              </sup>
                            </Link>
                          )
                        )
                      })}
                      {order.orderItems.length > 3 && (
                        <Link
                          to="/"
                          className="ml-2 mb-3"
                          style={{ fontSize: '17px' }}
                          onClick={toggleItemsDrawerHandler}
                        >
                          +{order.orderItems.length - 3}
                        </Link>
                      )}
                    </div>
                  </>
                )}
                <div className="border-bottom mb-4 pb-4">
                  <div className="media align-items-center mb-3">
                    <span className="d-block font-size-1 mr-3">
                      Item subtotal ({order.itemsTotal})
                    </span>
                    <div className="media-body text-right">
                      <span className="text-dark font-weight-bold text-numeric">
                        {currencyFormatter(order.itemsPrice)}
                      </span>
                    </div>
                  </div>

                  <div className="media align-items-center mb-3">
                    <span className="d-block font-size-1 mr-3">
                      Delivery {false && '*'}
                    </span>
                    <div className="media-body text-right">
                      <span className="text-dark font-weight-bold">
                        {order.shippingPrice === 0
                          ? 'Free'
                          : currencyFormatter(order.shippingPrice)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="media align-items-center mb-3">
                  <span className="d-block font-size-1 mr-3">Tax</span>
                  <div className="media-body text-right">
                    <span className="text-dark font-weight-bold">
                      {currencyFormatter(order.taxPrice)}
                    </span>
                  </div>
                </div>

                <div className="media align-items-center mb-2--">
                  <h6 className="text-primary mr-3">Total</h6>
                  <div className="media-body text-right">
                    <h5 className="text-primary font-weight-bold text-numeric">
                      {currencyFormatter(order.totalPrice)}
                    </h5>
                  </div>
                </div>

                {(!isProcessPayment || order.isPaid) &&
                  userInfo &&
                  userInfo.isAdmin &&
                  !order.isDelivered && (
                    <div className="row mt-3">
                      <div className="col my-1 px-3">
                        <button
                          className="btn btn-primary btn-block btn-pill transition-3d-hover text-uppercase"
                          disabled={false}
                          onClick={markAsDeliveredHandler}
                        >
                          Mark as delivered
                        </button>
                      </div>
                    </div>
                  )}

                {isProcessPayment && !order.isPaid && (
                  <div className="mt-4">
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                      options={{
                        clientId: paypalClientId
                      }}
                      // https://developer.paypal.com/docs/checkout/standard/customize/buttons-style-guide/
                      style={{
                        layout: 'horizontal',
                        // color: 'silver',
                        // shape: 'pill',
                        // label: 'pay',
                        tagline: false,
                        height: 42
                      }}
                    />
                    {/* <PayPalScriptProvider
                      options={{
                        'client-id': paypalClientId
                      }}
                    >
                      <PayPalButtons
                        style={{
                          layout: 'horizontal',
                          tagline: false,
                          height: 42
                        }}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: order.totalPrice
                                }
                              }
                            ]
                          })
                        }}
                        onApprove={successPaymentHandler}
                      />
                    </PayPalScriptProvider> */}
                  </div>
                )}

                {false && (
                  <div
                    className="d-block small text-muted fw-light mt-3 pt-2"
                    style={{ borderTop: '1px dotted #ddd' }}
                  >
                    * Free delivery when you spend over $100
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OrderScreen
