import React, { useState, useEffect } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { togglePassword } from '../utils/index'

// actions
import { login } from '../actions/userActions'

const LoginScreen = () => {
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = searchParams.get('redirect')
    ? searchParams.get('redirect')
    : '/'

  const onSubmit = (e) => {
    e.preventDefault()

    dispatch(login(identifier, password))
  }

  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  useEffect(() => {
    if (userInfo) navigate(`${redirect}`)
  }, [navigate, redirect, userInfo])

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-end mb-5 pb-3">
            <div>
              <h1 className="h3 mb-1">Login</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-overlap container-overlap-lg container">
        <div className="row">
          <div className="col-lg-7 col-xl-6 position-relative mx-auto">
            <div className="card pt-0 shadow">
              <div className="card-body p-4">
                <form onSubmit={onSubmit} noValidate>
                  <div className="form-group">
                    <label htmlFor="identifier">Email / Username</label>
                    <input
                      type="text"
                      autoComplete="true"
                      className="form-control form-control-lg"
                      name="identifier"
                      id="identifier"
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="position-relative">
                      <input
                        type="password"
                        autoComplete="new-password"
                        className="form-control form-control-lg"
                        name="password"
                        id="password"
                        style={{ paddingRight: '60px' }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="show-password-icon">
                        <i
                          className="bi bi-eye"
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowHidePassword}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="remember"
                      />
                      <label
                        htmlFor="remember"
                        className="custom-control-label custom-checkbox-label"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  {loading ? (
                    <button className="btn btn-lg btn-primary disabled px-4">
                      <span className="spinner-border spinner-form-button"></span>
                      Login
                    </button>
                  ) : (
                    <button className="btn btn-lg btn-outline-primary px-4">
                      Login
                    </button>
                  )}
                  <Link
                    to="/forgot-password"
                    className="btn btn-no-transaction text-primary"
                  >
                    Forgot password?
                  </Link>
                </form>
              </div>
            </div>
            <hr />
            <p>
              New customer?
              <Link
                to={redirect ? `/register?redirect=${redirect}` : '/register'}
                className="text-primary ml-1"
              >
                Register now
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginScreen
