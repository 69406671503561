import React, { useEffect, useState, useCallback } from 'react'
import Rating from './Rating'

const RatingBreakdown = ({ product }) => {
  const { rating, reviews, numReviews } = product
  const [ratingByStart1, setRatingByStart1] = useState(0)
  const [ratingByStart2, setRatingByStart2] = useState(0)
  const [ratingByStart3, setRatingByStart3] = useState(0)
  const [ratingByStart4, setRatingByStart4] = useState(0)
  const [ratingByStart5, setRatingByStart5] = useState(0)

  const _ratingByStars = useCallback(
    (starCount) => {
      return reviews.reduce(
        (acc, review) => (review.rating === starCount ? acc + 1 : acc),
        0
      )
    },
    [reviews]
  )

  useEffect(() => {
    if (numReviews !== undefined && numReviews !== 0) {
      setRatingByStart1((_ratingByStars(1) / numReviews) * 100)
      setRatingByStart2((_ratingByStars(2) / numReviews) * 100)
      setRatingByStart3((_ratingByStars(3) / numReviews) * 100)
      setRatingByStart4((_ratingByStars(4) / numReviews) * 100)
      setRatingByStart5((_ratingByStars(5) / numReviews) * 100)
    } else {
      setRatingByStart1(0)
      setRatingByStart2(0)
      setRatingByStart3(0)
      setRatingByStart4(0)
      setRatingByStart5(0)
    }
  }, [numReviews, _ratingByStars])

  return (
    <div className="mb-lg-0 mb-5">
      <div className="border-bottom mb-4 pb-4">
        <div className="card card-no-border card-no-transition bg-secondary text-dark mb-3 p-4">
          <div className="d-flex justify-content-center align-items-center">
            <span className="display-4">{rating.toFixed(2)}</span>
            <div className="ml-3">
              <div className="small">
                <Rating rating={rating} color={'text-dark'} />
              </div>
              <span>
                <span className="font-weight-bold">{numReviews}</span>{' '}
                {numReviews === 1 ? 'review' : 'reviews'}
              </span>
            </div>
          </div>
        </div>

        <h3 className="h5">Rating breakdown</h3>
        <ul className="list-unstyled list-sm-article">
          <li>
            <span className="row align-items-center mx-n2 small text-primary">
              <div className="col-3 px-2">
                <span className="text-dark">5 stars</span>
              </div>
              <div className="col-7 px-2">
                <div className="progress" style={{ height: '4px' }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${ratingByStart5}%`
                    }}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-2 px-2 text-right">
                <span>{_ratingByStars(5)}</span>
              </div>
            </span>
          </li>
          <li>
            <span className="row align-items-center mx-n2 small text-primary">
              <div className="col-3 px-2">
                <span className="text-dark">4 stars</span>
              </div>
              <div className="col-7 px-2">
                <div className="progress" style={{ height: '4px' }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${ratingByStart4}%`
                    }}
                    aria-valuenow="53"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-2 px-2 text-right">
                <span>{_ratingByStars(4)}</span>
              </div>
            </span>
          </li>
          <li>
            <span className="row align-items-center mx-n2 small text-primary">
              <div className="col-3 px-2">
                <span className="text-dark">3 stars</span>
              </div>
              <div className="col-7 px-2">
                <div className="progress" style={{ height: '4px' }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${ratingByStart3}%`
                    }}
                    aria-valuenow="20"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-2 px-2 text-right">
                <span>{_ratingByStars(3)}</span>
              </div>
            </span>
          </li>
          <li>
            <span className="row align-items-center mx-n2 small text-primary">
              <div className="col-3 px-2">
                <span className="text-dark">2 stars</span>
              </div>
              <div className="col-7 px-2">
                <div className="progress" style={{ height: '4px' }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${ratingByStart2}%`
                    }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-2 px-2 text-right">
                <span>{_ratingByStars(2)}</span>
              </div>
            </span>
          </li>
          <li>
            <span className="row align-items-center mx-n2 small text-primary">
              <div className="col-3 px-2">
                <span className="text-dark">1 star</span>
              </div>
              <div className="col-7 px-2">
                <div className="progress" style={{ height: '4px' }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${ratingByStart1}%`
                    }}
                    aria-valuenow="1"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="col-2 px-2 text-right">
                <span>{_ratingByStars(1)}</span>
              </div>
            </span>
          </li>
        </ul>
      </div>

      <span className="d-block display-4 text-dark">
        {Math.round((rating / 5) * 100)}%
      </span>
      <p className="font-weight-light small">
        of customers recommend this product
      </p>
    </div>
  )
}

RatingBreakdown.defaultProps = {
  rating: 0,
  numReviews: 0
}

export default RatingBreakdown
