// scroll to top
function scrollToTop() {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}

// toggle password
function togglePassword(passwordField, eyeIcon) {
  if (passwordField.type === 'password') {
    passwordField.type = 'text'
    eyeIcon.classList.remove('bi-eye')
    eyeIcon.classList.add('bi-eye-slash')
  } else {
    passwordField.type = 'password'
    eyeIcon.classList.remove('bi-eye-slash')
    eyeIcon.classList.add('bi-eye')
  }
}

// paging
const paging = (c, m) => {
  let current = c,
    last = m,
    delta = 1, // 2
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

// currency formatter
const currencyFormatter = (value, options) => {
  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$'
  }

  if (typeof value !== 'number') value = 0.0
  options = { ...defaultOptions, ...options }
  value = value.toFixed(options.significantDigits)

  const [currency, decimal] = value.split('.')
  return `${options.symbol}${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`
}

// check empty object
function isEmptyObj(obj) {
  return Object.keys(obj).length === 0
}

// get query string
const getQueryString = (paramsObj, pathname) => {
  let params = new URLSearchParams()

  for (const [key, value] of Object.entries(paramsObj)) {
    params.set(key, value)
  }

  return params.toString() ? `${pathname}?${params.toString()}` : `${pathname}`
}

// update query string
const updateQueryString = (paramsObj, newParamsObj, pathname) => {
  const params = new URLSearchParams(paramsObj)

  for (const [key, value] of Object.entries(newParamsObj)) {
    params.set(key, value)

    if (value === '' || value === undefined) params.delete(key)
    if (key === 'page' && value === 1) params.delete(key)
    if (key === 'sort' && value === '*') params.delete(key)
  }

  return params.toString() ? `${pathname}?${params.toString()}` : `${pathname}`
}

// base 64
const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

/**
 * @desc {A valid username should start with an alphabet, then other characters can be alphabets, numbers or underscores. When its length consists of 6 to 28 characters and it's case insensitive (e.g. hyusuf, yusuf22, Yusuf_22, h_yusuf_22).
 * @param {String} username
 * @returns Boolean
 */
const validateUsername = (username) => {
  let regex = new RegExp('^[A-Za-z][A-Za-z0-9_]{5,27}$')
  return regex.test(username)
}

const validateEmail = (email) => {
  // let regex = new RegExp('^.+@[^@]+.[^@]{2,}$')
  // return regex.test(email)
  return email.match(/.+@[^@]+\.[^@]{2,}$/)
}

/**
 * @desc Password must be at least 8 characters. And must contain letters, numbers, symbols (e.g. @yusuf123, Yusuf2022$).
 * @param {String} password
 * @returns Boolean
 */
const validatePassword = (password) => {
  return password.match(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
  )
}

/**
 * @desc Generate random password
 * @returns Promise
 */
const generateRandomPassword = (len) => {
  const length = len ? len : 9
  const string = 'abcdefghijklmnopqrstuvwxyz' // to upper
  const numeric = '0123456789'
  // const punctuation = '!@#$%^&*()_+~`|}{[]:;?><,./-='
  const punctuation = '$!%*#'
  let password = ''
  let character = ''
  while (password.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random())
    const entity2 = Math.ceil(numeric.length * Math.random() * Math.random())
    const entity3 = Math.ceil(
      punctuation.length * Math.random() * Math.random()
    )
    let hold = string.charAt(entity1)
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold
    character += hold
    character += numeric.charAt(entity2)
    character += punctuation.charAt(entity3)
    password = character
  }
  password = password
    .split('')
    .sort(function () {
      return 0.5 - Math.random()
    })
    .join('')
  return password.substring(0, len)
}

const checkImgURL = (url) => {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null
}

export {
  scrollToTop,
  togglePassword,
  paging,
  currencyFormatter,
  isEmptyObj,
  getQueryString,
  updateQueryString,
  toBase64,
  validateUsername,
  validateEmail,
  validatePassword,
  generateRandomPassword,
  checkImgURL
}
