import React from 'react'

import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

import { productImgPlaceholder } from '../../../data-ui'

const SkeletonHomeBanner = () => {
  return (
    <div className="band band-fat">
      <div className="container" style={{ marginTop: '7px' }}>
        <div className="row align-items-lg-center text-lg-left text-center">
          <div className="col-lg-5 order-lg-2 mb-lg-0 mb-5 skeleton-wrapper">
            <div className="mb-5">
              <SkeletonElement type="banner-heading" />
              <div className="d-none d-none d-md-block">
                <SkeletonElement type="banner-text" />
                <SkeletonElement type="banner-text" />
                <SkeletonElement type="banner-text-short" />
              </div>
            </div>
            <SkeletonElement type="banner-button" />
            <SkeletonElement type="banner-avatar" />
            <Shimmer themeClass="shimmer-banner" />
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="w-75 mx-auto skeleton-wrapper">
              <img
                className="img-fluid img-bw--"
                src={productImgPlaceholder}
                style={{ opacity: '.6' }}
                alt="..."
              />
              <Shimmer themeClass="shimmer-banner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonHomeBanner
