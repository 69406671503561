import React from 'react'

import Pagination from './Pagination'

const Paginator = ({ ordersPerPage, totalPages, page, userId }) => {
  return (
    totalPages > 1 && (
      <div className="d-lg-flex justify-content-lg-center align-items-lg-center container">
        <div className="d-none d-lg-block mt-4">
          <Pagination
            ordersPerPage={ordersPerPage}
            totalPages={totalPages}
            page={page}
            userId={userId}
          />
        </div>
        <div className="d-none d-md-block d-lg-none mt-4">
          <Pagination
            ordersPerPage={ordersPerPage}
            totalPages={totalPages}
            page={page}
            userId={userId}
            theme={'dropdown'}
          />
        </div>
        <div className="d-block d-md-none mt-2">
          <Pagination
            ordersPerPage={ordersPerPage}
            totalPages={totalPages}
            page={page}
            userId={userId}
            theme={'prevnext'}
          />
        </div>
      </div>
    )
  )
}

export default Paginator
