import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// components
import ProductCarousel from './ProductCarousel'

// actions
import { likeProduct } from '../../actions/productActions'

// config
import { productImagePlaceholder } from '../../config'

const ProductImg = ({ product }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [likeCount, setLikeCount] = useState(0)
  const [isLiked, setIsLiked] = useState(false)

  const [isLikeSubmit, setIsLikeSubmit] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const productLike = useSelector((state) => state.productLike)
  const { product: likedProduct } = productLike

  const likeProductHandler = (e, productId) => {
    if (!userInfo) {
      navigate(`/login?redirect=${pathname}`)
    }

    e.preventDefault()
    dispatch(likeProduct(productId))
    setIsLikeSubmit(true)
    setIsLiked(!isLiked)
    setIsFirstLoad(false)
  }

  useEffect(() => {
    if (likedProduct && isLikeSubmit) {
      setLikeCount(likedProduct.numLikes)
      setIsLikeSubmit(false)
    }

    if (isFirstLoad) {
      setLikeCount(product?.numLikes)
      setIsLiked(product.likes?.includes(userInfo?._id))
    }
  }, [
    likedProduct,
    isLikeSubmit,
    product.numLikes,
    product.likes,
    userInfo?._id,
    isLiked,
    product,
    isFirstLoad
  ])

  return (
    <div className="position-relative">
      {product.images && product.images.length > 1 ? (
        <div className="mb-md-0 mb-5">
          <ProductCarousel images={product.images} />
        </div>
      ) : (
        <div className="mt-md-0 mt-4">
          <img
            src={
              (product.images && product.images[0]?.imageurl) ||
              productImagePlaceholder
            }
            className="w-75 d-block img-bw-- mx-auto"
            alt="..."
          />
        </div>
      )}
      <div className="like d-none" style={{ top: 0, right: 0 }}>
        <Link
          to="/"
          className={`bi ${
            isLiked ? 'bi-suit-heart-fill' : 'bi-suit-heart'
          } h4 font-weight-light text-primary`}
          onClick={(e) => likeProductHandler(e, product._id)}
        >
          <span>{likeCount}</span>
        </Link>
      </div>
    </div>
  )
}

export default ProductImg
