import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// utils
import { currencyFormatter } from '../../utils/index'
import { removeFromCartHandler } from '../../utils/cart'

// actions
import { addToCart, removeFromCart } from '../../actions/cartActions'

// components
import Rating from '../rating/Rating'

const CartItem = ({ item }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div className="media d-block d-md-flex">
      <div className="mr-md-3 mb-md-0 mb-4 text-center">
        <Link to={`/product/${item.product}`}>
          <img
            className="img-fluid cart-img"
            src={item.image}
            width="160"
            alt="..."
          />
        </Link>
      </div>
      <div className="media-body mt-1">
        <div className="row">
          <div className="col-md-6 mb-md-0 mb-3">
            <Link
              to={`/product/${item.product}`}
              className="h6 d-block text-dark mb-0"
            >
              {item.name}{' '}
            </Link>
            <div className="mb-3">
              <Rating rating={item.rating} size="small" />
            </div>
            <div className="d-block d-md-none">
              <span className="d-block text-dark font-weight-bold text-numeric mb-1">
                {currencyFormatter(item.price)}
              </span>
            </div>
            <div className="text-muted small mt-md-0 mb-1 mt-3">
              <span>
                {item.countInStock} {item.countInStock === 1 ? 'item' : 'items'}{' '}
                left
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row">
              <div className="col-auto">
                <select
                  className="custom-select custom-select-cartitem mb-md-2 mr-2"
                  value={item.quantity}
                  onChange={(e) => {
                    dispatch(addToCart(item.product, Number(e.target.value)))
                    navigate('/cart')
                  }}
                >
                  {[...Array(item.countInStock).keys()].map((i) => (
                    <option value={i + 1} key={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
              <div className="pl-md-3 col-auto pl-0">
                <Link
                  to="/cart"
                  className="d-block text-primary font-size-1 mt-md-0 mb-1 mt-2"
                >
                  <i className="bi bi-trash mr-1"></i>
                  <span
                    className="font-size-1 position-relative"
                    onClick={() =>
                      removeFromCartHandler(
                        item.product,
                        dispatch,
                        removeFromCart
                      )
                    }
                  >
                    Remove
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-none d-md-inline-block text-right">
            <span className="d-block text-dark font-weight-bold mb-1">
              {currencyFormatter(item.price)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartItem
