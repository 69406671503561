import React from 'react'

import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonUserList = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="table-responsive">
        <table className="table-striped- table-user mb-0 table">
          <thead>
            <tr>
              <th
                scope="col"
                className="small font-weight-bold pl-4"
                colSpan="2"
                style={{ minWidth: '140px' }}
              >
                USERNAME
              </th>
              <th scope="col" className="small font-weight-bold">
                <span className="d-none d-lg-block">FIRST NAME</span>
                <span className="d-lg-none">FNAME</span>
              </th>
              <th scope="col" className="small font-weight-bold">
                <span className="d-none d-lg-block">LAST NAME</span>
                <span className="d-lg-none">LNAME</span>
              </th>
              <th scope="col" className="small font-weight-bold">
                EMAIL
              </th>
              <th scope="col" className="small font-weight-bold">
                ACTIVE
              </th>
              <th scope="col" className="small font-weight-bold">
                ADMIN
              </th>
              <th scope="col" className="small font-weight-bold pr-4">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            {[...Array(5).keys()].map((elem, index) => (
              <tr
                key={index}
                className={`${index + 1 !== 5 && 'tr-border-bottom'}`}
              >
                <td
                  className="position-relative top-min-2 pt-0 pb-0 pl-4"
                  width={40}
                >
                  <SkeletonElement type="item-circle" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3 pr-4">
                  <SkeletonElement type="item" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Shimmer />
      </div>
    </div>
  )
}

export default SkeletonUserList
