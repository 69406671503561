import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { updateQueryString } from '../../utils/index'

// actions
import { listProducts } from '../../actions/productActions'

const ProductFilterBar = ({ pt, pb, searchFor }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const [sortBy, setSortBy] = useState('')

  const productList = useSelector((state) => state.productList)
  const {
    products,
    meta: {
      total_products: totalProducts,
      products_per_page: productsPerPage,
      from_page: fromPage,
      to_page: toPage
    },
    term,
    sort,
    filter
  } = productList

  const updateParams = (sort) => {
    const updatedParams = updateQueryString(
      filter,
      { ...filter, page: 1, sort },
      pathname
    )
    window.history.pushState(null, '', `${updatedParams}`)
  }

  const sortByHandler = (e) => {
    e.preventDefault()
    setSortBy(e.target.value)

    dispatch(
      listProducts(productsPerPage, 1, e.target.value, term, {
        ...filter,
        page: 1,
        sort: e.target.value
      })
    )
    updateParams(e.target.value)
  }

  useEffect(() => {
    setSortBy(sort)
  }, [sort])

  return (
    <div
      className="container"
      style={{ paddingTop: `${pt}rem`, paddingBottom: `${pb}rem` }}
    >
      <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
        <div className="mb-lg-0 fw-light mb-4">
          {products && products.length > 0 ? (
            <div>
              Showing {fromPage} to {toPage} of {totalProducts} product
              {totalProducts.length !== 1 && 's'} {searchFor && ' found'}
            </div>
          ) : (
            searchFor && (
              <div>
                Search for“<strong>{term}</strong>”
              </div>
            )
          )}
        </div>
        {products.length > 0 && (
          <div className="d-md-flex justify-content-lg-end align-items-center">
            <div>
              <select
                className="custom-select custom-select-medium"
                name="sortBy"
                value={sortBy}
                onChange={sortByHandler}
              >
                <option value="*">Sort by</option>
                <option value="newest-first">Newest first</option>
                <option value="price-high-low">Price (high - low)</option>
                <option value="price-low-high">Price (low - high)</option>
                <option value="top-sellers">Top sellers</option>
                <option value="a-z">A to Z</option>
                <option value="z-a">Z to A</option>
              </select>
            </div>
            {/* <div className="ml-2">
              <select className="custom-select custom-select-medium" defaultValue="a-to-z">
                <option value="a-z">A to Z</option>
                <option value="z-a">Z to A</option>
              </select>
            </div> */}
          </div>
        )}
      </div>
    </div>
  )
}

ProductFilterBar.defaultProps = {
  pt: 0,
  pb: 0,
  searchFor: false
}

export default ProductFilterBar
