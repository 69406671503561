import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// components
import Rating from '../rating/Rating'

// utils
import { currencyFormatter } from '../../utils/index'

// actions
import { addToCart } from '../../actions/cartActions'
import { likeProduct } from '../../actions/productActions'

// config
import { productImagePlaceholder } from '../../config'

const ProductCard = ({ product }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [likeCount, setLikeCount] = useState(product.numLikes || 0)
  const [isLiked, setIsLiked] = useState(
    product.likes.includes(userInfo?._id) || false
  )
  const [isLikeSubmit, setIsLikeSubmit] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const productLike = useSelector((state) => state.productLike)
  const { product: likedProduct } = productLike

  const addToCartHandler = (id) => {
    // navigate(`/cart/${id}?quantity=1}`)
    dispatch(addToCart(id, 1))
  }

  const likeProductHandler = (e, productId) => {
    if (!userInfo) {
      navigate('/login')
    }

    e.preventDefault()
    dispatch(likeProduct(productId))
    setIsLikeSubmit(true)
    setIsLiked(!isLiked)
  }

  useEffect(() => {
    if (likedProduct && isLikeSubmit) {
      setLikeCount(likedProduct.numLikes)
      setIsLikeSubmit(false)
    }
  }, [likedProduct, isLikeSubmit])

  return (
    <div className="col-item col-md-6 col-lg-4 col-xl-3">
      <div className="card card-list card-bordered h-100 position-relative text-md-center">
        {product.isNewArrival && product.countInStock !== 0 && (
          <div className="ribbon">
            <span className="new-arrival">NEW ARRIVAL</span>
          </div>
        )}
        {product.countInStock === 0 && (
          <>
            {/* <span className="card-cover"></span> */}
            <div className="ribbon">
              <span className="sold-out small">OUT OF STOCK</span>
            </div>
          </>
        )}
        <div
          className={`like ${
            product.countInStock === 0 || product.isNewArrival
              ? 'like-swap'
              : ''
          }`}
        >
          {userInfo ? (
            <Link
              to="/"
              className={`bi ${
                isLiked ? 'bi-suit-heart-fill' : 'bi-suit-heart'
              } text-primary`}
              onClick={(e) => likeProductHandler(e, product._id)}
            >
              <span>{likeCount}</span>
            </Link>
          ) : (
            <Link
              to="/"
              className={`bi bi-suit-heart text-primary`}
              onClick={(e) => likeProductHandler(e, product._id)}
            >
              <span>{likeCount}</span>
            </Link>
          )}
        </div>
        <Link to={`/product/${product._id}`}>
          <img
            className="card-img-top-- img-bw-- card-img mx-auto"
            src={product.images[0]?.imageurl || productImagePlaceholder}
            alt="..."
          />
        </Link>
        <div className="card-body p-4">
          <div className="mb-2">
            <span
              className="d-inline-block text-body small font-weight-bold mb-1"
              style={{ fontSize: '80%' }}
            >
              {product.category}
            </span>
            <span className="d-block mb-3">
              <Link
                to={`/product/${product._id}`}
                className="small font-weight-light text-inherit"
                style={{ fontSize: '90%' }}
              >
                {product.name}
              </Link>
            </span>
            <div className="d-block">
              <span className="text-numeric text-dark font-weight-bold">
                {currencyFormatter(product.price)}
              </span>
            </div>
          </div>
          <div className="mb-3">
            <Link
              to={`/product/${product._id}#reviews`}
              className="d-inline-flex align-items-center small text-dark"
            >
              <div className="text-warning mr-2 mt-1">
                <Rating rating={product.rating} />
              </div>
              <span className="text-muted fw-light mt-1">{`(${
                product.numReviews
              } ${product.numReviews === 1 ? 'review' : 'reviews'})`}</span>
            </Link>
          </div>
          <button
            to="/"
            className="btn btn-cart btn-outline-primary mt-md-3 text-lowercase"
            onClick={() => addToCartHandler(product._id)}
            disabled={product.countInStock === 0}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
