import React from 'react'

const Shimmer = ({ themeClass = 'shimmer' }) => {
  return (
    <div className="shimmer-wrapper">
      <div className={themeClass}></div>
    </div>
  )
}

export default Shimmer
