import React from 'react'

import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonProduct = ({ theme }) => {
  return (
    <div className={`skeleton-wrapper ${theme}`}>
      <div className="skeleton-article">
        <div className="card card-list pb-2">
          <div className="like like-swap d-none">
            <span className="bi bi-suit-heart-fill opacity-25"></span>
          </div>
          <SkeletonElement type="avatar" />
          <div className="card-body">
            <h5 className="card-title">
              <SkeletonElement type="title" />
            </h5>
            <div className="card-text">
              <SkeletonElement type="text" />
              <SkeletonElement type="text" />
            </div>
            <div className="footer mb-3">
              <SkeletonElement type="price" />
            </div>
            <div className="footer mb-3">
              <SkeletonElement type="rate" />
            </div>
            <SkeletonElement type="button" />
          </div>
        </div>
      </div>
      <Shimmer />
    </div>
  )
}

SkeletonProduct.defaultProps = {
  theme: 'transparent'
}

export default SkeletonProduct
