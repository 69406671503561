import React from 'react'

import Pagination from './Pagination'

const Paginator = ({ usersPerPage, totalPages, page, keyword }) => {
  return (
    totalPages > 1 && (
      <div className="d-lg-flex justify-content-lg-center align-items-lg-center container">
        <div className="d-none d-lg-block mt-4">
          <Pagination
            usersPerPage={usersPerPage}
            totalPages={totalPages}
            page={page}
            keyword={keyword}
          />
        </div>
        <div className="d-none d-md-block d-lg-none mt-4">
          <Pagination
            usersPerPage={usersPerPage}
            totalPages={totalPages}
            page={page}
            theme={'dropdown'}
            keyword={keyword}
          />
        </div>
        <div className="d-block d-md-none mt-2">
          <Pagination
            usersPerPage={usersPerPage}
            totalPages={totalPages}
            page={page}
            theme={'prevnext'}
            keyword={keyword}
          />
        </div>
      </div>
    )
  )
}

export default Paginator
