import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'

// components
import Rating from '../rating/Rating'

// utils
import { currencyFormatter } from '../../utils/index'

// actions
import { addToCart } from '../../actions/cartActions'
import { likeProduct } from '../../actions/productActions'

const ProductInfo = ({ product }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [likeCount, setLikeCount] = useState(0)
  const [isLiked, setIsLiked] = useState(false)

  const [isLikeSubmit, setIsLikeSubmit] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const productLike = useSelector((state) => state.productLike)
  const { product: likedProduct } = productLike

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [quantity, setQuantity] = useState(1)
  const { pathname } = useLocation()

  const { id } = useParams()

  const addToCartHandler = () => {
    dispatch(addToCart(id, Number(quantity)))
  }

  const likeProductHandler = (e, productId) => {
    if (!userInfo) {
      navigate(`/login?redirect=${pathname}`)
    }

    e.preventDefault()
    dispatch(likeProduct(productId))
    setIsLikeSubmit(true)
    setIsLiked(!isLiked)
    setIsFirstLoad(false)
  }

  useEffect(() => {
    $(document).on('click', 'a[href$="#reviews"]', function () {
      $('html,body').animate(
        { scrollTop: $('#reviews').offset().top - 120 },
        'slow'
      )
    })

    if (likedProduct && isLikeSubmit) {
      setLikeCount(likedProduct.numLikes)
      setIsLikeSubmit(false)
    }

    if (isFirstLoad) {
      setLikeCount(product?.numLikes)
      setIsLiked(product.likes?.includes(userInfo?._id))
    }
  }, [
    likedProduct,
    isLikeSubmit,
    product.numLikes,
    product.likes,
    userInfo?._id,
    isLiked,
    product,
    isFirstLoad
  ])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center small mb-3">
        <div>
          <div className="text-warning d-inline mr-2">
            <Rating rating={product.rating} />
          </div>
          <a
            className="link-underline position-relative font-weight-light reviews"
            href="#reviews"
          >
            Read all {product.numReviews}{' '}
            {product.numReviews === 1 ? 'review' : 'reviews'}
          </a>
        </div>
        <div className="like like-unstyle">
          <Link
            to="/"
            className={`bi ${
              isLiked ? 'bi-suit-heart-fill' : 'bi-suit-heart'
            } h4 font-weight-light text-primary`}
            onClick={(e) => likeProductHandler(e, product._id)}
          >
            <span>{likeCount}</span>
          </Link>
        </div>
      </div>
      <div className="mb-4 mt-4">
        <h1 className="h2">{product.name}</h1>
        <p className="text-muted font-weight-light">{product.description}</p>
      </div>
      <div className="mb-4">
        <span className="h6 text-muted font-weight-light mb-0 mr-2">
          Price:
        </span>
        <span className="font-weight-bold">
          {currencyFormatter(product.price)}
        </span>
      </div>
      <div className="mb-4">
        <span className="h6 text-muted font-weight-light mb-0 mr-2">
          Status:
        </span>
        <span className="font-weight-bold">
          {product.countInStock > 0 ? 'In Stock' : 'Out of Stock'}
        </span>
      </div>
      <div className="mb-4">
        <label
          className="h6 text-muted font-weight-light mb-2"
          htmlFor="quantity"
        >
          Quantity:
        </label>
        <select
          className="custom-select custom-select-lg mr-sm-2"
          id="quantity"
          value={quantity}
          disabled={product.countInStock === 0}
          onChange={(e) => setQuantity(e.target.value)}
        >
          {product.countInStock === 0 && <option value={0}>0</option>}
          {[...Array(product.countInStock).keys()].map((i) => (
            <option value={i + 1} key={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <button
          className="btn btn-lg btn-block btn-primary transition-3d-hover"
          onClick={product.countInStock > 0 ? addToCartHandler : undefined}
          disabled={product.countInStock === 0}
        >
          Add to Cart
        </button>
      </div>
    </>
  )
}

export default ProductInfo
