import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// utils
import { togglePassword } from '../../utils/index'

// actions
import { updateUserPassword } from '../../actions/userActions'

const UserPassword = ({ title }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userUpdatePassword = useSelector((state) => state.userUpdatePassword)
  const { loading, error, userInfo } = userUpdatePassword

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  const cancelHandler = (e) => {
    e.preventDefault()

    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')

    navigate('/account')
  }

  const onSubmit = (e) => {
    e.preventDefault()

    // DIPATCH UPDATE PASSWORD
    dispatch(
      updateUserPassword(
        { currentPassword, newPassword, confirmNewPassword },
        navigate
      )
    )
  }

  return (
    <form onSubmit={onSubmit} noValidate autoComplete="false">
      <input
        hidden
        type="text"
        id="username"
        name="username"
        autoComplete="username"
      />
      <div className="card card-no-border- pt-0 shadow">
        <div className="card-body px-4 py-4">
          <div className="d-flex justify-content-between align-items-end border-bottom mb-4 pb-3">
            <h6 className="card-header-title">{title}</h6>
          </div>

          <div className="form-group">
            <label htmlFor="currentPassword">
              Current Password{' '}
              <small className="x-small text-muted opacity-75 font-weight-light">
                (required)
              </small>
            </label>
            <div className="position-relative">
              <input
                type="password"
                autoComplete="new-password"
                className="form-control form-control-lg"
                name="currentPassword"
                id="currentPassword"
                style={{ paddingRight: '60px' }}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <div className="show-password-icon">
                <i
                  className="bi bi-eye"
                  style={{ cursor: 'pointer' }}
                  onClick={handleShowHidePassword}
                ></i>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">
              New Password{' '}
              <small className="x-small text-muted opacity-75 font-weight-light">
                (required)
              </small>
            </label>
            <div className="position-relative">
              <input
                type="password"
                autoComplete="new-password"
                className="form-control form-control-lg"
                name="newPassword"
                id="newPassword"
                style={{ paddingRight: '60px' }}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <div className="show-password-icon">
                <i
                  className="bi bi-eye"
                  style={{ cursor: 'pointer' }}
                  onClick={handleShowHidePassword}
                ></i>
              </div>
            </div>
            <small className="form-text text-muted font-weight-light x-small">
              Password must be at least 8 characters. And must contain letters,
              numbers, symbols (e.g. <i>@yusuf123</i>, <i>Yusuf2022$</i>).
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="confirmNewPassword">
              Confirm New Password{' '}
              <small className="x-small text-muted opacity-75 font-weight-light">
                (required)
              </small>
            </label>
            <div className="position-relative">
              <input
                type="password"
                autoComplete="new-password"
                className="form-control form-control-lg"
                name="confirmNewPassword"
                id="confirmNewPassword"
                style={{ paddingRight: '60px' }}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              <div className="show-password-icon">
                <i
                  className="bi bi-eye"
                  style={{ cursor: 'pointer' }}
                  onClick={handleShowHidePassword}
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer px-4 py-4">
          <div className="float-right">
            <button
              className="btn btn-secondary mr-2 px-4"
              onClick={cancelHandler}
            >
              Cancel
            </button>
            {loading ? (
              <button className="btn btn-primary disabled px-4">
                <span className="spinner-border spinner-form-button"></span>
                Update Password
              </button>
            ) : (
              <button className="btn btn-primary px-4">Update Password</button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}

export default UserPassword
