import React from 'react'
import Masonry from 'react-masonry-component'
import { useSelector } from 'react-redux'

import ProductCard from './ProductCard'
import Message from '../shared/Message'

import SkeletonProduct from '../shared/skeletons/SkeletonProduct'
import AlertCard from '../shared/AlertCard'

const ProductList = () => {
  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  if (loading)
    return (
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 col-lg-4 col-xl-3">
            <SkeletonProduct />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 d-none d-md-block">
            <SkeletonProduct />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 d-none d-lg-block">
            <SkeletonProduct />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 d-none d-xl-block">
            <SkeletonProduct />
          </div>
        </div>
      </div>
    )
  if (error) return <Message message={error} />
  if (products.length === 0)
    return (
      <AlertCard message="No items found" icon="cart-x" cardBorder={false} />
    )

  return (
    <>
      <div className="container">
        <Masonry className="row">
          {products.map((product) => {
            return <ProductCard product={product} key={product._id} />
          })}
        </Masonry>
      </div>
    </>
  )
}

export default ProductList
