import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Loader from '../components/shared/Loader'

// utils
import { togglePassword } from '../utils/index'

// actions
import { resetPasswordValid, resetPassword } from '../actions/userActions'

// components
import AlertCard from '../components/shared/AlertCard'

const ResetPasswordScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { token } = useParams()

  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const userResetPasswordValid = useSelector(
    (state) => state.userResetPasswordValid
  )
  const { loading, error, userInfo } = userResetPasswordValid

  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.parentElement.getElementsByTagName(
        'input'
      )[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  const onSubmit = (e) => {
    e.preventDefault()

    dispatch(resetPassword(token, newPassword, confirmNewPassword, navigate))
  }

  useEffect(() => {
    dispatch(resetPasswordValid(token))
  }, [dispatch, token])

  if (loading)
    return (
      <div className="py-5">
        <Loader />
      </div>
    )
  if (error)
    return (
      <>
        <div className="band band-fat">
          <div className="mb-5 pb-3"></div>
        </div>
        <AlertCard
          message={error}
          icon="exclamation-circle"
          cardBorder={false}
          button={{ label: 'Go Back Home', action: '/' }}
        />
      </>
    )

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-end mb-5 pb-3">
            <div>
              <h1 className="h3 mb-1">Reset Password</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-overlap container-overlap-lg container">
        <div className="row">
          <div className="col-lg-7 col-xl-6 mx-auto">
            <div className="card pt-0 shadow">
              <div className="card-body p-4">
                <form onSubmit={onSubmit} noValidate>
                  <input
                    hidden
                    type="text"
                    id="username"
                    name="username"
                    autoComplete="username"
                  />
                  <input
                    type="hidden"
                    autoComplete="token"
                    name="token"
                    value={token}
                  />
                  <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <div className="position-relative">
                      <input
                        type="password"
                        autoComplete="new-password"
                        className="form-control form-control-lg"
                        name="newPassword"
                        id="newPassword"
                        style={{ paddingRight: '60px' }}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <div className="show-password-icon">
                        <i
                          className="bi bi-eye"
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowHidePassword}
                        ></i>
                      </div>
                    </div>
                    <small className="form-text text-muted font-weight-light x-small">
                      Password must be at least 8 characters. And must contain
                      letters, numbers, symbols (e.g. <i>@yusuf123</i>,{' '}
                      <i>Yusuf2022$</i>).
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirmNewPassword">
                      Confirm New Password
                    </label>
                    <div className="position-relative">
                      <input
                        type="password"
                        autoComplete="new-password"
                        className="form-control form-control-lg"
                        name="confirmNewPassword"
                        id="confirmNewPassword"
                        style={{ paddingRight: '60px' }}
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                      />
                      <div className="show-password-icon">
                        <i
                          className="bi bi-eye"
                          style={{ cursor: 'pointer' }}
                          onClick={handleShowHidePassword}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-outline-primary px-4"
                  >
                    Update Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPasswordScreen
