export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
export const reSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
export const productsPerPage = process.env.REACT_APP_PRODUCTS_PER_PAGE || 0
export const myOrdersPerPage = process.env.REACT_APP_MY_ORDERS_PER_PAGE || 0
export const userListPerPage = process.env.REACT_APP_USER_LIST_PER_PAGE || 0
export const productListPerPage =
  process.env.REACT_APP_PRODUCT_LIST_PER_PAGE || 0
export const orderListPerPage = process.env.REACT_APP_ORDER_LIST_PER_PAGE || 0
export const myWishlistPerPage =
  process.env.REACT_APP_MY_MY_WISHLIST_PER_PAGE || 0
export const myReviewsPerPage =
  process.env.REACT_APP_MY_MY_REVIEWS_PER_PAGE || 0
export const shownReviews = process.env.REACT_APP_SHOWN_REVIEWS || 5
export const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID
export const productImagePlaceholder =
  process.env.REACT_APP_PRODUCT_IMAGE_PLACEHOLDER
