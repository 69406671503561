import React from 'react'
import { Link } from 'react-router-dom'

const OrderCompletedScreen = () => {
  return (
    <div className="container mb-4 pt-5">
      <div className="w-md-80 w-lg-50 mx-md-auto text-center">
        <figure className="mx-auto mb-3">
          <i className="bi bi-check-circle display-4 opacity-25-- text-success"></i>
        </figure>
        <div className="mb-5">
          <h1 className="h2">Your order is completed!</h1>
          <p className="font-weight-light">
            Thank you for your order! Your order is being processed and will be
            completed within 3-6 hours. You will receive an email confirmation
            when your order is completed.
          </p>
        </div>
        <Link className="btn btn-primary text-uppercase px-5" to="/">
          Continue Shopping
        </Link>
      </div>
    </div>
  )
}

export default OrderCompletedScreen
