import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Slider from 'react-slick'

import { SlickArrowLeft, SlickArrowRight } from '../slick/SlickArrows'

// actions
import { listFeaturedProducts } from '../../actions/productActions'

// components
import SkeletonHomeBanner from '../../components/shared/skeletons/SkeletonHomeBanner'
import SingleBanner from './SingleBanner'

const HomeBanner = () => {
  // const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

  const featuredProductList = useSelector((state) => state.featuredProductList)
  const { products, loading, error } = featuredProductList

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    fade: false,
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: true,
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false
        }
      }
    ]
  }

  useEffect(() => {
    dispatch(listFeaturedProducts())
  }, [dispatch])

  if (loading) return <SkeletonHomeBanner />

  return (
    <>
      {!loading && products && products.length > 0 && (
        <div className="band">
          <Slider {...settings}>
            {products.map((product) => (
              <SingleBanner product={product} key={product._id} />
            ))}
          </Slider>
        </div>
      )}
    </>
  )
}

export default HomeBanner
