import React, { useEffect, useRef, useState } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

// components
import Message from '../shared/Message'
import SkeletonMyReviews from '../shared/skeletons/SkeletonMyReviews'

// actions
import { myReviewedProducts, removeReview } from '../../actions/productActions'

// config
import { myReviewsPerPage, productImagePlaceholder } from '../../config'

// utils
import Paginator from '../pagination/myreviews/Paginator'
import Rating from '../rating/Rating'
import RemoveModal from '../shared/modal/RemoveModal'
import ModalMessage from '../shared/ModalMessage'
import EditModal from '../shared/modal/EditModal'
import EditReviewForm from '../form/EditReviewForm'

const UserReviews = () => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [isRemoved, setIsRemoved] = useState('')
  const [selectedReviewTitle, setSelectedReviewTitle] = useState('')
  const [selectedProductId, setSelectedProductId] = useState('')
  const [isInitialRender, setIsInitialRender] = useState(false)
  const [random, setRandom] = useState(Math.random())

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productMyReviews = useSelector((state) => state.productMyReviews)
  const { items, loading, error } = productMyReviews

  const page = Number(searchParams.get('page')) || 1

  const handleRemoveReview = (e) => {
    e.preventDefault()

    document.querySelector('.close').click()

    // TODO: remove review
    dispatch(removeReview(selectedProductId))
    setIsRemoved(Math.random())
  }

  useEffect(() => {
    dispatch(myReviewedProducts(myReviewsPerPage, page))
  }, [dispatch, page, isRemoved])

  return (
    <>
      <div className="card pt-0 shadow">
        <div className="card-body px-0 py-4">
          <div className="d-flex justify-content-between align-items-center border-bottom mb-0 pb-3">
            <h6 className="card-header-title ml-4 mb-1">{'My Reviews'}</h6>
            <div className="fw-light top-min-2 position-relative mr-4 mb-1">
              {items && items.data && items.data.length > 0 && (
                <div className="small-- fw-light">
                  <span className="d-none d-md-inline-block">Showing</span>{' '}
                  {items.meta.from_page} to {items.meta.to_page} of{' '}
                  {items.meta.total_products}{' '}
                  {items.meta.total_products === 1 ? 'product' : 'products'}
                </div>
              )}
            </div>
          </div>
          <div>
            {loading && <SkeletonMyReviews />}
            {!loading && error && <Message message={error} />}
            {!loading && items && items.data && items.data.length === 0 && (
              <Message message={'No reviews found'} />
            )}
            {!loading && items && items.data && items.data.length > 0 && (
              <>
                <div className="table-responsive">
                  <table className="table-striped- table-user mb-0 table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="small font-weight-bold pl-4"
                          colSpan="2"
                          style={{ minWidth: '260px' }}
                        >
                          ITEM
                        </th>
                        <th
                          scope="col"
                          className="small font-weight-bold d-none d-xl-block"
                        >
                          REVIEW
                        </th>
                        <th
                          scope="col"
                          className="small font-weight-bold"
                          style={{ width: 120 }}
                        >
                          RATING
                        </th>
                        <th
                          scope="col"
                          className="small font-weight-bold d-none d-xl-block"
                        >
                          DATE
                        </th>
                        <th scope="col" className="small font-weight-bold pr-4">
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.data.map((product, index) => {
                        // get the review
                        const review = product.reviews.find(
                          (review) => review.user === userInfo._id
                        )
                        // console.log('my review:', review)
                        return (
                          <tr
                            key={product._id}
                            className={`${
                              (items.data.length !== index + 1 || true) &&
                              'tr-border-bottom'
                            }`}
                          >
                            <td
                              className="position-relative top-min-2 pt-2 pl-3 pb-0"
                              width="50"
                            >
                              <Link to={`/product/${product._id}`}>
                                <img
                                  alt={product.name}
                                  src={
                                    (product.images &&
                                      product.images[0]?.imageurl) ||
                                    productImagePlaceholder
                                  }
                                  className="rounded-circle"
                                  width="50"
                                />
                              </Link>
                            </td>
                            <td className="pt-3 pl-0 pb-3">
                              <Link
                                to={`/product/${product._id}`}
                                className="text-black"
                              >
                                {product.name}
                              </Link>
                            </td>
                            <td className="d-none d-xl-block pt-3 pb-3">
                              {review?.title}
                            </td>
                            <td className="pt-3 pb-3">
                              <Rating rating={review?.rating || 0} />
                            </td>
                            <td className="d-none d-xl-block pt-3 pb-3">
                              {moment(review?.createdAt).format('L')}
                            </td>
                            <td className="pt-3 pb-3 pr-4">
                              <Link
                                to="."
                                className="fw-light link-underline mr-2"
                                data-toggle="modal"
                                data-target="#editItem"
                                title="edit review"
                                onClick={() => {
                                  setSelectedProductId(product._id)
                                  setSelectedReviewTitle(review.title)
                                  setIsInitialRender(true)
                                }}
                              >
                                Edit
                              </Link>
                              <Link
                                to="."
                                className="fw-light link-underline"
                                title="remove review"
                                data-toggle="modal"
                                data-target="#removeItem"
                                onClick={() => {
                                  setSelectedProductId(product._id)
                                  setSelectedReviewTitle(review.title)
                                }}
                              >
                                Remove
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="float-lg-right">
                  <Paginator
                    itemsPerPage={items.meta.products_per_page}
                    totalPages={items.meta.total_pages}
                    page={items.meta.page}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <RemoveModal
        title="Remove Review"
        message={
          <ModalMessage type="removeReview" context={selectedReviewTitle} />
        }
        action={handleRemoveReview}
      />
      <EditModal
        title="Edit Review"
        size="large"
        content={
          <EditReviewForm
            productId={selectedProductId}
            isInitialRender={isInitialRender}
            setIsInitialRender={setIsInitialRender}
            forwardScreen="reviews"
          />
        }
        action={() => {}}
      />
    </>
  )
}

export default UserReviews
