import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// utils
import { currencyFormatter } from '../utils/index'
import { cartCalculations } from '../utils/cart'

// components
import CartOrderSummary from '../components/cart/CartOrderSummary'
import PlaceOrder from '../components/checkout/PlaceOrder'
import Steps from '../components/checkout/Steps'

// actions
import { createOrder } from '../actions/orderActions'
import { emptyCart, resetCart } from '../actions/cartActions'

const PlaceOrderScreen = () => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const { cartItems, shippingAddress } = cart

  // calculations
  cartCalculations(cart, cartItems, shippingAddress)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, error } = orderCreate

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        user: userInfo._id,
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsTotal: cart.itemSubtotal,
        itemsPrice: cart.itemTotalPrice,
        taxPrice: cart.taxPrice,
        shippingPrice: cart.shippingPrice,
        totalPrice: cart.orderTotalPrice
      })
    )

    // navigate('/order-completed')
  }

  useEffect(() => {
    if (!cartItems.length) navigate('/')

    if (success) {
      // empty the cart
      dispatch(emptyCart())

      // rest the cart
      // dispatch(resetCart())

      // navigate
      navigate(`/orders/${order._id}`)
    }
    // eslint-disable-next-line
  }, [cartItems, navigate, success])

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-top mb-5 pb-3">
            <div>
              <h1 className="h3 pr-lg-0 mb-1 pr-5">Place Order</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-overlap space-1 space-md-2 container">
        <div className="row">
          <div className="col-lg-8">
            <div className="card p-4 shadow">
              <Steps step={4} />
              <div className="mt-4">
                <PlaceOrder action={placeOrderHandler} />
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <CartOrderSummary
              title={'Order summary'}
              itemSubtotal={cart.itemSubtotal}
              itemTotalPrice={currencyFormatter(cart.itemTotalPrice)}
              deliveryFee={
                cart.shippingPrice === 0
                  ? 'Free'
                  : currencyFormatter(cart.shippingPrice)
              }
              taxLabel={'Tax'}
              tax={currencyFormatter(cart.taxPrice)}
              orderTotalLabel={'Total'}
              orderTotalPrice={currencyFormatter(cart.orderTotalPrice)}
              cartItems={cartItems}
              actionLabel={'Place Order'}
              action={placeOrderHandler}
              actionDisable={false}
              isItemsShown={true}
              isDeliveryNote={true}
            />
          </div>
          {/* Order Summary */}
        </div>
      </div>
    </>
  )
}

export default PlaceOrderScreen
