import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import ScrollToTop from './components/shared/ScrollToTop'
import ProductGallery from './components/product/ProductGallery'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import ResetPasswordScreen from './screens/ResetPasswordScreen'
import AccountScreen from './screens/AccountScreen'
import SearchScreen from './screens/SearchScreen'
import ConfirmRegistrationScreen from './screens/ConfirmRegistrationScreen'
import SignInScreen from './screens/SignInScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderCompletedScreen from './screens/OrderCompletedScreen'
import OrderScreen from './screens/OrderScreen'
import CartFooter from './components/cart/CartFooter'
import UserListScreen from './screens/UserListScreen'
import ProductListScreen from './screens/ProductListScreen'
import OrderListScreen from './screens/OrderListScreen'
import UserDetailsScreen from './screens/UserDetailsScreen'
import ProductDetailsScreen from './screens/ProductDetailsScreen'
import SettingsScreen from './screens/SettingsScreen'

const App = () => {
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const getItemSubtotal = (cartItems) =>
    cartItems.reduce((acc, item) => acc + item.quantity, 0)

  const getItemTotalPrice = (cartItems) =>
    cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0)

  return (
    <Router>
      <div id="cover" className="d-none"></div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        theme="colored"
        transition={Zoom}
        closeButton={true}
        style={{ fontWeight: '300' }}
      />
      <ScrollToTop />
      <div className="container-vertical">
        <div className="container-vertical-content">
          <Header />
          {cartItems.length > 0 && (
            <CartFooter
              items={getItemSubtotal(cartItems)}
              price={getItemTotalPrice(cartItems)}
            />
          )}
          <main>
            <Routes>
              <Route exact path="/" element={<HomeScreen />} />
              <Route path="/product/:id" element={<ProductScreen />} />
              <Route path="/cart" element={<CartScreen />} />
              <Route path="/cart/:id" element={<CartScreen />} />

              <Route exact path="/search/" element={<SearchScreen />} />
              <Route exact path="/search/:term" element={<SearchScreen />} />

              <Route path="/login" element={<LoginScreen />} />
              <Route path="/register" element={<RegisterScreen />} />
              <Route
                path="/forgot-password"
                element={<ForgotPasswordScreen />}
              />
              <Route
                path="/reset-password/:token"
                element={<ResetPasswordScreen />}
              />
              <Route
                path="/confirm-registration/:token"
                element={<ConfirmRegistrationScreen />}
              />

              <Route path="/account" element={<AccountScreen />} />
              <Route path="/account/:slug" element={<AccountScreen />} />

              <Route path="/product/gallery" element={<ProductGallery />} />

              <Route path="/signin" element={<SignInScreen />} />
              <Route path="/shipping" element={<ShippingScreen />} />
              <Route path="/payment" element={<PaymentScreen />} />
              <Route path="/place-order" element={<PlaceOrderScreen />} />
              <Route
                path="/order-completed"
                element={<OrderCompletedScreen />}
              />
              <Route path="/orders/:id" element={<OrderScreen />} />
              <Route path="/users" element={<UserListScreen />} />
              <Route path="/users/:id" element={<UserDetailsScreen />} />
              <Route path="/products" element={<ProductListScreen />} />
              <Route path="/products/:id" element={<ProductDetailsScreen />} />
              <Route path="/settings" element={<SettingsScreen />} />
              <Route path="/orders" element={<OrderListScreen />} />
            </Routes>
          </main>
        </div>
      </div>
      <Footer />
    </Router>
  )
}

export default App
