import React from 'react'

import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonOrder = () => {
  return (
    <div className="container-overlap space-1 space-md-2 container">
      <div className="row">
        <div className="col-lg-8">
          <div className="card p-4 shadow">
            <div className="skeleton-wrapper">
              <div className="border-bottom mb-4 pb-4">
                <div className="mb-4">
                  <SkeletonElement type="order-heading" />
                </div>
                <SkeletonElement type="order-text" />
                <SkeletonElement type="order-text" />
                <SkeletonElement type="order-mark" />
              </div>
              <div>
                <div className="mb-4">
                  <SkeletonElement type="order-heading" />
                </div>
                <SkeletonElement type="order-text" />
                <SkeletonElement type="order-mark" />
              </div>
              <Shimmer />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card p-4 shadow">
            <div className="skeleton-wrapper">
              <div className="border-bottom mb-4 pb-4">
                <SkeletonElement type="order-heading" />
              </div>
              {[''].map((elem, index) => (
                <div className="border-bottom mb-4 pb-4" key={index}>
                  <div className="media">
                    <div className="avatar avatar-lg position-relative mr-3">
                      <SkeletonElement type="order-img" />
                      <sup className="avatar-status badge-dark opacity-10"></sup>
                    </div>
                    <div className="media-body">
                      <SkeletonElement type="order-item-title" />
                      <SkeletonElement type="order-item-price" />
                    </div>
                  </div>
                </div>
              ))}
              <div className="border-bottom mb-4 pb-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <SkeletonElement type="order-item-title" />
                  <SkeletonElement type="order-price" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <SkeletonElement type="order-item-title" />
                  <SkeletonElement type="order-price" />
                </div>
              </div>
              {/* <div className="d-flex justify-content-between align-items-center mb-3">
              <SkeletonElement type="order-item-title" />
              <SkeletonElement type="order-price" />
            </div> */}
              <div className="d-flex justify-content-between align-items-center mb-3">
                <SkeletonElement type="order-item-title-lg" />
                <SkeletonElement type="order-price-lg" />
              </div>
              <div className="row">
                <div className="col my-1 px-3">
                  <SkeletonElement type="order-button" />
                </div>
              </div>
              <Shimmer />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonOrder
