import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { forgotUserPassword } from '../actions/userActions'

const ForgotPasswordScreen = () => {
  const dispactch = useDispatch()
  const navigate = useNavigate()

  const [identifier, setIdentifier] = useState('')

  const userForgotPassword = useSelector((state) => state.userForgotPassword)
  const { loading, error, userInfo } = userForgotPassword

  const onSubmit = (e) => {
    e.preventDefault()

    setIdentifier('')

    dispactch(forgotUserPassword(identifier, navigate))
  }

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-end pb-3 mb-5">
            <div>
              <h1 className="h3 mb-1">Forgot Password</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container container-overlap container-overlap-lg">
        <div className="row">
          <div className="col-lg-7 col-xl-6 mx-auto">
            <div className="card shadow pt-0">
              <div className="card-body p-4">
                <form onSubmit={onSubmit} noValidate>
                  <div className="form-group">
                    <label htmlFor="identifier">Email / Username</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      name="identifier"
                      id="identifier"
                      value={identifier}
                      onChange={(e) => setIdentifier(e.target.value)}
                    />
                  </div>
                  {loading ? (
                    <button className="btn btn-lg px-4 btn-outline-primary disabled">
                      <span className="spinner-border spinner-form-button"></span>
                      Reset Password
                    </button>
                  ) : (
                    <button className="btn btn-lg px-4 btn-outline-primary">
                      Reset Password
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordScreen
