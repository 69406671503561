import React from 'react'

const ProductGallery = () => {
  return (
    <div className="container">
      <h1>72 Images</h1>
      <div>
        {[...Array(72)].map((_, i) => {
          const j = ++i
          const img = j < 10 ? `0${j}.jpg` : `${j}.jpg`

          return (
            <>
              <img
                src={`https://cartzilla.createx.studio/img/shop/catalog/${img}`}
                alt="..."
              />
              <br />
            </>
          )
        })}
      </div>
      <hr />
      <h1>8 Single Images</h1>
      <div>
        {[...Array(8)].map((_, i) => {
          const j = ++i
          const img = `0${j}.jpg`

          return (
            <>
              <img
                src={`https://cartzilla.createx.studio/img/shop/single/gallery/${img}`}
                alt="..."
              />
              <br />
            </>
          )
        })}
      </div>
      <hr />
      <h1>8 Thumbnails</h1>
      <div>
        {[...Array(8)].map((_, i) => {
          const j = ++i
          const img = `th0${j}.jpg`

          return (
            <>
              <img
                src={`https://cartzilla.createx.studio/img/shop/single/gallery/${img}`}
                alt="..."
              />
              <br />
            </>
          )
        })}
      </div>
    </div>
  )
}

export default ProductGallery
