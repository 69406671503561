import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// components
import ReviewModal from '../../components/shared/modal/ٍReviewModal'
import AddReviewForm from '../../components/form/AddReviewForm'

// config
import { shownReviews } from '../../config'

const ReviewForm = ({
  numReviews,
  readMore,
  isShown,
  setIsShown,
  setReadMore,
  productId,
  reviews
}) => {
  const [isReviewed, setIsReviewed] = useState(false)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const handleReadMore = () => {
    isShown ? setReadMore('Read More') : setReadMore('Show Less')
    setIsShown(!isShown)
  }

  const _isReviewedByMe = useCallback(
    (userId) => reviews.find((review) => review.user === userId),
    [reviews]
  )

  useEffect(() => {
    _isReviewedByMe(userInfo?._id) ? setIsReviewed(true) : setIsReviewed(false)
  }, [_isReviewedByMe, userInfo?._id])

  return (
    <>
      <div className="d-flex justify-content-end">
        {numReviews > shownReviews && (
          <Link
            to="."
            className={`btn btn-secondary w-100 w-sm-auto px-sm-5 mb-2 px-2 py-2 ${
              !isReviewed && userInfo && 'mr-2'
            }`}
            onClick={handleReadMore}
          >
            {readMore}
            <i className="bi bi-chevron-right position-relative ml-1"></i>
          </Link>
        )}
        {!isReviewed && userInfo && (
          <button
            type="button"
            className="btn btn-primary w-100 w-sm-auto transition-3d-hover px-sm-5 mb-2 px-2 py-2"
            data-toggle="modal"
            data-target="#review"
          >
            Write a Review
          </button>
        )}
      </div>
      <ReviewModal
        title="Add Review"
        content={<AddReviewForm productId={productId} />}
        size="large"
        action={() => {}}
      />
    </>
  )
}

export default ReviewForm
