import React, { useEffect, useState, useRef } from 'react'
import {
  Link,
  useSearchParams,
  useNavigate,
  useLocation
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// actions
import { listUsers, removeUser } from '../actions/userActions'

// utils
import { updateQueryString } from '../utils/index'

// config
import { userListPerPage } from '../config'

// components
import Message from '../components/shared/Message'
import SkeletonUserList from '../components/shared/skeletons/SkeletonUserList'
import Paginator from '../components/pagination/users/Paginator'
import AlertCard from '../components/shared/AlertCard'
import RemoveModal from '../components/shared/modal/RemoveModal'
import AddModal from '../components/shared/modal/AddModal'
import EditModal from '../components/shared/modal/EditModal'
import AddUserForm from '../components/form/AddUserForm'
import EditUserForm from '../components/form/EditUserForm'
import ModalMessage from '../components/shared/ModalMessage'

const UserListScreen = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const searchIcon = useRef()
  const searchSearch = useRef()

  // const [isRemoved, setIsRemoved] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [isChangePassword, setIsChangePassword] = useState(false)
  const [keyword, setKeyword] = useState(searchParams.get('term') || '')

  const userList = useSelector((state) => state.userList)
  const { users, loading, error } = userList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userRemove = useSelector((state) => state.userRemove)
  const { success: successRemove } = userRemove

  const page = Number(searchParams.get('page')) || 1

  const handleRemove = (e) => {
    e.preventDefault()
    document.querySelector('.close').click()
    // TODO: remove user
    dispatch(removeUser(selectedUser._id))
    // setIsRemoved(true)
    navigate('/users')
  }

  // console.log('users:', users)

  const updateParams = (term) => {
    const updatedParams = updateQueryString({}, { term }, pathname)
    window.history.pushState(null, '', `${updatedParams}`)
  }

  const searchHandler = (e) => {
    e.preventDefault()
    updateParams(e.target.value)
    setKeyword(e.target.value)
    if (e.target.value) {
      searchIcon.current.classList.add('bi-search-clear')
    } else {
      searchIcon.current.classList.remove('bi-search-clear')
    }
  }

  const clearSearchHandler = (e) => {
    e.preventDefault()
    if (keyword) {
      searchIcon.current.classList.remove('bi-search-clear')
      setKeyword('')
      updateParams('')
      searchSearch.current.focus()
    }
  }

  useEffect(() => {
    // if (userInfo && userInfo.isAdmin || isRemoved) {
    if ((userInfo && userInfo.isAdmin) || successRemove) {
      dispatch(listUsers(userListPerPage, page, keyword))
      // setIsRemoved(false)
    } else {
      navigate('/')
    }
  }, [dispatch, page, userInfo, navigate, successRemove, keyword])

  // console.log('users:', users)

  if (error)
    return (
      <>
        <div className="band band-fat">
          <div className="mb-5 pb-3"></div>
        </div>
        <AlertCard
          message={error}
          icon="exclamation-circle"
          cardBorder={false}
          button={{ label: 'Go Back Home', action: '/' }}
        />
      </>
    )

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-5 pb-3">
            <h1 className="h3 mb-1">
              <i className="bi bi-people before-top-min-1-- mr-2"></i>Users
            </h1>
            <Link
              to="/users"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#addItem"
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              + <span className="d-none d-md-inline-block mr-1">Add</span>User
            </Link>
          </div>
        </div>
      </div>
      <div className="container-overlap container">
        <div className="row">
          <div className="col">
            <div className="card card-no-border- pt-0 shadow">
              <div className="card-body px-0 py-4">
                <div className="d-md-flex justify-content-md-between align-items-md-center border-bottom-- mb-0 pb-3">
                  <h6 className="card-header-title mb-md-2 ml-4 mb-3">
                    Users List
                  </h6>
                  <div className="fw-light top-min-2 position-relative mr-4 ml-4 mb-1">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        name="term"
                        id="term"
                        value={keyword}
                        onChange={searchHandler}
                        style={{
                          paddingLeft: '12px',
                          paddingRight: '40px'
                        }}
                        ref={searchSearch}
                      />
                      <div className="search-input-icon">
                        <i
                          className="bi bi-search text-black-50"
                          style={{ fontSize: '1.1rem', marginRight: '-6px' }}
                          ref={searchIcon}
                          onClick={clearSearchHandler}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center border-bottom mb-0 pl-4 pb-3">
                  <div className="fw-light top-min-2 position-relative mr-4 mb-1">
                    {users && users.data && users.data.length > 0 && (
                      <div className="small-- fw-light">
                        <span className="d-none-- d-md-inline-block">
                          Showing
                        </span>{' '}
                        {users.meta.from_page} to {users.meta.to_page} of{' '}
                        {users.meta.total_users} users
                      </div>
                    )}
                  </div>
                </div>
                {loading && <SkeletonUserList />}
                {!loading && users && users.data && users.data.length === 0 && (
                  <Message message="No users found" />
                )}
                {!loading && users && users.data && users.data.length > 0 && (
                  <>
                    <div className="table-responsive">
                      <table className="table-user mb-0 table">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="small font-weight-bold pl-4"
                            >
                              USERNAME
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              <span className="d-none d-lg-block">
                                FIRST NAME
                              </span>
                              <span className="d-lg-none">FNAME</span>
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              <span className="d-none d-lg-block">
                                LAST NAME
                              </span>
                              <span className="d-lg-none">LNAME</span>
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              EMAIL
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              ACTIVE
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              ADMIN
                            </th>
                            <th
                              scope="col"
                              className="small font-weight-bold pr-4"
                            >
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.data.map((user, index) => (
                            <tr
                              key={user._id}
                              className={`${
                                (users.data.length !== index + 1 || true) &&
                                'tr-border-bottom'
                              }`}
                            >
                              <td className="pt-3 pl-4">
                                <div
                                  className="position-relative"
                                  style={{ minHeight: '42px' }}
                                >
                                  <img
                                    className="rounded-circle position-absolute mr-2"
                                    style={{ top: '-6px' }}
                                    width="40"
                                    src={user.picture}
                                    alt="..."
                                  />
                                  <span
                                    className="ml-2"
                                    style={{ paddingLeft: '50px' }}
                                  >
                                    {user.username}
                                  </span>
                                </div>
                              </td>
                              <td className="pt-3">{user.firstname}</td>
                              <td className="pt-3">{user.lastname}</td>
                              <td className="pt-3">
                                <a
                                  href={`mailto: ${user.email}`}
                                  className="text-dark"
                                >
                                  {user.email}
                                </a>
                              </td>
                              <td className="pt-3">
                                {/* <div className="form-group mt-1">
                                  <div className="custom-control custom-switch">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input custom-control-input-lg"
                                      id={`activeSwitch_${user._id}`}
                                      checked={user.active}
                                      onChange={() => {}}
                                    />
                                    <label
                                      className="custom-control-label custom-control-label-lg"
                                      htmlFor={`activeSwitch_${user._id}`}
                                    ></label>
                                  </div>
                                </div> */}
                                {user.active ? (
                                  <i className="bi bi-check-circle bi-status-align text-dark before-top-2"></i>
                                ) : (
                                  <i className="bi bi-x-circle bi-status-align text-muted before-top-2 opacity-25"></i>
                                )}
                              </td>
                              <td className="pt-3">
                                {user.isAdmin ? (
                                  <i className="bi bi-check-circle bi-status-align text-dark before-top-2"></i>
                                ) : (
                                  <i className="bi bi-x-circle bi-status-align text-muted before-top-2 opacity-25"></i>
                                )}
                              </td>
                              <td className="pt-3 pr-4">
                                <Link
                                  to={`/users`}
                                  className="fw-light link-underline mr-3"
                                  title="edit"
                                  data-toggle="modal"
                                  data-target="#editItem"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedUser(user)
                                    setIsInitialRender(true)
                                    setIsChangePassword(false)
                                  }}
                                >
                                  Edit
                                </Link>
                                {user._id !== userInfo._id && (
                                  <Link
                                    to="/users"
                                    className="fw-light link-underline"
                                    title="remove"
                                    data-toggle="modal"
                                    data-target="#removeItem"
                                    onClick={(e) => {
                                      e.preventDefault()
                                      setSelectedUser(user)
                                    }}
                                  >
                                    Remove
                                  </Link>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="float-lg-right">
                      <Paginator
                        usersPerPage={users.meta.users_per_page}
                        totalPages={users.meta.total_pages}
                        page={users.meta.page}
                        keyword={keyword}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RemoveModal
        title="Remove User"
        message={
          <ModalMessage type="removeUser" context={selectedUser?.username} />
        }
        action={handleRemove}
      />
      <AddModal title="Add User" content={<AddUserForm />} size="large" />
      <EditModal
        title="Edit User"
        content={
          <EditUserForm
            userId={selectedUser?._id}
            isInitialRender={isInitialRender}
            setIsInitialRender={setIsInitialRender}
            isChangePassword={isChangePassword}
            setIsChangePassword={setIsChangePassword}
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        size="large"
      />
    </>
  )
}

export default UserListScreen
