import React from 'react'

const AddModal = ({ title, content, size = '', position = '' }) => {
  return (
    <div
      className="modal fade"
      id="addItem"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addItemLabel"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog ${size === 'large' && 'modal-lg'} ${
          position === 'center' && 'modal-dialog-centered'
        }`}
        role="document"
      >
        <div className="modal-content">
          <div
            className={`modal-header ${size === 'large' && 'modal-header-lg'}`}
          >
            <h5 className="modal-title" id="addItemLabel">
              {title}
            </h5>
            <button
              type="button"
              className="close closeAddModal"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="bi bi-x"></i>
            </button>
          </div>
          <div className="modal-body mt-3 mb-0 pb-0">
            <div className="font-weight-light--">{content}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddModal
