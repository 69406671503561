import React from 'react'
import { Link } from 'react-router-dom'

// utils
import { currencyFormatter } from '../../utils/index'

const CartFooter = ({ items, price }) => {
  return (
    <div className="cart-footer">
      <Link
        className="d-flex justify-content-between align-items-center text-dark mb-5 pt-1 pb-3"
        to="/cart"
      >
        <div className="cart-footer-link text-dark">
          <i className="bi bi-cart3 cart-footer-icon"></i>
          <span className="position-relative">Cart</span>
          <span className="badge badge-primary position-relative font-weight-normal rounded-circle cart-qty">
            {items}
          </span>
        </div>
        <div className="h6 mb-0">{currencyFormatter(price)}</div>
      </Link>
    </div>
  )
}

export default CartFooter
