import React, { useMemo } from 'react'
import moment from 'moment'

const Order = ({
  shippingAddress,
  paymentMethod,
  user,
  isDelivered = false,
  isPaid = false,
  paidAt,
  deliveredAt
}) => {
  const fullname = useMemo(() => {
    const fname = user.firstname === undefined ? '' : user.firstname
    const lname = user.lastname === undefined ? '' : user.lastname
    const fullname = `${fname} ${lname}`.trim()
    return fullname
  }, [user])

  return (
    <>
      <div className="border-bottom mb-4 pb-4">
        {shippingAddress && (
          <>
            <h2 className="h5 mb-4">Shipping Address</h2>
            {fullname}
            {fullname && <br />}
            {shippingAddress.address}
            {shippingAddress.apt && `, #${shippingAddress.apt}`}
            <br />
            {shippingAddress.city}, {shippingAddress.state}{' '}
            {shippingAddress.zipCode}
            <br />
            {shippingAddress.country}
            {isDelivered ? (
              <h2 className="mb-2 mt-1">
                <i className="bi bi-check-circle-fill position-relative text-success top-5"></i>
                <span className="h6 font-weight-normal text-success-- ml-2">
                  Delivered on {moment(deliveredAt).calendar()}
                </span>
              </h2>
            ) : (
              <h2 className="mb-2 mt-1">
                <i className="bi bi-exclamation-circle-fill position-relative text-danger top-5"></i>
                <span className="h6 font-weight-normal text-danger-- ml-2">
                  Not Yet Delivered
                </span>
              </h2>
            )}
          </>
        )}
      </div>
      <div>
        <h2 className="h5 mb-3">Payment Method</h2>
        {paymentMethod}
        {isPaid ? (
          <h2 className="mt-1 mb-0">
            <i className="bi bi-check-circle-fill position-relative text-success top-5"></i>
            <span className="h6 font-weight-normal text-success-- ml-2">
              Paid on {moment(paidAt).calendar()}
            </span>
          </h2>
        ) : (
          <h2 className="mt-1 mb-0">
            <i className="bi bi-exclamation-circle-fill position-relative text-danger top-5"></i>
            <span className="h6 font-weight-normal text-danger-- ml-2">
              Not Yet Paid
            </span>
          </h2>
        )}
      </div>
    </>
  )
}

export default Order
