import React from 'react'

import Shimmer from './Shimmer'
import SkeletonElement from './SkeletonElement'

const SkeletonOrderList = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="table-responsive">
        <table className="table-striped- table-user mb-0 table">
          <thead>
            <tr>
              <th scope="col" className="small font-weight-bold pl-4">
                ID
              </th>
              <th
                scope="col"
                className="small font-weight-bold"
                style={{ minWidth: '200px' }}
              >
                USER
              </th>
              <th scope="col" className="small font-weight-bold">
                DATE
              </th>
              <th scope="col" className="small font-weight-bold">
                TOTAL
              </th>
              <th scope="col" className="small font-weight-bold">
                PAID
              </th>
              <th scope="col" className="small font-weight-bold">
                DELIVERED
              </th>
              <th scope="col" className="small font-weight-bold pr-4">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody>
            {[...Array(5).keys()].map((elem, index) => (
              <tr
                key={index}
                className={`${index + 1 !== 5 && 'tr-border-bottom'}`}
              >
                <td className="position-relative pt-2 pl-4">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pl-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3">
                  <SkeletonElement type="item" />
                </td>
                <td className="pt-2 pb-3 pr-4">
                  <SkeletonElement type="item" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Shimmer />
      </div>
    </div>
  )
}

export default SkeletonOrderList
