import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// images
import { defaultProductBrand } from '../../data-ui'

// actions
import { addProduct, listProducts } from '../../actions/productActions'

// utils
import { currencyFormatter, checkImgURL } from '../../utils/index'

// config
import { productListPerPage, productImagePlaceholder } from '../../config'

const AddProductForm = () => {
  const initialProductValues = {
    name: '',
    shortName: '',
    // image: undefined,
    description: '',
    brand: undefined,
    category: '',
    price: '',
    countInStock: '',
    isNewArrival: false
  }
  const [productFormValues, setProductFormValues] =
    useState(initialProductValues)
  const [productFormErrors, setProductFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const [images, setImages] = useState([{ imageurl: '' }])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const productAdd = useSelector((state) => state.productAdd)
  const { loading, error, success } = productAdd

  // refs and callbacks
  const _initialProductValuesRef = useRef(initialProductValues).current

  // display image
  // const displayImage = useMemo(() => {
  //   if (!checkImgURL(productFormValues.image)) return productImagePlaceholder
  //   if (productFormValues.image) return productFormValues.image
  // }, [productFormValues.image])

  // display name
  const displayName = useMemo(() => {
    return productFormValues.name ? productFormValues.name : 'Product Name'
  }, [productFormValues.name])

  // display price
  const displayPrice = useMemo(() => {
    return productFormValues.price
      ? currencyFormatter(parseFloat(productFormValues.price))
      : currencyFormatter(0)
  }, [productFormValues.price])

  // handle form fields changes
  const handleChange = (e) => {
    let { name, value } = e.target

    if (name === 'price' && value && !value.match(/^\d{1,}(\.\d{0,2})?$/))
      return

    if (name === 'countInStock' && value && !value.match(/^[0-9]+$/)) return

    if (e.target.type === 'checkbox') {
      value = e.target.checked ? true : false
    }

    // if (
    //   name === 'image' &&
    //   value &&
    //   !value.match(/^https?:\/\/([\w\d\-]+\.)+\w{2,}(\/.+)?$/)
    // )
    //   return

    setProductFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value
    }))

    // add images
    setProductFormValues((prevFormValues) => ({
      ...prevFormValues,
      images
    }))
  }

  // validate form
  const validate = (values) => {
    const errors = {}

    if (!values.name.trim()) errors.name = 'Name is required'

    if (!values.price) errors.price = 'Price is required'

    // if (!values.brand.trim()) errors.brand = 'Brand is required'

    if (values.countInStock === '')
      errors.countInStock = 'Count in stock is required'

    if (!values.category) errors.category = 'Category is required'

    if (!values.description) errors.description = 'Description is required'

    return errors
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()

    setProductFormErrors(validate(productFormValues))
    setIsSubmit(true)

    dispatch(addProduct(productFormValues))
  }

  const handleChangeImageField = (e, i) => {
    let { name, value } = e.target

    if (value && !value.match(/^https?:\/\/([\w\d\-]+\.)+\w{2,}(\/.+)?$/))
      return

    let newFormValues = [...images]
    newFormValues[i][name] = value
    setImages(newFormValues)
  }

  const addImageField = () => {
    setImages([...images, { imageurl: '' }])
  }

  const removeImageField = (i) => {
    let newFormValues = [...images]
    newFormValues.splice(i, 1)
    setImages(newFormValues)
  }

  useEffect(() => {
    if (success) {
      // close the modal
      document.querySelector('.closeAddModal').click()

      // list users
      dispatch(listProducts(productListPerPage, 1, '*', ''))

      // reset form
      setProductFormValues(_initialProductValuesRef)

      // navigate
      navigate('/products')
    }
  }, [dispatch, _initialProductValuesRef, success, navigate])

  return (
    <>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '20px 20px 5px 20px',
          margin: '0 0 20px 0',
          borderRadius: '10px'
        }}
        className="d-none"
      >
        <h4 className="mb-4">DEGUB</h4>
        <pre>formValues: {JSON.stringify(productFormValues, undefined, 2)}</pre>
        <pre>formErrors: {JSON.stringify(productFormErrors, undefined, 2)}</pre>
        <pre>isSubmit: {JSON.stringify(isSubmit, undefined, 2)}</pre>
      </div>
      <form
        encType="multipart/form-data"
        autoComplete="false"
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="media position-relative mb-4">
          <img
            src={images[0].imageurl || productImagePlaceholder}
            width="100"
            className="rounded-circle pic-border-- profile-img mr-3 shadow-sm"
            alt="..."
          />
          <div className="media-body mt-3">
            <h4 className="card-title">{displayName}</h4>
            <p className="card-text text-muted text-small">{displayPrice}</p>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="productName">
            Name{' '}
            <small className="text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <input
            type="text"
            className={`form-control form-control-lg ${
              productFormErrors.name && 'is-invalid'
            }`}
            name="name"
            id="productName"
            value={productFormValues.name}
            onChange={handleChange}
          />
          {productFormErrors.name && (
            <div className="invalid-feedback fw-light">
              {productFormErrors.name}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="productShortName">Short Name</label>
          <input
            type="text"
            className={`form-control form-control-lg`}
            name="shortName"
            id="productShortName"
            value={productFormValues.shortName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="productPrice">
            Price{' '}
            <small className="text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <input
            type="text"
            className={`form-control form-control-lg ${
              productFormErrors.price && 'is-invalid'
            }`}
            name="price"
            id="productPrice"
            value={productFormValues.price}
            onChange={handleChange}
          />
          {productFormErrors.price && (
            <div className="invalid-feedback fw-light">
              {productFormErrors.price}
            </div>
          )}
        </div>
        <div className="form-group">
          {/* <label htmlFor="image">Image URL</label>
          <input
            type="text"
            className="form-control form-control-lg mb-2"
            name="image"
            placeholder="https://"
            id="image"
            value={productFormValues.image}
            onChange={handleChange}
          /> */}
          {/* <span className="small d-block text-muted mb-1">OR Upload Image</span>
          <div className="custom-file form-control-lg">
            <input
              type="file"
              className="custom-file-input form-control-lg"
              name="picture"
              id="picture"
            />
            <label
              className="custom-file-label form-control-lg text-muted"
              htmlFor="picture"
            >
              Choose file
            </label>
          </div>
          <small className="form-text text-muted font-weight-light x-small">
            The image needs to be PNG transparent with dimensions of 640 x 640
            pixels.
          </small> */}
        </div>
        <div className="form-group">
          <label htmlFor="imageurl">
            Image(s){' '}
            <small className="text-muted font-weight-light opacity-75">
              (add one or more images, all images need to be PNG transparent
              with dimensions of 640 x 640 pixels.)
            </small>
          </label>
          {images &&
            images.map((elem, index) => (
              <div className={`${index && 'removable-input'}`} key={index}>
                <input
                  type="text"
                  className="form-control form-control-lg mb-2"
                  name="imageurl"
                  placeholder="https://"
                  id="imageurl"
                  value={elem.imageurl}
                  onChange={(e) => {
                    handleChangeImageField(e, index)
                  }}
                />
                {index > 0 && (
                  <span onClick={() => removeImageField(index)}></span>
                )}
              </div>
            ))}
          <Link
            to="."
            className="d-flex justify-content-end align-items-center small font-weight-normal"
            onClick={addImageField}
          >
            + Add another image
          </Link>
        </div>
        <div className="form-group">
          <label htmlFor="productBrand">Brand</label>
          <input
            type="text"
            className={`form-control form-control-lg`}
            name="brand"
            id="productBrand"
            value={productFormValues.brand}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="productCountInStock">
            Count In Stock{' '}
            <small className="text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <input
            type="text"
            className={`form-control form-control-lg ${
              productFormErrors.countInStock && 'is-invalid'
            }`}
            name="countInStock"
            id="productCountInStock"
            value={productFormValues.countInStock}
            onChange={handleChange}
          />
          {productFormErrors.countInStock && (
            <div className="invalid-feedback fw-light">
              {productFormErrors.countInStock}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="productCategory">
            Category{' '}
            <small className="text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <select
            className={`custom-select custom-select-lg ${
              productFormErrors.category && 'is-invalid'
            }`}
            name="category"
            id="productCategory"
            value={productFormValues.category}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Vehicules">Vehicules</option>
            <option value="Rentals">Rentals</option>
            <option value="Home & Gardens">Home & Gardens</option>
            <option value="Electronics">Electronics</option>
            <option value="Hobbies">Hobbies</option>
            <option value="Home Sales">Home Sales</option>
            <option value="Classifieds">Classifieds</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Clothing & Accessories">
              Clothing & Accessories
            </option>
            <option value="Family">Family</option>
            <option value="Deals">Deals</option>
          </select>
          {productFormErrors.category && (
            <div className="invalid-feedback fw-light">
              {productFormErrors.category}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="productDescription">
            Description{' '}
            <small className="text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <textarea
            className={`form-control form-control-lg ${
              productFormErrors.description && 'is-invalid'
            }`}
            name="description"
            id="productDescription"
            rows="4"
            value={productFormValues.description}
            onChange={handleChange}
          ></textarea>
          {productFormErrors.description && (
            <div className="invalid-feedback fw-light">
              {productFormErrors.description}
            </div>
          )}
        </div>
        <div className="form-group mb-0">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              name="isNewArrival"
              id="productIsNewArrival"
              value={productFormValues.isNewArrival}
              onChange={handleChange}
              checked={productFormValues.isNewArrival}
            />
            <label
              htmlFor="productIsNewArrival"
              className="custom-control-label custom-checkbox-label"
            >
              New Arrival
            </label>
          </div>
        </div>
        <div className="modal-footer modal-footer-align">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Add
          </button>
        </div>
      </form>
    </>
  )
}

export default AddProductForm
