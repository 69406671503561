import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'

// style
import 'react-dual-listbox/lib/react-dual-listbox.css'

// actions
import {
  listProducts,
  updateFeaturedProducts
} from '../../actions/productActions'

// component
import Message from '../../components/shared/Message'

const FeaturedProducts = ({
  featuredProducts,
  isSaveSubmit,
  setIsSaveSubmit
}) => {
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState([])
  const [updatedFeaturedProducts, setUpdatedFeaturedProducts] = useState([])
  const [firstLoad, setFirstLoad] = useState(true)
  const [initProducts, setInitProducts] = useState(true)
  const [initFeaturedProducts, setInitFeaturedProducts] = useState(true)

  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  const dispatch = useDispatch()

  const selectProductHandler = (selectedProducts) => {
    if (selectedProducts) {
      const tempProducts = selectedProducts.map((product) => {
        return {
          id: product.value
        }
      })

      setUpdatedFeaturedProducts(tempProducts)
    }
  }

  useEffect(() => {
    if (firstLoad) {
      dispatch(listProducts(0, 1, 'a-z', ''))
      setFirstLoad(false)
    }

    if (products.length && initProducts) {
      products.map((product) =>
        setOptions((prevState) => [
          ...prevState,
          {
            value: product._id,
            label: product.name
          }
        ])
      )
      setInitProducts(false)
    }
    if (featuredProducts.length && initFeaturedProducts) {
      featuredProducts.map((product) =>
        setSelected((prevState) => [
          ...prevState,
          {
            value: product._id,
            label: product.name
          }
        ])
      )
      featuredProducts.map((product) =>
        setUpdatedFeaturedProducts((prevState) => [
          ...prevState,
          {
            id: product._id
          }
        ])
      )

      setInitFeaturedProducts(false)
    }

    if (isSaveSubmit && updatedFeaturedProducts !== undefined) {
      dispatch(updateFeaturedProducts(updatedFeaturedProducts))
      setIsSaveSubmit(false)
    }
  }, [
    products,
    featuredProducts,
    dispatch,
    firstLoad,
    initProducts,
    initFeaturedProducts,
    selected,
    isSaveSubmit,
    setIsSaveSubmit,
    updatedFeaturedProducts
  ])

  if (loading) return <Message message="Loading..." />
  if (error) return <Message message={error} />

  return (
    <div className="mt-2">
      <Select
        closeMenuOnSelect={false}
        isClearable={false}
        defaultValue={selected}
        isMulti
        name="fproducts"
        options={options}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: '#f5efe0',
            primary: '#dc143c'
          }
        })}
        onChange={(value) => selectProductHandler(value)}
      />
    </div>
  )
}

export default FeaturedProducts
