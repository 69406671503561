import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// components
import {
  editReview,
  listProductDetails,
  myReviewedProducts,
  updateReview
} from '../../actions/productActions'

// utils
import { isEmptyObj } from '../../utils'

// config
import { myReviewsPerPage } from '../../config'

const EditReviewForm = ({
  productId,
  isInitialRender,
  setIsInitialRender,
  forwardScreen = ''
}) => {
  const initialReviewValues = {
    title: '',
    rating: '',
    comment: ''
  }

  const [reviewFormValues, setReviewFormValues] = useState(initialReviewValues)
  const [reviewFormErrors, setReviewFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [isReviewd, setIsReviewd] = useState(false)

  const dispatch = useDispatch()

  const reviewEdit = useSelector((state) => state.reviewEdit)
  const {
    loading: loadingEdit,
    error: errorEdit,
    review,
    success: successEdit
  } = reviewEdit

  const reviewUpdate = useSelector((state) => state.reviewUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    product,
    success: successUpdate
  } = reviewUpdate

  // console.log('reviewEdit:', reviewEdit)

  // refs and callbacks
  const _initialReviewValuesRef = useRef(initialReviewValues).current
  const _editFormCb = useCallback(async () => {
    if (successEdit) {
      setReviewFormValues((prevFormValues) => ({
        ...prevFormValues,
        title: review.title.trim(),
        rating: Number(review.rating),
        comment: review.comment.trim()
      }))
    }
  }, [review, successEdit])

  // handle form fields changes
  const handleChange = (e) => {
    let { name, value } = e.target

    setReviewFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value
    }))
  }

  // validate form
  const validate = (values) => {
    const errors = {}

    if (!values.title.trim()) errors.title = 'Title is required'

    if (!values.rating) errors.rating = 'Rating is required'

    if (!values.comment.trim()) errors.comment = 'Comment is required'

    return errors
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    setReviewFormErrors(validate(reviewFormValues))
    setIsSubmit(true)

    const reviewFormData = {
      title: reviewFormValues.title.trim(),
      rating: reviewFormValues.rating,
      comment: reviewFormValues.comment.trim()
    }

    // DIPATCH UPDATE PRODUCT
    dispatch(updateReview(productId, reviewFormData))
  }

  useEffect(() => {
    if (productId && isInitialRender) {
      dispatch(editReview(productId))
      setIsInitialRender(false)
    }

    _editFormCb()

    if (
      Object.keys(reviewFormErrors).length === 0 &&
      successUpdate &&
      isSubmit
    ) {
      // close the modal
      document.querySelector('.closeEditModal').click()

      // dispatch list product details
      dispatch(listProductDetails(productId))

      // dispatch my reviews
      if (forwardScreen === 'reviews') {
        dispatch(myReviewedProducts(myReviewsPerPage, 1))
      }
    }
  }, [
    successEdit,
    _editFormCb,
    dispatch,
    isInitialRender,
    isSubmit,
    productId,
    reviewFormErrors,
    setIsInitialRender,
    successUpdate
  ])

  return (
    <>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '20px 20px 5px 20px',
          margin: '0 0 20px 0',
          borderRadius: '10px'
        }}
        className="d-none"
      >
        <h4 className="mb-4">DEGUB</h4>
        <pre>formValues: {JSON.stringify(reviewFormValues, undefined, 2)}</pre>
        <pre>formErrors: {JSON.stringify(reviewFormErrors, undefined, 2)}</pre>
        <pre>isSubmit: {JSON.stringify(isSubmit, undefined, 2)}</pre>
      </div>
      <form autoComplete="false" onSubmit={handleSubmit} noValidate>
        <div className="form-group font-weight-normal">
          <label htmlFor="title">
            Title{' '}
            <small className="x-small text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <input
            type="text"
            autoComplete="true"
            className={`form-control form-control-lg ${
              reviewFormErrors.title && 'is-invalid'
            }`}
            name="title"
            id="title"
            value={reviewFormValues.title}
            onChange={handleChange}
          />
          {reviewFormErrors.title && (
            <div className="invalid-feedback fw-light">
              {reviewFormErrors.title}
            </div>
          )}
        </div>
        <div className="form-group font-weight-normal">
          <label htmlFor="rating">
            Rating{' '}
            <small className="x-small text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <select
            className={`custom-select custom-select-lg ${
              reviewFormErrors.rating && 'is-invalid'
            }`}
            id="rating"
            name="rating"
            value={reviewFormValues.rating}
            onChange={handleChange}
          >
            <option value="">-- Select --</option>
            <option value="5">Excellent (5 stars)</option>
            <option value="4">Very Good (4 stars)</option>
            <option value="3">Good (3 stars)</option>
            <option value="2">Fair (2 stars)</option>
            <option value="1">Poor (1 star)</option>
          </select>
          {reviewFormErrors.rating && (
            <div className="invalid-feedback fw-light">
              {reviewFormErrors.rating}
            </div>
          )}
        </div>
        <div className="form-group font-weight-normal">
          <label htmlFor="comment">
            Comment{' '}
            <small className="x-small text-muted font-weight-light opacity-75">
              (required)
            </small>
          </label>
          <textarea
            className={`form-control form-control-lg ${
              reviewFormErrors.comment && 'is-invalid'
            }`}
            name="comment"
            id="comment"
            rows={4}
            value={reviewFormValues.comment}
            onChange={handleChange}
          ></textarea>
          {reviewFormErrors.comment && (
            <div className="invalid-feedback fw-light">
              {reviewFormErrors.comment}
            </div>
          )}
        </div>
        <div className="modal-footer modal-footer-align">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </div>
      </form>
    </>
  )
}

export default EditReviewForm
