import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// actions
import { saveShippingAddress } from '../../actions/cartActions'

// country lists
import { usaStateList, canadaProvinceList } from '../../countries'

const ShippingForm = () => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialShippingValues = {
    address: shippingAddress.address || '',
    apt: shippingAddress.apt || '',
    city: shippingAddress.city || '',
    state: shippingAddress.state || '',
    zipCode: shippingAddress.zipCode || '',
    country: shippingAddress.country || 'United States'
  }
  const [shippingFormValues, setShippingFormValues] = useState(
    initialShippingValues
  )
  const [shippingFormErrors, setShippingFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [stateList, setStateList] = useState([])
  const [stateLabel, setStateLabel] = useState('')
  const [zipCodeLael, setZipCodeLael] = useState('')
  const [aptPlaceholder, setAptPlaceholder] = useState('')

  // handle form fields changes
  const handleChange = (e) => {
    let { name, value } = e.target

    setShippingFormValues({ ...shippingFormValues, [name]: value })

    if (name === 'country') {
      // setShippingFormValues({ ...shippingFormValues, state: '' })
      setShippingFormValues((prevFormValues) => ({
        ...prevFormValues,
        state: ''
      }))
    }
  }

  // validate form
  const validate = (values) => {
    const errors = {}

    if (!values.address.trim()) errors.address = 'Street address is required'
    if (!values.city.trim()) errors.city = 'City is required'
    if (!values.state.trim()) errors.state = `${stateLabel} is required`
    if (!values.zipCode.trim()) errors.zipCode = `${zipCodeLael} is required`

    return errors
  }

  // handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()

    setShippingFormErrors(validate(shippingFormValues))
    setIsSubmit(true)
  }

  // save data
  const saveData = useCallback(async () => {
    if (Object.keys(shippingFormErrors).length === 0 && isSubmit) {
      dispatch(saveShippingAddress({ ...shippingFormValues }, navigate))
    }
  }, [shippingFormErrors, shippingFormValues, dispatch, isSubmit, navigate])

  useEffect(() => {
    if (shippingFormValues.country === 'United States') {
      setStateList(['Select', ...usaStateList])
      setStateLabel('State')
      setZipCodeLael('Zip Code')
      setAptPlaceholder('Apt, Suite, Unit, Building, Floor, etc.')
    }
    if (shippingFormValues.country === 'Canada') {
      setStateList(['Select', ...canadaProvinceList])
      setStateLabel('Province / Territory')
      setZipCodeLael('Postal Code')
      setAptPlaceholder('Apt, Suite, Unit, Building')
    }

    saveData()
  }, [shippingFormValues.country, saveData])

  return (
    <>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          padding: '20px 20px 5px 20px',
          margin: '0 0 20px 0',
          borderRadius: '10px'
        }}
        className="d-none"
      >
        <h4 className="mb-4">DEGUB</h4>
        <pre>
          formValues: {JSON.stringify(shippingFormValues, undefined, 2)}
        </pre>
        <pre>
          formErrors: {JSON.stringify(shippingFormErrors, undefined, 2)}
        </pre>
        <pre>isSubmit: {JSON.stringify(isSubmit, undefined, 2)}</pre>
      </div>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="country">Country / Region</label>
              <select
                className="custom-select custom-select-lg"
                id="country"
                name="country"
                value={shippingFormValues.country}
                onChange={handleChange}
              >
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="text"
                className={`form-control form-control-lg ${
                  shippingFormErrors.address && 'is-invalid'
                }`}
                name="address"
                id="address"
                value={shippingFormValues.address}
                onChange={handleChange}
                placeholder="Street address or P.O. Box"
              />
              {shippingFormErrors.address && (
                <div className="invalid-feedback fw-light">
                  {shippingFormErrors.address}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="apt" className="d-none d-md-block">
                &nbsp;
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                name="apt"
                id="apt"
                value={shippingFormValues.apt}
                onChange={handleChange}
                placeholder={aptPlaceholder}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                className={`form-control form-control-lg ${
                  shippingFormErrors.city && 'is-invalid'
                }`}
                name="city"
                id="city"
                value={shippingFormValues.city}
                onChange={handleChange}
              />
              {shippingFormErrors.city && (
                <div className="invalid-feedback fw-light">
                  {shippingFormErrors.city}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="state">{stateLabel}</label>
              <select
                className={`custom-select custom-select-lg ${
                  shippingFormErrors.state && 'is-invalid'
                }`}
                id="state"
                name="state"
                value={shippingFormValues.state}
                onChange={handleChange}
              >
                {stateList.map((state) => {
                  const value = state === 'Select' ? '' : state
                  return (
                    <option value={value} key={state}>
                      {state}
                    </option>
                  )
                })}
              </select>

              {shippingFormErrors.state && (
                <div className="invalid-feedback fw-light">
                  {shippingFormErrors.state}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="zipCode">{zipCodeLael}</label>
          <input
            type="text"
            className={`form-control form-control-lg ${
              shippingFormErrors.zipCode && 'is-invalid'
            }`}
            id="zipCode"
            name="zipCode"
            value={shippingFormValues.zipCode}
            onChange={handleChange}
          />
          {shippingFormErrors.zipCode && (
            <div className="invalid-feedback fw-light">
              {shippingFormErrors.zipCode}
            </div>
          )}
        </div>
        <button
          type="submit"
          className="btn btn-primary text-uppercase mt-2 px-4"
        >
          Continue
        </button>
      </form>
    </>
  )
}

export default ShippingForm
