import React, { useEffect, useState, useRef } from 'react'
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// components
import AddModal from '../components/shared/modal/AddModal'
import AddProductForm from '../components/form/AddProductForm'
import RemoveModal from '../components/shared/modal/RemoveModal'
import EditModal from '../components/shared/modal/EditModal'
import SkeletonProductList from '../components/shared/skeletons/SkeletonProductList'
import Message from '../components/shared/Message'
import AlertCard from '../components/shared/AlertCard'
import Paginator from '../components/pagination/productlist/Paginator'
import ModalMessage from '../components/shared/ModalMessage'

// actions
import { listProducts, removeProduct } from '../actions/productActions'

// utils
import { currencyFormatter, updateQueryString } from '../utils/index'

// config
import { productListPerPage, productImagePlaceholder } from '../config'
import EditProductForm from '../components/form/EditProductForm'

const ProductListScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const searchIcon = useRef()
  const searchSearch = useRef()

  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [keyword, setKeyword] = useState(searchParams.get('term') || '')

  const page = searchParams.get('page') || 1
  const sort = searchParams.get('sort') || '*'

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productList = useSelector((state) => state.productList)
  const { loading: loadingList, error: errorList, products, meta } = productList

  const productRemove = useSelector((state) => state.productRemove)
  const {
    loading: loadingRemove,
    error: errorRemove,
    success: successRemove
  } = productRemove

  const handleRemove = (e) => {
    e.preventDefault()
    document.querySelector('.close').click()
    // TODO: remove product
    dispatch(removeProduct(selectedProduct._id))
    navigate('/products')
  }

  const updateParams = (term) => {
    const updatedParams = updateQueryString({}, { term }, pathname)
    window.history.pushState(null, '', `${updatedParams}`)
  }

  const searchHandler = (e) => {
    e.preventDefault()
    updateParams(e.target.value)
    setKeyword(e.target.value)
    if (e.target.value) {
      searchIcon.current.classList.add('bi-search-clear')
    } else {
      searchIcon.current.classList.remove('bi-search-clear')
    }
  }

  const clearSearchHandler = (e) => {
    e.preventDefault()
    if (keyword) {
      searchIcon.current.classList.remove('bi-search-clear')
      setKeyword('')
      updateParams('')
      searchSearch.current.focus()
    }
  }

  useEffect(() => {
    if ((userInfo && userInfo.isAdmin) || successRemove) {
      // TODO: distpatch list products
      dispatch(
        listProducts(productListPerPage, page, sort, keyword, { page, sort })
      )
    } else {
      navigate('/')
    }
  }, [userInfo, navigate, dispatch, page, sort, keyword, successRemove])

  if (errorList)
    return (
      <>
        <div className="band band-fat">
          <div className="mb-5 pb-3"></div>
        </div>
        <AlertCard
          message={errorList}
          icon="exclamation-circle"
          cardBorder={false}
          button={{ label: 'Go Back Home', action: '/' }}
        />
      </>
    )

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-5 pb-3">
            <h1 className="h3 mb-1">
              <i className="bi bi-basket3 before-top-min-2 mr-2"></i>Products
            </h1>
            <Link
              to="/products"
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#addItem"
              onClick={(e) => {
                e.preventDefault()
              }}
            >
              + <span className="d-none d-md-inline-block mr-1">Add</span>
              Product
            </Link>
          </div>
        </div>
      </div>
      <div className="container-overlap container">
        <div className="row">
          <div className="col">
            <div className="card card-no-border- pt-0 shadow">
              <div className="card-body px-0 py-4">
                <div className="d-md-flex justify-content-md-between align-items-md-center border-bottom-- mb-0 pb-3">
                  <h6 className="card-header-title mb-md-2 ml-4 mb-3">
                    Poducts List
                  </h6>
                  <div className="fw-light top-min-2 position-relative mr-4 ml-4 mb-1">
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        name="term"
                        id="term"
                        value={keyword}
                        onChange={searchHandler}
                        style={{
                          paddingLeft: '12px',
                          paddingRight: '40px'
                        }}
                        ref={searchSearch}
                      />
                      <div className="search-input-icon">
                        <i
                          className="bi bi-search text-black-50"
                          style={{ fontSize: '1.1rem', marginRight: '-6px' }}
                          ref={searchIcon}
                          onClick={clearSearchHandler}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center border-bottom mb-0 pl-4 pb-3">
                  <div className="fw-light top-min-2 position-relative mr-4 mb-1">
                    {products && products.length > 0 && (
                      <div className="small-- fw-light">
                        <span className="d-none-- d-md-inline-block">
                          Showing
                        </span>{' '}
                        {meta.from_page} to {meta.to_page} of{' '}
                        {meta.total_products} products
                      </div>
                    )}
                  </div>
                </div>
                {loadingList && <SkeletonProductList />}
                {!loadingList && products && products.length === 0 && (
                  <Message message="No products found" />
                )}
                {!loadingList && products && products.length > 0 && (
                  <>
                    <div className="table-responsive">
                      <table className="table-user mb-0 table">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="small font-weight-bold pl-4"
                              colSpan="2"
                              style={{ minWidth: '260px' }}
                            >
                              ITEM
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              PRICE
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              CATEGORY
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              BRAND
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              QT
                            </th>
                            <th scope="col" className="small font-weight-bold">
                              REVIEWS
                            </th>
                            <th
                              scope="col"
                              className="small font-weight-bold pr-4"
                            >
                              ACTION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((product, index) => (
                            <tr
                              key={product._id}
                              className={`${
                                (products.length !== index + 1 || true) &&
                                'tr-border-bottom'
                              } ${
                                product.countInStock === 0 && 'bg-secondary--'
                              }`}
                            >
                              <td
                                className="position-relative top-min-2-- pt-1 pl-3 pb-1"
                                width={50}
                              >
                                <Link to={`/product/${product._id}`}>
                                  <img
                                    alt="..."
                                    src={
                                      (product.images &&
                                        product.images[0]?.imageurl) ||
                                      productImagePlaceholder
                                    }
                                    className="rounded-circle"
                                    width="50"
                                  />
                                </Link>
                              </td>
                              <td className="pt-3 pl-0 pb-3">
                                <Link
                                  to={`/product/${product._id}`}
                                  className="text-black"
                                >
                                  {product.name}
                                </Link>
                                {product.countInStock === 0 && (
                                  <span className="badge bg-danger font-weight-light ml-2 px-2 text-white">
                                    Out of stock
                                  </span>
                                )}
                                {product.isNewArrival &&
                                  product.countInStock !== 0 && (
                                    <span className="badge bg-success font-weight-light ml-2 px-2 text-white">
                                      New arrival
                                    </span>
                                  )}
                              </td>
                              <td className="pt-3 pb-3">
                                {currencyFormatter(product.price)}
                              </td>
                              <td className="pt-3 pb-3">{product.category}</td>
                              <td className="pt-3 pb-3">{product.brand}</td>
                              <td
                                className={`pt-3 pb-3 ${
                                  product.countInStock === 0 && 'text-primary--'
                                }`}
                              >
                                {product.countInStock}
                              </td>
                              <td className={`pt-3 pb-3`}>
                                <Link
                                  to={`/product/${product._id}#reviews`}
                                  className="text-black"
                                >
                                  {product.numReviews}
                                </Link>
                              </td>
                              <td className="pt-3 pb-3 pr-4">
                                <Link
                                  to={`/products`}
                                  className="fw-light link-underline mr-3"
                                  title="edit"
                                  data-toggle="modal"
                                  data-target="#editItem"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedProduct(product)
                                    setIsInitialRender(true)
                                  }}
                                >
                                  Edit
                                </Link>
                                <Link
                                  to={`/products`}
                                  className="fw-light link-underline"
                                  title="remove"
                                  data-toggle="modal"
                                  data-target="#removeItem"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedProduct(product)
                                  }}
                                >
                                  Remove
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="float-lg-right">
                      <Paginator mode="backend" keyword={keyword} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <RemoveModal
        title="Remove Product"
        message={
          <ModalMessage type="removeProduct" context={selectedProduct?.name} />
        }
        action={handleRemove}
      />
      <AddModal title="Add Product" content={<AddProductForm />} size="large" />
      <EditModal
        title="Edit Product"
        content={
          <EditProductForm
            productId={selectedProduct?._id}
            isInitialRender={isInitialRender}
            setIsInitialRender={setIsInitialRender}
            keyword={keyword}
            setKeyword={setKeyword}
          />
        }
        size="large"
      />
    </>
  )
}

export default ProductListScreen
