import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// utils
import { currencyFormatter } from '../../utils/index'

// actions
import { addToCart } from '../../actions/cartActions'
import { likeProduct } from '../../actions/productActions'

// config
import { productImagePlaceholder } from '../../config'

const SingleBanner = ({ product }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [likeCount, setLikeCount] = useState(product.numLikes || 0)
  const [isLiked, setIsLiked] = useState(
    product.likes.includes(userInfo?._id) || false
  )
  const [isLikeSubmit, setIsLikeSubmit] = useState(false)

  const productLike = useSelector((state) => state.productLike)
  const { product: likedProduct } = productLike

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const addToCartHandler = (id) => {
    // navigate(`/cart/${id}?quantity=1}`)
    dispatch(addToCart(id, 1))
  }

  const likeProductHandler = (e, productId) => {
    if (!userInfo) {
      navigate('/login')
    }

    e.preventDefault()
    dispatch(likeProduct(productId))
    setIsLikeSubmit(true)
    setIsLiked(!isLiked)
  }

  useEffect(() => {
    if (likedProduct && isLikeSubmit) {
      setLikeCount(likedProduct.numLikes)
      setIsLikeSubmit(false)
    }
  }, [likedProduct, isLikeSubmit])

  return (
    <div>
      <div className="container">
        <div className="row align-items-lg-center text-lg-left text-center">
          <div className="col-lg-5 order-lg-2 mb-lg-0 mb-5">
            <div className="mb-5">
              <Link
                to={`/product/${product._id}`}
                className="display-4 d-block mb-4 text-black"
              >
                {product.shortName !== 'undefined' && product.shortName
                  ? product.shortName
                  : product.name}
              </Link>
              <p className="font-weight-light d-none d-md-block">
                {product.description}
              </p>
            </div>
            <Link
              to="."
              className="btn btn-lg btn-primary transition-3d-hover px-md-5 mr-2 px-4"
              tabIndex="0"
              onClick={() => addToCartHandler(product._id)}
            >
              <span className="text-numeric">
                {currencyFormatter(product.price)}
              </span>{' '}
              - Add to Cart
            </Link>
            <Link
              to="/"
              className="btn btn-icon btn-outline-primary rounded-circle"
              data-toggle="tooltip"
              data-placement="top"
              title="Save for later"
              tabIndex="0"
              onClick={(e) => likeProductHandler(e, product._id)}
            >
              {userInfo ? (
                <i
                  className={`bi ${
                    isLiked ? 'bi-suit-heart-fill' : 'bi-suit-heart'
                  } position-relative`}
                ></i>
              ) : (
                <i className={`bi bi-suit-heart position-relative`}></i>
              )}
            </Link>
            <span className="d-none ml-2">{likeCount}</span>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="w-75 mx-auto">
              <Link to={`/product/${product._id}`}>
                {' '}
                <img
                  className="img-fluid img-bw--"
                  src={
                    (product.images && product.images[0]?.imageurl) ||
                    productImagePlaceholder
                  }
                  alt={product.name}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleBanner
