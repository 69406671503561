import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

// utils
import { currencyFormatter } from '../utils/index'
import { cartCalculations } from '../utils/cart'

// components
import CartOrderSummary from '../components/cart/CartOrderSummary'
import SignIn from '../components/checkout/SignIn'
import Steps from '../components/checkout/Steps'

const ShippingScreen = () => {
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  // calculations
  cartCalculations(cart, cartItems)

  useEffect(() => {}, [])

  return (
    <>
      <div className="band band-fat">
        <div className="container">
          <div className="d-flex justify-content-between align-items-top mb-5 pb-3">
            <div>
              <h1 className="h3 pr-lg-0 mb-1 pr-5">Sign In</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-overlap space-1 space-md-2 container">
        <div className="row">
          <div className="col-lg-8">
            <div className="card p-4 shadow">
              <Steps step={1} />
              <div className="mt-4">
                <SignIn />
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <CartOrderSummary
              title={'Order summary'}
              itemSubtotal={cart.itemSubtotal}
              itemTotalPrice={currencyFormatter(cart.itemTotalPrice)}
              deliveryFee={
                cart.shippingPrice === 0
                  ? 'Free'
                  : currencyFormatter(cart.shippingPrice)
              }
              taxLabel={'Tax'}
              tax={currencyFormatter(cart.taxPrice)}
              orderTotalLabel={'Total'}
              orderTotalPrice={currencyFormatter(cart.orderTotalPrice)}
              cartItems={cartItems}
              actionLabel={'Place Order'}
              action={() => {
                // console.log('checkout hanlder')
              }}
              isItemsShown={true}
              isDeliveryNote={true}
            />
          </div>
          {/* Order Summary */}
        </div>
      </div>
    </>
  )
}

export default ShippingScreen
